import React from "react";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import './AdminPage.scss';
import {AdminOverviewTab} from "./AdminOverviewTab";
import {AdminCoachApprovalTab} from "./AdminCoachApprovalTab";
import {AdminGamesTab} from "./AdminGamesTab";
import useCookie from "react-use-cookie";
import {AdminLabTab} from "./AdminLabTab";
import {Bank2, CardChecklist, Cash, Controller, Droplet, Person, Shield} from "react-bootstrap-icons";
import {AdminCoachesTab} from "./AdminCoachesTab";
import {AdminAccessTab} from "./AdminAccessTab";
import {AdminGainsTab} from "../coach/AdminGainsTab";

export const AdminDashboardPage = () => {
    const [adminDashboardTabIndex, setAdminDashboardTabIndex] = useCookie('adminDashboardTabIndex', "first");
    return (
        <Container className={'page admin-page'}>
            <h2 className={"primary-color dashboard-title pb-5"}>Admin Dashboard</h2>
            <div className={'py-4'}>
                <Tab.Container defaultActiveKey={adminDashboardTabIndex}
                               onSelect={(tab) => setAdminDashboardTabIndex(tab ?? "first")}>
                    <Row>
                        <Col sm={3} className={"dashboard-left-panel-main"}>
                            <Nav variant="pills" className="flex-column dashboard-sidebar">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><Bank2/>&nbsp;&nbsp; Overview</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><CardChecklist/>&nbsp;&nbsp; Coach Approvals</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="coaches"><Person/>&nbsp;&nbsp; Coaches</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="gains"><Cash/>&nbsp;&nbsp; All Purchases</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="admins"><Shield/>&nbsp;&nbsp; Admin Access</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third"><Controller/>&nbsp;&nbsp; Games</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="lab"><Droplet/>&nbsp;&nbsp; Lab</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <AdminOverviewTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <AdminCoachApprovalTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="coaches">
                                    <AdminCoachesTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="gains">
                                    <AdminGainsTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="admins">
                                    <AdminAccessTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <AdminGamesTab/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="lab">
                                    <AdminLabTab/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </Container>

    );
}
