import {useBusy} from "./use-busy";
import {useStoreActions} from "../models";
import React from "react";
import {TipCoachPopup, TipCoachPopupResult} from "../components/alerts/TipCoachPopup";
import {useUser} from "./use-user";

export function useTipCoach(coachId?: string): {
    isBusyTipCoach: boolean;
    tipCoach: () => Promise<void>;
} {
    const {isBusy, setIsBusy} = useBusy();
    const {userData: coachData} = useUser(coachId);
    const uiStoreActions = useStoreActions(state => state.ui);
    const economyStoreActions = useStoreActions((actions) => actions.economy);

    async function tipCoach(): Promise<void> {
        if (!coachId || !coachData) return;
        const value: TipCoachPopupResult = await uiStoreActions.addCustomAlert({
            title: 'Tip Coach',
            closeOnClickOutside: false,
            builder: (alertProps) => {
                return <TipCoachPopup {...alertProps} coachData={coachData}/>;
            }
        });
        console.log(value)
        if (!value) return;
        setIsBusy(true);
        const response = await economyStoreActions.tipCoach({
            coachId: coachId,
            amount: value.amount,
            tipMessage: value.tipMessage,
        });
        setIsBusy(false);
        await uiStoreActions.addMessageAlert({
            title: response.success ? 'Tipping successful!' : 'Failed to tip!',
            subtitle: response.message,
        });
    }

    return {
        isBusyTipCoach: isBusy,
        tipCoach,
    };
}
