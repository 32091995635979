import {UserSchema, UserSchemaExt} from "../schemas/user-schema";
import {PricingPack} from "../interfaces/pricing-pack";
import {GameSchema} from "../schemas/game-schema";
import {useMemo} from "react";

export function useCoachPricing(coachData: UserSchema | null | undefined, gameData: GameSchema | null | undefined): { pricingPack: PricingPack | null, chargingMode: string[] | false | null } {
    const pricingPack = useMemo(() => UserSchemaExt.getPricingPackWithGame(coachData, gameData), [coachData, gameData]);
    const chargingMode = useMemo(() => UserSchemaExt.getChargingModeWithGame(coachData, gameData), [coachData, gameData]);
    return {
        pricingPack,
        chargingMode,
    }
}
