import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {StoreProvider} from "easy-peasy";
import {store} from "./models";
import {App} from "./App";
import {FirebaseMessagingWrapper} from "./components/firebase/FirebaseMessagingWrapper";
import {ScrollToTop} from "./components/ScrollToTop";

type Props = StoreProvider["props"] & { children: React.ReactNode };
const StoreProviderCasted = StoreProvider as unknown as React.ComponentType<Props>;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <StoreProviderCasted store={store}>
        <Router>
            <FirebaseMessagingWrapper>
                <ScrollToTop>
                    <App/>
                </ScrollToTop>
            </FirebaseMessagingWrapper>
        </Router>
    </StoreProviderCasted>
);
