import {Col, Row} from "react-bootstrap";
import React, {FC} from "react";
import {VodSchema} from "../schemas/vod-schema";
import "./CoachVodListItem.scss";
import {VodTypeExt} from "../definitions/vod-type";
import {VodReviewStatus} from "../definitions/vod-review-status";
import {Clock, Link45deg, PersonWorkspace, Play} from "react-bootstrap-icons";
import {useStoreActions} from "../models";
import {VodCoachDisplayPopup} from "./alerts/VodCoachDisplayPopup";
import {TimeUtils} from "../utils/time-utils";

export const CoachVodListItem: FC<{ vodData: VodSchema }> = function (props) {
    const uiStoreActions = useStoreActions((actions) => actions.ui);

    async function onVodOpen(): Promise<void> {
        await uiStoreActions.addCustomAlert({
            title: `${props.vodData.name}`,
            customClass: 'coach-vod-alert',
            closeOnClickOutside: true,
            builder: (alertProps) => {
                return <VodCoachDisplayPopup {...alertProps} vodData={props.vodData}/>;
            }
        });
    }

    return (<div onClick={() => onVodOpen()} className={"coach-vod-list-item clickable mb-4"} key={props.vodData.id}>
            <div className="p-4">
                <Row>
                    <Col xl={5} className={"vod-thumbnail"}>
                        <img src="/assets/dashboard/vod-thumbnail.png" alt=""/>
                    </Col>
                    <Col xl={7} className={"pt-4 pt-xl-0"}>
                        <div
                            className="vod-title primary-color">{props.vodData.name}&nbsp;&nbsp; - &nbsp;&nbsp;{VodReviewStatus[props.vodData.vodReviewStatus]}</div>
                        <div className="vod-created pb-3">Submitted
                            on {TimeUtils.timestampToLocaleString(props.vodData.created)}</div>
                        <div className="vod-description pb-3">{props.vodData.description}</div>
                        <div className="vod-description pb-3"><Clock
                            size={20}/>&nbsp;&nbsp; {TimeUtils.hoursToHumanizedString(props.vodData.hours)}
                        </div>
                        <div className="vod-type pb-3"><Play
                            size={20}/>&nbsp;&nbsp; {VodTypeExt.toDisplayLabel(props.vodData.vodType)}</div>
                        <div className="vod-coach pb-3"><PersonWorkspace
                            size={20}/>&nbsp;&nbsp; {props.vodData.studentPointer.name}</div>
                        <div className="vod-status pb-2"></div>
                        <div className="vod-link pb-2"><Link45deg size={20}/>&nbsp;&nbsp; <a target={"_blank"}
                                                                                             href={props.vodData.vodUrl}>{props.vodData.vodUrl}</a>
                        </div>
                        <div className="vod-remark pb-3">{props.vodData.remarks}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
