import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useEarningsOfCoach} from "../../hooks/use-earnings";
import {CoachEarningListItem} from "../../components/CoachEarningListItem";
import {SpinnerPadded} from "../../components/SpinnerPadded";

export function CoachEarningsTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allEarnings, earningsLoading} = useEarningsOfCoach(user?.uid);

    return (<div>
        <h3 className={"primary-color"}>My Earnings</h3>
        <p>Review your earnings history below.</p>
        {earningsLoading && <SpinnerPadded/>}
        {userLoading || allEarnings?.map(earning =>
            <CoachEarningListItem key={earning.id} hasActions={true} earningData={earning}/>)}
    </div>);
}
