import "./CoachCardDisplay.scss"
import {StarFill} from "react-bootstrap-icons";
import React, {FC} from "react";
import {UserSchema, UserSchemaExt} from "../schemas/user-schema";
import {GameSchema} from "../schemas/game-schema";
import {routes} from "../App";
import {useNavigate} from "react-router-dom";
import {OnlineIndicator} from "./OnlineIndicator";
import _ from "lodash";
import {useCoachAvailability} from "../hooks/use-coach-availability";
import {TimeUtils} from "../utils/time-utils";

export const CoachCardDisplay: FC<{ userData: UserSchema, gameData: GameSchema | null }> = function (props) {
    const navigate = useNavigate();
    const {availabilityEvents} = useCoachAvailability(props.userData);

    return (
        <div className="display-coach-card">
            <div className={`coach-card ${UserSchemaExt.isOnline(props.userData) ? 'coach-card-online' : ''}`}>
                <div className="coach-card-outline">
                    <div className="coach-card-profile-click"
                         onClick={() => navigate(routes.coachProfile.replace(':username', props.userData.permalink!))}></div>
                    <div className="coach-card-img"><img
                        src={props.userData?.profileImage ?? '/assets/placeholders/game-placeholder.jpg'} alt=""/></div>
                    <div className="game-name">{props.gameData?.name?.toUpperCase() ?? 'UNKNOWN GAME'}</div>

                    <div className={"coach-card-content"}>
                        <div className="coach-name-main">
                            <div className="coach-name">{props.userData.displayName}</div>
                            <div className="coach-language">
                                <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                    {UserSchemaExt.languageInfo(props.userData)?.map(language => <div
                                        key={language.id}
                                        className="language-img px-1"><img
                                        src={language.flagUrl}
                                        width={25} alt=""/></div>)}
                                </div>
                            </div>
                            <div className={"online-indicator px-2"}><OnlineIndicator
                                isOnline={UserSchemaExt.isOnline(props.userData)}/></div>
                        </div>

                        <div className="coach-rating py-2"><StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/></div>

                        <div className="coach-details pt-1">
                            <div className="coach-point">
                                {props.userData.mainPoints?.length && <div className="coach-detail-row">
                                    <ul>
                                        {props.userData.mainPoints?.map((point) => <li className={"pb-2"}
                                                                                       key={point.id}>{_.truncate(point.label, {length: 30})}</li>)}
                                    </ul>
                                </div>}
                                {!props.userData.mainPoints?.length && <div className="coach-detail-row">
                                    <div>{props.userData.bio || 'No bio provided'}</div>
                                </div>}
                            </div>

                            <div className="coach-detail-column pt-3">
                                <div className="primary-color">General Availability :</div>
                                <div className="general-availability-main">
                                    {_.isEmpty(availabilityEvents) && 'Availability not specified yet.'}
                                    {availabilityEvents.map((availability) => {
                                        return <div key={JSON.stringify(availability)}
                                                    className={'general-availability-row pb-3'}>
                                            <div>{TimeUtils.dateToLocaleWeekdayString(availability[0])} {TimeUtils.dateToLocaleTimeString(availability[0])}</div>
                                            to
                                            <div>{TimeUtils.dateToLocaleWeekdayString(availability[1])} {TimeUtils.dateToLocaleTimeString(availability[1])}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
