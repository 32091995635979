import React, {FC, PropsWithChildren} from 'react';
import LoadingOverlayWrapper from "react-loading-overlay-ts";

export const BusyOverlay: FC<{ isBusy: boolean; text?: string, bgColor?: string } & PropsWithChildren> = (props) => {
    return (
        <LoadingOverlayWrapper
            active={props.isBusy}
            spinner
            text={props.text ?? 'Loading...'}
            styles={{
                wrapper: (base) => ({...base, height: '100%'}),
                overlay: (base) => ({...base, backgroundColor: props.bgColor ?? 'rgba(16,24,32,0.9)'}),
                content: (base) => ({...base, color: '#ffffff', fontSize: 13}),
                spinner: (base) => ({
                    ...base, '& svg circle': {
                        stroke: 'rgb(255,255,255)'
                    }
                }),
            }}
        >
            {props.children}
        </LoadingOverlayWrapper>
    );
};
