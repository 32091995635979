import React, {useEffect, useMemo, useState} from "react";
import {Button, Card, Col, Container, Form, Nav, Row, Stack} from "react-bootstrap";
import './BookCoachPage.scss';
import {useNavigate, useParams} from "react-router-dom";
import {useUser} from "../../hooks/use-user";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import {CreateBookingRequestPayload} from "../../schemas/booking-schema";
import {useCurrentUser} from "../../hooks/use-current-user";
import {CalendarEvent} from "../../definitions/calendar-event";
import {v4 as uuid} from "uuid";
import {BookingMode} from "../../definitions/booking-mode";
import {useGameOnce} from "../../hooks/use-game";
import {PricingMode} from "../../definitions/pricing-mode";
import {GameSchemaExt} from "../../schemas/game-schema";
import {FieldError} from "../../components/form/FieldError";
import {useBookingsOfCoach} from "../../hooks/use-bookings";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import {BookingStatus} from "../../definitions/booking-status";
import {CoachCardDisplay} from "../../components/CoachCardDisplay";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {toast} from "react-toastify";
import {BookingRequestedPopup} from "../../components/alerts/BookingRequestedPopup";
import _ from "lodash";
import {DateRange, DateRangeList, SlotAvailability, TimeUtils} from "../../utils/time-utils";
import humanizeDuration from "humanize-duration";
import ReactDatePicker from "react-datepicker";
import {SlotAvailabilityView} from "../../components/SlotAvailabilityView";
import {UserSchemaExt} from "../../schemas/user-schema";
import {useCoachPricing} from "../../hooks/use-coach-pricing";
import {DateTime} from "luxon";

export const BookCoachPage = () => {
    const {isBusy, setIsBusy} = useBusy();
    let {username} = useParams();
    const navigate = useNavigate();
    const {userData: coachData, userLoading, userError} = useUser(username!);
    const {game} = useGameOnce(coachData?.coachingGame);
    const {user} = useCurrentUser();
    const bookingStoreAction = useStoreActions((actions) => actions.booking);
    // todo incorporate background events
    const [backgroundEvents, setBackgroundEvents] = useState<CalendarEvent[]>([])
    const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);
    useEffect(() => {
        if (!coachData) return;
        setSelectedDate(TimeUtils.nowTz(UserSchemaExt.getTimezone(coachData)));
    }, [coachData]);
    const [selectedSlot, setSelectedSlot] = useState<SlotAvailability | null>(null);
    const [selectedRange, setSelectedRange] = useState<DateRange | null>(null);
    const [selectedBookingEvent, setSelectedBookingEvent] = useState<CalendarEvent | null>()
    const uiStoreActions = useStoreActions(state => state.ui);
    const {pricingPack: finalPricingPack, chargingMode: finalChargingMode} = useCoachPricing(coachData, game);
    // scheduling mode state members
    const [bookingMode, setBookingMode] = useState<BookingMode>(BookingMode.Instant);
    const {allBookings} = useBookingsOfCoach(coachData?.uid, [BookingStatus.Accepted, BookingStatus.Complete]);
    const isScheduleMode = bookingMode === BookingMode.Scheduled;
    useEffect(() => {
        const allEvents = allBookings?.filter(bookingData => !!bookingData.booking).map(bookingData => bookingData.booking!) ?? [];
        setBackgroundEvents(allEvents)
    }, [allBookings])
    // pricing mode state members
    const pricingModes = useMemo(() => {
        if (!finalPricingPack || !finalChargingMode) return [];
        return [PricingMode.Hourly, PricingMode.PerGame, PricingMode.Ranked].filter(mode => GameSchemaExt.hasPricingMode(finalChargingMode, finalPricingPack, mode));
    }, [finalPricingPack, finalChargingMode]);

    const [activePricingMode, setActivePricingMode] = useState<PricingMode | null>(null);

    useEffect(() => {
        if (!!pricingModes.length && activePricingMode === null) setActivePricingMode(pricingModes[0]);
    }, [pricingModes]);
    useEffect(() => {
        setSelectedBookingEvent(null);
        setSelectedSlot(null);
        if (coachData) setSelectedDate(TimeUtils.nowTz(UserSchemaExt.getTimezone(coachData)));
    }, [bookingMode])
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch,
        trigger
    } = useForm<CreateBookingRequestPayload>({
        shouldUnregister: true,
    });

    const {hours} = watch();
    useEffect(() => {
        setValue('hours', 1)
    }, [])


    useEffect(() => {
        if (!selectedSlot || !hours) {
            setSelectedRange(null);
        } else {
            const suggestedRange: DateRange = [selectedSlot.slot[0], selectedSlot.slot[0].plus({hour: hours})];
            const isAvailable = isRangeAvailableExhaustive({
                start: suggestedRange[0],
                end: suggestedRange[1]
            });
            if (isAvailable) {
                setSelectedRange(suggestedRange);
                const newEvent: CalendarEvent = {
                    id: uuid(),
                    start: suggestedRange[0].toJSDate(),
                    end: suggestedRange[1].toJSDate(),
                    title: 'New Event'
                };
                setSelectedBookingEvent(newEvent);
            } else {
                toast('Selected slot is invalid for the duration you selected.')
                setSelectedRange(null);
                setSelectedSlot(null);
            }
        }
    }, [selectedSlot, hours])

    function onSlotClick(slot: SlotAvailability) {
        setSelectedSlot(slot);
    }

    const [availabilityEvents, setAvailabilityEvents] = useState<DateRangeList>([]);
    const [availabilityOfSelectedDay, setAvailabilityOfSelectedDay] = useState<SlotAvailability[]>([]);

    useEffect(() => {
        if (!selectedDate || !coachData?.availabilityRanges) return;
        updateAvailabilityEvents(selectedDate);
    }, [coachData?.availabilityRanges, selectedDate])

    function updateAvailabilityEvents(targetDate: DateTime): void {
        const availabilityEvents: DateRangeList = coachData?.availabilityRanges?.map(event => {
            return [
                TimeUtils.getStartOfWeek(targetDate).plus(TimeUtils.weeklyHalfHourIdToOffset(event[0])),
                TimeUtils.getStartOfWeek(targetDate).plus(TimeUtils.weeklyHalfHourIdToOffset(event[1], true))];
        }) ?? [];
        setAvailabilityEvents(availabilityEvents);
    }

    useEffect(() => {
        if (!selectedDate) return;
        setAvailabilityOfSelectedDay(getSlotAvailabilityOfDay(selectedDate));
        setSelectedSlot(null);
    }, [selectedDate, availabilityEvents]);


    const onSubmit: SubmitHandler<CreateBookingRequestPayload> = async (payload) => {
        console.log(payload)
        if (activePricingMode === null || !coachData?.uid || !coachData.coachingGame || !user?.uid) return;
        if (bookingMode === BookingMode.Scheduled && !selectedBookingEvent) {
            await uiStoreActions.addMessageAlert({
                title: 'Cannot Proceed',
                subtitle: 'Please select a time slot on the calendar'
            });
            return;
        }
        if (bookingMode === BookingMode.Instant && !UserSchemaExt.isOnline(coachData)) {
            await uiStoreActions.addMessageAlert({
                title: 'Instant booking unavailable',
                subtitle: 'Cannot book an instant session because the coach is not online. Please make a future booking.'
            });
            return;
        }
        setIsBusy(true);
        const bookingData = {
            ...payload,
            booking: selectedBookingEvent ?? null,
            pricingMode: activePricingMode,
            gameId: coachData.coachingGame,
            coachPointer: {
                id: coachData.uid,
                name: coachData.displayName
            },
            studentPointer: {
                id: user?.uid,
                name: user.displayName,
            },
            bookingMode: bookingMode,
        };
        console.log('bookingData', bookingData);
        const response = await bookingStoreAction.createBooking(bookingData);
        setIsBusy(false);
        if (!response.success || !response.data) {
            await uiStoreActions.addMessageAlert({
                title: 'Booking Failed!',
                subtitle: response.message,
            });
            return;
        }
        navigate(-1);
        toast(`Booking request submitted to coach '${coachData.displayName}' successfully!`);
        await uiStoreActions.addCustomAlert({
            title: 'Session Request Submitted',
            closeOnClickOutside: false,
            builder: (alertProps) => {
                return <BookingRequestedPopup {...alertProps} bookingId={response.data!}/>;
            }
        });
    };

    async function onDiscard(): Promise<void> {
        navigate(-1);
    }

    if (!userLoading && userError) return <p>{userError.message}</p>

    function buildSelectedEvent() {
        return <div className={'mt-4'}>
            <div className={'selected-time-label'}>From:</div>
            <div>{TimeUtils.dateToLocaleString(selectedBookingEvent?.start)} </div>
            <div className={'selected-time-label'}>To:</div>
            <div>{TimeUtils.dateToLocaleString(selectedBookingEvent?.end)}</div>
        </div>;
    }

    function buildFeeDisplay(amount: number) {
        return <h4>Session Fee: $ {amount}</h4>
    }

    function onSliderChange(value: number) {
        setValue('hours', value / 60);
    }

    function getMaxSliderValue(): number {
        return 60 * 6;
    }

    function getCurrentSliderValue(): number {
        return 60 * (hours ?? 0);
    }


    function buildBookingModeSelector() {
        return (
            <Nav variant="pills" activeKey={bookingMode} onSelect={(eventKey: any) => {
                setBookingMode(_.toNumber(eventKey))
            }}>
                <Nav.Item className={'booking-mode-tab'}>
                    <Nav.Link eventKey={BookingMode.Instant}>Instant Booking</Nav.Link>
                </Nav.Item>
                <Nav.Item className={'booking-mode-tab'}>
                    <Nav.Link eventKey={BookingMode.Scheduled}>Future Booking</Nav.Link>
                </Nav.Item>
            </Nav>
        );
    }

    function isRangeAvailableExhaustive(range: { start: DateTime, end: DateTime }): boolean {
        const tree = TimeUtils.getRangeTreeForDateRanges(availabilityEvents);
        let i = range.start;
        while (i < range.end || i.equals(range.end)) {
            const searchHalfHourRange = TimeUtils.inclusiveDateRangeToHalfHourRange([i.minus({minute: 30}), i.plus({minute: 30})]);
            const overlaps = tree.search(searchHalfHourRange);
            if (overlaps.length === 0) {
                return false;
            }
            i = i.plus({minute: 30});
        }
        return true;
    }

    function getSlotAvailabilityOfDay(date: DateTime): SlotAvailability[] {
        const startOfDay = date.startOf("day");
        const endOfDay = date.endOf("day");
        let i = startOfDay;
        const allAvailableSlots: SlotAvailability[] = [];
        while (i < endOfDay || i.equals(endOfDay)) {
            const isAvailable = isRangeAvailableExhaustive({
                start: i,
                end: i.plus({minute: 30}),
            })
            allAvailableSlots.push({
                slot: [i, i.plus({minute: 30})],
                isAvailable,
            })
            i = i.plus({minute: 30});
        }
        return allAvailableSlots;
    }

    return (
        <Container className={'page book-coach-page py-5'}>
            <BusyOverlay isBusy={isBusy}>
                <div className={"coach-calendar"}>
                    {coachData && <Row>
                        <Form onSubmit={handleSubmit(onSubmit)} className={"d-flex"}>
                            <Col>
                                <Card className={'p-5'}>
                                    <h1 className={"text-center primary-color"}>Book
                                        Coaching Session</h1>
                                    <h4 className={"text-center pb-5"}>Book a date and time to train
                                        with {coachData.displayName}</h4>
                                    <Row>
                                        <Col lg={4}>
                                            <CoachCardDisplay userData={coachData} gameData={game ?? null}/>
                                            <div className={'mb-5 px-4'}>
                                                {selectedBookingEvent && buildSelectedEvent()}
                                                <Form.Group className={'mt-3'}>
                                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Leave any
                                                        additional information for your coach:</Form.Label>
                                                    <Form.Control
                                                        type={'text'}
                                                        placeholder="Additional Notes"
                                                        {...register("messageToCoach", {
                                                            shouldUnregister: true
                                                        })}
                                                    />
                                                    <FieldError message={errors.messageToCoach?.message}/>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg={8}>

                                            {buildBookingModeSelector()}
                                            <div className={'pt-5 pb-5 px-2'}>
                                                {activePricingMode === PricingMode.Hourly &&
                                                    <Stack direction={"vertical"}>
                                                        <div className={'d-flex'}
                                                             style={{justifyContent: "space-between"}}>
                                                            <div><h4>{humanizeDuration((hours ?? 0) * 3600 * 1000)}</h4>
                                                            </div>
                                                            <div>{game && buildFeeDisplay((finalPricingPack!.hourlyRate || 0) * (hours || 0))}</div>
                                                        </div>
                                                        <div className="book-coach-slider pt-3"><Slider
                                                            handleStyle={{width: 27, height: 27, marginTop: -11}}
                                                            value={getCurrentSliderValue()} min={60}
                                                            max={getMaxSliderValue()} range={false}
                                                            step={60}
                                                            defaultValue={0}
                                                            onChange={(value) => onSliderChange(value as number)}/>
                                                        </div>
                                                    </Stack>}
                                            </div>
                                            {isScheduleMode && user && <Form.Group>
                                                <Form.Label>Booking Date</Form.Label>
                                                <ReactDatePicker
                                                    selected={selectedDate?.toJSDate()}
                                                    dateFormat={'EEE dd/MM/yyyy'}
                                                    onChange={(e) => {
                                                        if (!e) {
                                                            setSelectedDate(null);
                                                            return;
                                                        }
                                                        const coachZone = TimeUtils.findTimezoneByZoneName(coachData?.timezone);
                                                        if (coachZone) setSelectedDate(DateTime.fromJSDate(e).setZone(coachZone));
                                                    }}
                                                    className="form-control"
                                                    minDate={new Date()}
                                                    placeholderText={'Select Booking Date'}
                                                />
                                            </Form.Group>}
                                            {isScheduleMode && selectedDate && <SlotAvailabilityView
                                                availabilityList={availabilityOfSelectedDay}
                                                selectedRange={selectedRange}
                                                onClickSlot={onSlotClick}
                                            />}
                                        </Col>
                                    </Row>
                                    <Stack direction={"horizontal"} gap={2}
                                           className={'mt-3  mb-4 coach-calendar-btn-main'}>
                                        <Button type="submit" className={"calendar-save-btn w-100"}>
                                            Book
                                        </Button>
                                        <Button className={"w-100 calendar-discard-btn"}
                                                onClick={() => onDiscard()}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Card>
                            </Col>
                        </Form>
                    </Row>}
                </div>
            </BusyOverlay>
        </Container>);
}
