import {thunk, Thunk} from "easy-peasy";
import {functions} from "../config/firebase-config";
import {
    CompleteBookingPayload,
    CreateBookingRequestPayload,
    ProceedBookingPayload,
    RespondToVodPayload,
    SetCoachAvailabilityPayload
} from "../schemas/booking-schema";
import {httpsCallable} from "firebase/functions";
import {CallableResponse} from "../definitions/callable-response";
import _ from "lodash";
import {SubmitVodPayload} from "../interfaces/submit-vod-payload";
import {TypedCallableResponse} from "../definitions/typed-callable-response";

export interface BookingModel {
    submitVod: Thunk<BookingModel, SubmitVodPayload, any, {}, Promise<boolean>>;
    createBooking: Thunk<BookingModel, CreateBookingRequestPayload, any, {}, Promise<TypedCallableResponse<string>>>;
    respondToVodReview: Thunk<BookingModel, RespondToVodPayload, any, {}, Promise<CallableResponse>>;
    setCoachAvailability: Thunk<BookingModel, SetCoachAvailabilityPayload, any, {}, Promise<CallableResponse>>;
    proceedBooking: Thunk<BookingModel, ProceedBookingPayload, any, {}, Promise<boolean>>;
    completeSession: Thunk<BookingModel, CompleteBookingPayload, any, {}, Promise<boolean>>;
}

export const booking: BookingModel = {
    /* thunks */
    submitVod: thunk(async (actions, vodData) => {
        try {
            console.log(vodData)
            const submitVodCallable = httpsCallable<SubmitVodPayload, CallableResponse>(functions, 'submitVod');
            const response = await submitVodCallable(vodData);
            console.log('VOD submit response', response)
            return response.data.success;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
    createBooking: thunk(async (actions, bookingData) => {
        try {
            console.log(bookingData)
            const createBookingRequestCallable = httpsCallable<CreateBookingRequestPayload, TypedCallableResponse<string>>(functions, 'createBookingRequest');
            const formattedBookingData: any = _.cloneDeep(bookingData);
            if (bookingData.booking?.start) {
                formattedBookingData.booking.start = bookingData.booking.start.valueOf();
            }
            if (bookingData.booking?.end) {
                formattedBookingData.booking.end = bookingData.booking.end.valueOf();
            }
            const response = await createBookingRequestCallable(formattedBookingData);
            console.log('Booking response', response)
            return response.data;
        } catch (ex: any) {
            console.error("Error adding document: ", ex);
            return {
                success: false,
                message: ex.toString(),
            } as TypedCallableResponse<string>;
        }
    }),
    respondToVodReview: thunk(async (actions, respondToPayload) => {
        try {
            console.log(respondToPayload)
            const respondToVodReviewCallable = httpsCallable<RespondToVodPayload, CallableResponse>(functions, 'respondToVodReview');
            const response = await respondToVodReviewCallable(respondToPayload);
            console.log('Booking response', response)
            return response.data;
        } catch (ex: any) {
            console.error("Error adding document: ", ex);
            return {
                success: false,
                message: ex.toString(),
            } as CallableResponse;
        }
    }),
    setCoachAvailability: thunk(async (actions, availabilityPayload) => {
        try {
            console.log('availabilityPayload:', availabilityPayload);
            const respondToVodReviewCallable = httpsCallable<SetCoachAvailabilityPayload, CallableResponse>(functions, 'setCoachAvailability');
            const response = await respondToVodReviewCallable(availabilityPayload);
            console.log('setCoachAvailability response', response)
            return response.data;
        } catch (ex: any) {
            console.error("Error adding document: ", ex);
            return {
                success: false,
                message: ex.toString(),
            } as CallableResponse;
        }
    }),
    proceedBooking: thunk(async (actions, booking) => {
        try {
            const proceedBookingRequestCallable = httpsCallable<ProceedBookingPayload, CallableResponse>(functions, 'proceedBookingRequest');
            const response = await proceedBookingRequestCallable(booking);
            return response.data.success;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
    completeSession: thunk(async (actions, booking) => {
        try {
            const completeSessionCallable = httpsCallable<CompleteBookingPayload, CallableResponse>(functions, 'completeSession');
            const response = await completeSessionCallable(booking);
            return response.data.success;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
};
