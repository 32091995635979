import {useStoreActions} from "../models";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useUpdatePassword} from "react-firebase-hooks/auth";
import {auth} from "../config/firebase-config";

export function useChangePassword(): {
    changePassword: () => void,
    isBusyChangePassword: boolean,
} {
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const [updatePassword, updating, error] = useUpdatePassword(auth);

    useEffect(() => {
        if (error) {
            uiStoreActions.addMessageAlert({
                title: 'Password change failed!',
                subtitle: `Please retry later or contract an administrator. Error: ${error?.message}`,
            });
        }
    }, [error])

    async function changePassword(): Promise<void> {
        const newPassword = await uiStoreActions.addSingleInputAlert({
            title: "Change Password",
            message: "Enter a new password for your account. Passwords must be at least 6 characters long.",
            inputType: 'password',
            required: true,
        });
        if (!newPassword) return;
        const confirmPassword = await uiStoreActions.addSingleInputAlert({
            title: "Confirm Password",
            message: "Please re-enter your password for confirmation.",
            inputType: 'password',
            required: true,
        });
        if (!confirmPassword) return;
        if (newPassword !== confirmPassword) {
            await uiStoreActions.addMessageAlert({
                title: 'Passwords do not match',
                subtitle: 'Please recheck the password you entered and retry.',
            });
            return;
        }
        await updatePassword(newPassword);
        toast('Password updated successfully!');
    }

    return {
        changePassword,
        isBusyChangePassword: updating,
    }
}
