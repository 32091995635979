import React, {FC} from "react";
import "./ComingSoonCoachCard.scss"

export const ComingSoonCoachCard: FC<{ name: string, email: string }> = function (props) {

    return (
        <div className={'coming-soon-coach-card mx-5'}>
            <div className={"coach-card"}>
                <div className="coach-card-overlay">
                    <h2>Coming Soon</h2>
                </div>
                <div className="coach-card-outline">
                    <div className="coach-card-img"><img
                        src={'/assets/placeholders/game-placeholder.jpg'}
                        alt=""/></div>
                    <div className="game-name">{'...'}</div>

                    <div className={"coach-card-content"}>
                        <div className="coach-name-main">

                            <div className="coach-name">
                                <div>{props.name}</div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
