import IntervalTree, {Interval} from "@flatten-js/interval-tree";
import humanizeDuration from "humanize-duration";
import {Timestamp} from "firebase/firestore";
import {DateTime, Duration, IANAZone, Settings, Zone} from "luxon";

export type DateRange = [DateTime, DateTime];
export type DateRangeList = DateRange[];
export type SlotAvailability = { slot: DateRange, isAvailable: boolean };

export class TimeInterval extends Interval {
    not_intersect(other_interval: Interval) {
        return (this.high <= other_interval.low || other_interval.high <= this.low);
    }
}

export abstract class TimeUtils {
    public static dateRangeOfTz(start: number, end: number, timezone: Zone): DateRange {
        return [this.dateOfTz(start, timezone), this.dateOfTz(end, timezone)]
    }

    public static nowTz(timezone: Zone): DateTime {
        return DateTime.now().setZone(timezone.name);
    }

    public static readonly defaultTimezone = TimeUtils.findTimezoneByZoneName('America/New_York');

    public static dateToHalfHourId(date: DateTime): number {
        if (date.second === 59) date = date.plus({second: 1});
        return Math.floor(date.toMillis() / 1_800_000)
    }

    public static dateToWeeklyHalfHourId(date: DateTime): number {
        if (date.second === 59) {
            const rawStartOfWeek = this.getStartOfWeek(date);
            const shiftedDate = date.plus({second: 1});
            if (rawStartOfWeek.equals(this.getStartOfWeek(shiftedDate))) {
                date = shiftedDate;
            }
        }
        const diffMillis = date.toMillis() - this.getStartOfWeek(date).toMillis();
        return Math.floor(diffMillis / 1_800_000);
    }

    public static weeklyHalfHourIdToDate(halfHourIndex: number, referenceDate: DateTime, subtractMidnightSecond = false): DateTime {
        const startOfWeek = this.getStartOfWeek(referenceDate);
        let fullDate = startOfWeek.plus(Duration.fromObject({
            millisecond: 1_800_000 * halfHourIndex,
        }));
        if (subtractMidnightSecond && fullDate.hour === 0 && fullDate.minute === 0) {
            return fullDate.minus({second: 1});
        }
        return fullDate;
    }

    public static getStartOfWeek(date: DateTime): DateTime {
        const startOfWeek = date.weekday === 7 ? date.startOf('week').plus({week: 1}).minus({day: 1}) : date.startOf('week').minus({day: 1})
        console.log('>>> date:', date.toLocaleString(DateTime.DATETIME_SHORT), '\nstartOfWeek:', startOfWeek.toLocaleString(DateTime.DATETIME_SHORT));
        return startOfWeek
    }

    public static weeklyHalfHourIdToOffset(halfHourIndex: number, subtractMidnightSecond = false): Duration {
        const day = Math.floor(halfHourIndex / 48);
        const hour = Math.floor((halfHourIndex % 48) / 2);
        let minute = ((halfHourIndex % 48) % 2) * 30;
        let duration = Duration.fromObject({
            days: day,
            hours: hour,
            minutes: minute,
        });
        if (subtractMidnightSecond && hour === 0 && minute === 0) {
            return duration.minus({second: 1});
        }
        return duration;
    }

    public static halfHourIdToDateOfTz(halfHourIndex: number, timezone: Zone): DateTime {
        return this.dateOfTz(halfHourIndex * 1000 * 60 * 30, timezone);
    }

    public static dateRangeToString(range: DateRange): string {
        return `[${range[0].toString()},${range[1].toString()}]`
    }

    public static inclusiveHalfHourIdRangeToDateRangeTz(range: [halfHourIndexA: number, halfHourIndexB: number], timezone: Zone): DateRange {
        return [this.halfHourIdToDateOfTz(range[0], timezone), this.halfHourIdToDateOfTz(range[1], timezone)];
    }

    public static inclusiveDateRangeToHalfHourRange(range: DateRange): [number, number] {
        return [this.dateToHalfHourId(range[0]), this.dateToHalfHourId(range[1])];
    }

    // range processing
    public static getRangeTreeForDateRanges(dateRanges?: DateRangeList): IntervalTree {
        const tree = new IntervalTree();
        for (const dateRange of (dateRanges ?? [])) {
            const halfHourRange = this.inclusiveDateRangeToHalfHourRange(dateRange);
            tree.insert(new TimeInterval(...halfHourRange));
        }
        return tree;
    }

    public static roundDateTo30MinTz(date: DateTime, timezone: Zone): DateTime {
        return this.halfHourIdToDateOfTz(this.dateToHalfHourId(date), timezone);
    }

    public static roundDateRangeTo30MinTz(dateRange: DateRange, timezone: Zone): DateRange {
        return [this.halfHourIdToDateOfTz(this.dateToHalfHourId(dateRange[0]), timezone), this.halfHourIdToDateOfTz(this.dateToHalfHourId(dateRange[1]), timezone)]
    }

    public static hoursToHumanizedString(hours: number): string {
        return humanizeDuration(Duration.fromObject({hours: hours}).toMillis());
    }

    public static timestampToLocaleString(timestamp: Timestamp | null | undefined, ifNull = '-'): string {
        if (!timestamp) return ifNull;
        return DateTime.fromMillis(timestamp.toMillis()).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {locale: 'en-US'});
    }

    public static dateToLocaleString(dateTime: Date | null | undefined, ifNull = '-'): string {
        if (!dateTime) return ifNull;
        return DateTime.fromJSDate(dateTime).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {locale: 'en-US'});
    }

    public static dateToLocaleTimeString(dateTime: DateTime | null | undefined, ifNull = '-'): string {
        if (!dateTime) return ifNull;
        return dateTime.setZone(TimeUtils.guessTimezone()).toLocaleString(DateTime.TIME_SIMPLE, {locale: 'en-US'});
    }

    public static dateToLocaleWeekdayString(dateTime: DateTime | null | undefined, ifNull = '-'): string {
        if (!dateTime) return ifNull;
        return dateTime.setZone(TimeUtils.guessTimezone()).weekdayShort;
    }

    static timestampToUtcString(timestamp: Timestamp | null | undefined, ifNull = '-'): string {
        return timestamp?.toDate().toUTCString() ?? ifNull;
    }

    // extra features
    public static parseTimestamp(dateTimeStr: string): Timestamp | null {
        const parsedEpoch: number = Date.parse(dateTimeStr);
        if (isNaN(parsedEpoch)) return null;
        return Timestamp.fromMillis(parsedEpoch);
    }

    public static parseDate(dateTimeStr: string, timezone: Zone): DateTime | null {
        const parsedEpoch: number = Date.parse(dateTimeStr);
        if (isNaN(parsedEpoch)) return null;
        return this.dateOfTz(parsedEpoch, timezone);
    }

    public static dateOfTz(date: number, timezone: Zone, keepLocalTime?: boolean): DateTime {
        return DateTime.fromMillis(date).setZone(timezone.name, {keepLocalTime: keepLocalTime});
    }

    public static dateOfTzName(date: number, timezone: string | null | undefined, keepLocalTime?: boolean): DateTime | null {
        if (!timezone) return null;
        const tz = this.findTimezoneByZoneName(timezone);
        if (!tz) return null;
        return this.dateOfTz(date, tz, keepLocalTime);
    }

    public static findTimezoneByZoneName(zoneName: string | undefined | null): Zone | null {
        if (!zoneName) return null;
        const timezoneInfo = new IANAZone(zoneName);
        if (!timezoneInfo.isValid) console.error('Could not find the timezone:', zoneName)
        return timezoneInfo ?? null;
    }

    public static diffDuration(dateA: DateTime, dateB: DateTime): Duration {
        return dateA.diff(dateB);
    }

    public static zeroDuration(): Duration {
        return Duration.fromMillis(0);
    }

    public static negateDuration(duration: Duration | undefined | null): Duration {
        if (!duration) return this.zeroDuration();
        return Duration.fromMillis(-duration.toMillis());
    }

    public static absDuration(duration: Duration | undefined | null): Duration {
        if (!duration) return this.zeroDuration();
        return Duration.fromMillis(Math.abs(duration.toMillis()));
    }

    static guessTimezone(): Zone {
        return this.findTimezoneByZoneName((Settings.defaultZone as Zone).name) ?? this.defaultTimezone!;
    }

    static guessTimezoneName(): string {
        return this.guessTimezone().name;
    }
}
