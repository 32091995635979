import _ from "lodash";

export abstract class EnvUtils {
    public static readBool(key: string): boolean {
        if (!key.startsWith('REACT_APP_')) throw Error('Invalid react env key: Must start with REACT_APP')
        const rawValue = process.env[key];
        if (!rawValue) return false;
        if (rawValue?.toLowerCase()?.trim() === 'false') return false;
        if (rawValue?.toLowerCase()?.trim() === 'no') return false;
        if (rawValue?.toLowerCase()?.trim() === 'off') return false;
        return !!_.toNumber(rawValue);
    }

    public static readString(key: string): string {
        if (!key.startsWith('REACT_APP_')) throw Error('Invalid react env key: Must start with REACT_APP')
        return process.env[key] ?? '';
    }

    public static readNumber(key: string, ifNaN = 0): number {
        if (!key.startsWith('REACT_APP_')) throw Error('Invalid react env key: Must start with REACT_APP')
        const raw = _.toNumber(process.env[key]);
        return _.isNaN(raw) ? ifNaN : raw;
    }
}
