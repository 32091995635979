import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {ModelPointer} from "../interfaces/model-pointer";
import {GainType} from "../definitions/gain-type";

export interface GainSchema {
    id: string;
    created: Timestamp;
    targetUserPointer: ModelPointer;
    totalAmount: number;
    creditAmount: number;
    houseAmount: number;
    gainType: GainType;
}

export const gainConverter: FirestoreDataConverter<GainSchema> = {
    toFirestore(gain: WithFieldValue<GainSchema>): DocumentData {
        return gain;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): GainSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as GainSchema;
    },
};
