import * as React from 'react';
import {MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";

type CheckboxButtonProps = {
    size?: number;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
};

export function CheckboxButton(props: CheckboxButtonProps) {
    return (
        <div className={'clickable'} onClick={() => props.onChange(!props.isChecked)}>
            {props.isChecked ? <MdCheckBox size={props.size ?? 25}/> :
                <MdCheckBoxOutlineBlank size={props.size ?? 25}/>}
        </div>
    );
};
