import React, {useEffect, useState} from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import './ChatPage.scss'
import {Container} from "react-bootstrap";
import {useSecret} from "../../hooks/use-secrets";
import {MultiChatSocket, MultiChatWindow, useMultiChatLogic} from "react-chat-engine-advanced";
import {ChatEngineConfig} from "../../config/chat-engine-config";
import {ChatEngineUtils} from "../../utils/chat-engine-utils";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

export function ChatPage() {
    const {user} = useCurrentUser();
    const {userId: targetUserId} = useParams();
    const {secretData} = useSecret(user?.uid);
    return (<>
        {user?.uid && secretData?.chatEngineSecret &&
            <ChatRenderer targetUser={targetUserId} chatEngineSecret={secretData.chatEngineSecret} uid={user?.uid}/>}
    </>);
}

function ChatRenderer(props: {
    chatEngineSecret: string;
    uid: string;
    targetUser: string | null | undefined;
}) {
    const {isBusy, setIsBusy} = useBusy();
    const [chatReady, setChatReady] = useState<boolean>(false);
    const chatProps = useMultiChatLogic(
        ChatEngineConfig.projectId,
        `${props.uid}`,
        props.chatEngineSecret,
    );
    useEffect(() => {
        if (props.targetUser) {
            createDirectChatWithUser().then(() => setChatReady(true));
        } else {
            setChatReady(true);
        }
    }, [props.targetUser]);

    async function createDirectChatWithUser() {
        if (!props.targetUser) return;
        setIsBusy(true);
        try {
            const newChatInfo = await ChatEngineUtils.getOrCreateChat({
                is_direct_chat: true,
                usernames: [props.targetUser],
                senderSecret: props.chatEngineSecret,
                senderUsername: props.uid,
                title: [props.uid, props.targetUser].sort().join('-H-')
            });
            chatProps.setActiveChatId(newChatInfo.id);
            console.log(`Chat created with ${props.targetUser}`)
        } catch (ex) {
            toast(`Failed to load chat with error: ${ex}`)
        }
        setIsBusy(false);
    }

    return <Container className={"page chat-page pt-4 pb-5"}>
        <h2 className={"primary-color dashboard-title pb-3"}>Messages</h2>
        <hr/>
        <div className={'pt-3'}></div>
        <BusyOverlay isBusy={isBusy}>
            <div style={{height: 800}}>
                {chatReady && <div id={'chat-section'}>
                    <MultiChatWindow
                        {...chatProps

                        } />
                    <MultiChatSocket {...chatProps} />
                </div>}
            </div>
        </BusyOverlay>
    </Container>;
}
