import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Container, Form, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {
    Configure,
    Hits,
    HitsPerPage,
    InstantSearch,
    Pagination,
    SearchBox,
    useToggleRefinement
} from "react-instantsearch-hooks-web";
import './AlgoliaCoachesPage.scss'
import {CoachHit} from "../../components/CoachHit";
import _ from "lodash";
import {Facet} from "../../definitions/facet";
import {FacetRefinementList} from "../../components/algolia/FacetRefinementList";
import {useParams} from "react-router-dom";
import {useGames} from "../../hooks/use-games";
import {AlgoliaUserSchema} from "../../schemas/user-schema";
import {GameUtils} from "../../utils/game-utils";
import {AlgoliaConfig, searchClient} from "../../config/algolia-config";
import {ComingSoonCoachCard} from "../../components/ComingSoonCoachCard";
import {DataConfig} from "../../config/data-config";

export const AlgoliaCoachesPage = () => {
    const [facets, setFacets] = useState<Facet[]>([]);
    const usersIndex = useMemo(() => searchClient.initIndex(AlgoliaConfig.usersIndexName), []);
    useEffect(() => {
        initializeIndex().then();
    }, []);

    async function initializeIndex() {
        const settings = await usersIndex.getSettings();
        console.log(settings);
        const facets = _(settings.attributesForFaceting)
            .filter(item => item.includes('searchable(facets.'))
            .map(item => _.trimEnd(_.trimStart(item, 'searchable('), ')'))
            .uniq()
            .map(item => {
                return {
                    id: item,
                    label: _.split(item, '.')[1],
                }
            })
            .sortBy(item => item.label === 'Game' ? 0 : 1)
            .value();
        console.log(facets)
        setFacets(facets);
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 3
        },
        desktop: {
            breakpoint: {max: 3000, min: 1200},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1200, min: 992},
            items: 2
        },
        mobile: {
            breakpoint: {max: 992, min: 0},
            items: 1
        }
    }


    return (
        <Container className={'page coaches-page mb-5'}>
            <h1 className={"primary-color text-center mt-5"}>Our Coaches</h1>
            <p className={"text-center pb-5"}>From the best-of-the-best, select your sherpa.</p>


            <Nav variant="tabs" defaultActiveKey="/home">
                <Tabs defaultActiveKey="first">
                    <Tab eventKey="first" title="Our Coaches">
                        <InstantSearch searchClient={searchClient} indexName={AlgoliaConfig.usersIndexName}>
                            <AlgoliaCoachesInner facets={facets}/>
                        </InstantSearch>
                    </Tab>
                    <Tab eventKey="second" title="Upcoming Coaches">
                        <div className="upcoming-coaches-main ">
                            <div className="pt-5">
                                <h3 className={"text-center"}>Upcoming Coaches</h3>

                                {/* <div className="join-coaches-waitlist-btn mt-5">
                                    <Button className={"FPS-primary-button"}>Join Coach's Waitlist</Button>
                                </div>*/}

                                <div className="upcoming-coaches-form dashboard-box-outline mt-5">
                                    <div className="p-4">
                                        <h4 className={"primary-color text-center pb-4"}>Join Coach's Waitlist</h4>
                                        <Form>
                                            <Form.Group className="mb-4" controlId="formBasicPassword">
                                                <Form.Control type="password" placeholder="Enter Coach's Name"/>
                                            </Form.Group>

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Enter Your Email Address"/>
                                            </Form.Group>

                                            <Form.Group className="mb-4 comment-text-field"
                                                        controlId="exampleForm.ControlTextarea1">
                                                <Form.Control as="textarea" placeholder={"Comments"} rows={3}/>
                                            </Form.Group>
                                            <Button className={"FPS-primary-button"} variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>

                                </div>
                            </div>

                            <div className={"upcoming-coach-cards-main mt-5 pb-5"}>

                                {
                                    DataConfig.comingSoonCoaches.map(coach => <ComingSoonCoachCard
                                        key={coach.email} {...coach}/>)
                                }
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Nav>


        </Container>
    );
}

function AlgoliaCoachesInner(props: { facets: Facet[] }) {
    const {gameFilter} = useParams();
    const {allGames} = useGames();
    const gameRefinementToggle = useToggleRefinement({
        attribute: "facets.Game",
        on: gameFilter,
    });
    const [doneInitialRefinements, setDoneInitialRefinements] = useState(false);

    useEffect(() => {
        if (doneInitialRefinements || _.isEmpty(props.facets)) return;
        if (gameFilter) gameRefinementToggle.refine();
        setDoneInitialRefinements(true);
    }, [props.facets, gameFilter, gameRefinementToggle])

    return <div>
        <Row className={"mt-5 pb-5"}>
            <Col lg={2}>
                <Configure hitsPerPage={40} facetFilters={["role:coach", "isHidden:false"]}/>
                <SearchBox/>
                <div className={"mt-4 side-panel"}>
                    <div className="p-1">
                        {props.facets.map(facet => <FacetRefinementList key={facet.id} facet={facet}/>)}
                    </div>
                </div>
            </Col>
            <Col lg={9} className={"pt-4 pt-lg-0"}>
                <div className={"result-pagination-bar pb-2"}>
                    <Pagination/> <HitsPerPage items={[
                    {default: false, value: 10, label: "10"},
                    {default: true, value: 30, label: "30"},
                    {default: false, value: 50, label: "50"},
                    {default: false, value: 100, label: "100"},
                ]}></HitsPerPage>
                </div>
                <hr className={""}/>
                <Hits hitComponent={(hit) => {
                    const user = (hit.hit as any) as AlgoliaUserSchema;
                    const gameData = GameUtils.getGameDataById(allGames, user.coachingGame);
                    return <CoachHit hit={hit.hit} sendEvent={hit.sendEvent} gameData={gameData}/>;
                }}/>
            </Col>
        </Row>
    </div>;
}
