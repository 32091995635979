import {User} from 'firebase/auth';
import {ADMIN_ROLE, COACH_ROLE, STUDENT_ROLE, userConverter} from "../schemas/user-schema";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../config/firebase-config";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {doc, FirestoreError} from "@firebase/firestore";
import {UserSchema} from "../schemas/user-schema";

export function useCurrentUser(): {
    userError: FirestoreError | undefined;
    userLoading: boolean;
    authUserError: Error | undefined;
    authUser: User | null;
    user: UserSchema | null;
    isStudent: boolean;
    authUserLoading: boolean;
    isAdmin: boolean;
    isCoach: boolean
} {
    const [authUser, authUserLoading, authUserError] = useAuthState(auth);
    const [user, userLoading, userError] = useDocumentData<UserSchema>(doc(db, `users/${authUser?.uid}`).withConverter(userConverter));
    const isAdmin = !!authUser && user?.role === ADMIN_ROLE;
    const isStudent = !!authUser && user?.role === STUDENT_ROLE;
    const isCoach = !!authUser && user?.role === COACH_ROLE;
    return {
        authUser: authUser ?? null,
        user: user ?? null,
        authUserLoading,
        authUserError,
        userLoading,
        userError,
        isAdmin,
        isStudent,
        isCoach,
    };
}
