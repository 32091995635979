import {Container} from "react-bootstrap"
import "./Community.scss"

export function Community() {

    return (
        <div className="py-5 community">
            <div className="community-bg-pattern"><img src="/assets/Homepage/logo-bg.png" width={500} alt=""/></div>
            <Container className="py-5">

                <h1 className="text-center">JOIN OUR <span className="primary-color">COMMUNITY</span>
                </h1>

                <div className="community-description pb-5">Share your experience, progress, and clips with the rest of
                    our community.
                </div>

                <div className="social-links-main py-5">
                    <div><a target={"_blank"} href="http://www.twitter.com/fpslounge_"><img
                        src="/assets/social-icons/twitter.png"
                        width={50} alt=""/></a></div>
                    <div><a target={"_blank"} href="https://discord.gg/fpslounge"><img
                        src="/assets/social-icons/discord.png" width={50}
                        alt=""/></a></div>
                    <div><a target={"_blank"} href="http://www.instagram.com/fpslounge"><img
                        src="/assets/social-icons/ig.png" width={50}
                        alt=""/></a></div>
                    <div><a target={"_blank"} href="https://www.tiktok.com/@fps_lounge"><img
                        src="/assets/social-icons/tiktok.png"
                        width={45} alt=""/></a></div>
                    <div><a target={"_blank"} href="https://www.twitch.tv/fps_lounge"><img
                        src="/assets/social-icons/twitch.png"
                        width={50} alt=""/></a></div>
                </div>
            </Container>

        </div>
    )
}
