import {StorageReference} from "firebase/storage";
import {useMemo} from "react";
import {FirebaseUtils} from "../utils/firebase-utils";

export function usePublicUrl(storageRef: StorageReference | null | undefined): string | null {
    return useMemo(() => {
        if (!storageRef) return null;
        return FirebaseUtils.storageRefToPublicPath(storageRef);
    }, [storageRef]);
}
