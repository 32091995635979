import {deleteToken, MessagePayload} from 'firebase/messaging';
import React, {FC, PropsWithChildren, useEffect, useState} from 'react'
import {getMessagingToken, messaging, onMessageListener} from "../../config/firebase-config";
import {Toast} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import './FirebaseMessagingWrapper.scss';
import {arrayUnion, updateDoc} from "firebase/firestore";
import {UserSchema} from "../../schemas/user-schema";
import useSound from "use-sound";

export const FirebaseMessagingWrapper: FC<PropsWithChildren> = function ({children}) {
    const {user} = useCurrentUser();
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [play] = useSound(require('../../assets/sounds/notification.wav'), {
        volume: 0.5
    })

    async function writeTokenToUser(tokenInfo: { userData: UserSchema, token: string } | null) {
        if (!tokenInfo) return;
        await updateDoc(tokenInfo.userData.snapshot.ref, {
            'messagingTokens': arrayUnion(tokenInfo.token),
        });
    }

    useEffect(() => {
        if (!user) return;
        getMessagingToken().then((token) => {
            const tokenInfo = (user && token) ? {userData: user, token: token} : null;
            writeTokenToUser(tokenInfo).then();
            onMessageListener().then((payload: MessagePayload) => {
                console.log(payload);
                if (!payload.notification) return;
                setShow(true);
                setNotification({
                    title: payload.notification.title ?? 'No title',
                    body: payload.notification.body ?? 'No body'
                })
                play();
            }).catch(err => console.log('failed: ', err));
        });
        return () => {
            console.log('Deleted messaging tokenInfo');
            deleteToken(messaging).then();
        };
    }, [user?.uid])

    return (
        <div className={'firebase-messaging-wrapper'}>
            <Toast bg={'dark'} className={'messaging-notification'} onClose={() => setShow(false)} show={show} autohide
                   animation>
                <Toast.Header className={'notification-header-container'}>
                    <div className={'notification-header'}>
                        <strong className="mr-auto">{notification.title}</strong>
                        <small>just now</small>
                    </div>
                </Toast.Header>
                <Toast.Body className={'text-white'}>{notification.body}</Toast.Body>
            </Toast>
            {children}
        </div>
    )
}
