import {Container} from "react-bootstrap";
import "./PrivacyPolicyPage.scss"

export function PrivacyPolicyPage() {

    return (
        <div className="page privacy-policy">
            <Container className={"py-5"}>
                <h1 className="text-center primary-color pb-5">PRIVACY POLICY FOR FPS LOUNGE™</h1>

                <div className="dashboard-box-outline">
                    <div className="terms-condition-text-main">
                        <div className="p-4">
                            <p>FPS Lounge, Inc. (“Company” “we” or “us” or “our”) respects the privacy of its users
                                (“user”
                                or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard
                                your
                                information when you visit the FPS Lounge™ website and mobile application (individually
                                and
                                collectively, the “Service”). Please read this Privacy Policy carefully. IF YOU DO NOT
                                AGREE
                                WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.
                            </p>
                            <p>We reserve the right to make changes to this Privacy Policy at any time and for any
                                reason.
                                We will alert you about any changes by updating the “Last updated” date of this Privacy
                                Policy. You are encouraged to periodically review this Privacy Policy to stay informed
                                of
                                updates. You will be deemed to have been made aware of, will be subject to, and will be
                                deemed to have accepted the changes in any revised Privacy Policy by your continued use
                                of
                                the Service after the date such revised Privacy Policy is posted.</p>

                            <h4 className={"pt-4 pb-4 secondary-color"}>Definitions</h4>

                            <p>The following definitions shall have the same meaning regardless of whether they appear
                                in singular or in plural.</p>
                            <p>“Account” means a unique account created for you to access our Service or parts of our
                                Service.</p>
                            <p>“Affiliate” means an entity that controls, is controlled by or is under common control
                                with a party, where "control" means ownership of 50% or more of the shares, equity
                                interest or other securities entitled to vote for election of directors or other
                                managing authority.</p>
                            <p>“Application” or “App” means the software program provided by the Company downloaded by
                                you on any electronic device.</p>
                            <p>“Business,” for the purpose of the California Consumer Privacy Act (“CCPA”), refers to
                                the Company as the legal entity that collects Consumers' personal information and has
                                the meaning set forth in CCPA § 1798.140(c).</p>
                            <p>“Consumer,” for the purpose of the CCPA, means a natural person who is a California
                                resident and has the meaning set forth in CCPA § 1798.140(g).</p>
                            <p>“Cookies” are small files that are placed on your computer, mobile device or any other
                                device by a website, containing the details of your browsing history on that website
                                among its many uses.</p>
                            <p>“Device” means any device that can access the Service such as a computer, a cellphone or
                                a digital tablet.</p>
                            <p>“Do Not Track” (“DNT”) is a concept that has been promoted by US regulatory authorities,
                                in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to
                                develop and implement a mechanism for allowing internet users to control the tracking of
                                their online activities across websites.</p>
                            <p>“Personal Information” is any information that relates to an identified or identifiable
                                individual. For the purposes of the CCPA, Personal Information means any information
                                that identifies, relates to, describes or is capable of being associated with, or could
                                reasonably be linked, directly or indirectly, with you and has the meaning set forth in
                                CCPA § 1798.140(o).
                            </p>
                            <p>“Sale,” for the purpose of the CCPA means selling, renting, releasing, disclosing,
                                disseminating, making available, transferring, or otherwise communicating orally, in
                                writing, or by electronic or other means, a Consumer’s Personal Information to another
                                business or a third party for monetary or other valuable consideration and has the
                                meaning set forth in CCPA § 1798.140(t).</p>
                            <p>“Service” refers to the Application and Website, individually and collectively.</p>
                            <p>“Service Provider” means any natural or legal person who processes the data on behalf of
                                the Company. It refers to third-party companies and individuals employed by the Company
                                to facilitate the Service, to provide the Service on behalf of the Company, to perform
                                services related to the Service or to assist the Company in analyzing how the Service is
                                used.</p>
                            <p>“Third-party Social Media Service” refers to any website or any social network website
                                through which a User can log in or create an account to use the Service.</p>
                            <p>“Usage Data” refers to data collected automatically, either generated by the use of the
                                Service or from the Service infrastructure itself (for example, the duration of a page
                                visit).</p>
                            <p>“Website” refers to the FPS Lounge website, accessible at www.fpslounge.com.</p>
                            <p>“You” and “you” means the individual accessing or using the Service, or the company, or
                                other legal entity on behalf of which such individual is accessing or using the Service,
                                as applicable.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>COLLECTION OF YOUR INFORMATION</h4>
                            <p>We may collect information about you in a variety of ways. The information we may collect
                                via the Service depends on the content and materials you use, and includes:</p>

                            <h5 className={"secondary-color"}>Personal Information </h5>
                            <p>Demographic and other personally identifiable information (such as your name and email
                                address) that you voluntarily give to us when choosing to participate in various
                                activities related to the Service, such as making a purchase, registering an account,
                                sending feedback or responding to surveys. If you choose to share data about yourself
                                via your profile or other interactive areas of the Service, please be advised that all
                                data you disclose in these areas is public and your data will be accessible to anyone
                                who accesses the Service.</p>

                            <h5 className={"secondary-color"}>Health Insurance Portability & Accountability Act
                                (“HIPAA”) Data</h5>
                            <p>Some of the information collected by FPS Lounge in the course of your use of the Service
                                may include “protected health information” (“PHI”) as defined by HIPAA. FPS Lounge acts
                                as a “Business Associate” as defined by HIPAA. When it receives personally identifiable
                                information about you from a healthcare provider, such information is PHI and FPS
                                Lounge’s use of such PHI is governed by HIPAA. Data that you provide to FPS Lounge
                                directly, e.g., by creating an account, is not considered PHI and FPS Lounge’ use such
                                data is not governed by HIPAA. </p>
                            <p>HIPAA provides protections for the security and privacy of your PHI. FPS Lounge may only
                                use and disclose PHI as permitted by your healthcare provider or as authorized by
                                you. </p>

                            <h5 className={"secondary-color"}>Derivative Data</h5>
                            <p>Information our servers automatically collect when you access the Service, such as your
                                native actions that are integral to the Service, as well as other interactions with the
                                Service via server log files. </p>

                            <h5 className={"secondary-color"}>Financial Data</h5>
                            <p>Financial information, such as data related to your payment method (e.g. valid credit
                                card number, card brand, expiration date) that we may collect when you purchase, order,
                                or request information about our goods or services from the Service.</p>

                            <h5 className={"secondary-color"}>Data from Social Networks</h5>
                            <p>User information from social networking sites, such as Facebook, Instagram, Discord, Tik
                                Tok or Twitter, including your name, your social network username, location, gender,
                                birth date, email address, profile picture, and public data for contacts, if you connect
                                your account to such social networks. This information may also include the contact
                                information of anyone you invite to use and/or join the Service. </p>
                            <p>More specifically, but without limitation, we provide you with the option to register or
                                access the Service using social media account details, like your Facebook, Twitter or
                                other social media account. We may collect information regarding your geo-location,
                                mobile device, push notifications, and Facebook permissions when you use our apps.</p>

                            <p>We may also collect the following information:</p>
                            <ul>
                                <li>Geo-Location Information. We may request access or permission to and track
                                    location-based information from your mobile device, either continuously or while you
                                    are using our mobile application, to provide location-based services. If you wish to
                                    change our access or permissions, you may do so in your device’s settings.
                                </li>
                                <li>Mobile Device Access. We may request access or permission to certain features from
                                    your mobile device, including your mobile device’s Universally Unique Identifier
                                    (UUID), storage, reminders, notifications, social media accounts and other features.
                                    If you wish to change our access or permissions, you may do so in your device’s
                                    settings.
                                </li>
                                <li>Mobile Device Data. We may automatically collect device information (such as your
                                    mobile device ID, model and manufacturer), operating system, version information and
                                    IP address.
                                </li>
                                <li>Push Notifications. We may ask to send you push notifications regarding your account
                                    or the mobile application. If you wish to opt-out from receiving these types of
                                    communications, you may turn them off in your device’s settings.
                                </li>
                                <li>Facebook, Tik Tok, Discord Instagram & Twitter Permissions. If you give us
                                    permission via the respective app, we will access your Facebook, Tik Tok, Discord,
                                    Instagram and Twitter basic account information, including your name, email, gender,
                                    birthday, current city, and profile picture URL, as well as other information that
                                    you choose to make public. We may also request access to other permissions related
                                    to your account, such as friends, check-ins, and likes, and you may choose to grant
                                    or deny us access to each individual permission. For more information regarding
                                    permissions, refer to the Facebook Permissions Reference page, the <a
                                        href="https://privacycenter.instagram.com/policy?__coig_consent=1https://privacycenter.instagram.com/policy?__coig_consent=1https://privacycenter.instagram.com/policy?__coig_consent=1https://privacycenter.instagram.com/policy?__coig_consent=1https://privacycenter.instagram.com/policy?__coig_consent=1">Instagram
                                        Privacy Policy</a>&nbsp;
                                    and the <a href="https://discord.com/privacy">Discord Privacy Policy</a>,&nbsp;<a
                                        href="https://www.tiktok.com/legal/privacy-policy-us?lang=en">Tik Tok Privacy
                                        Policy</a> and <a href="https://twitter.com/en/privacy">Twitter Privacy
                                        Policy</a>.
                                </li>
                            </ul>

                            <h5 className={"secondary-color"}>Data from Social Networks</h5>
                            <p>We may collect limited data from public databases, marketing partners, social media
                                platforms, and other outside sources. </p>
                            <p>We may obtain information about you from other sources, such as public databases, joint
                                marketing partners, social media platforms (such as Facebook), as well as from other
                                third parties. Examples of the information we receive from other sources include: social
                                media profile information (your name, gender, birthday, email, current city, state and
                                country, user identification numbers for your contacts, profile picture URL and any
                                other information that you choose to make public); marketing leads and search results
                                and links, including paid listings (such as sponsored links).</p>

                            <h5 className={"secondary-color"}>Geo-Location Information </h5>
                            <p>We may request access or permission to and track location-based information from your
                                mobile device, either continuously or while you are using the Service, to provide
                                location-based services and for other purposes. If you wish to change our access or
                                permissions, you may do so in your device’s settings.</p>

                            <h5 className={"secondary-color"}>Push Notifications </h5>
                            <p>We may request to send you push notifications regarding your account or the Service. If
                                you wish to opt-out from receiving these types of communications, you may turn them off
                                in your device’s settings.</p>

                            <h5 className={"secondary-color"}>Third-Party Data </h5>
                            <p>Information from third parties, such as personal information or network friends, if you
                                connect your account to the third party and grant the Service permission to access this
                                information.</p>

                            <h5 className={"secondary-color"}>Data From Contests, Giveaways, and Surveys </h5>
                            <p>Personal and other information you may provide when entering sweepstakes, games,
                                tournaments, contests or giveaways and/or responding to surveys.</p>


                            <h4 className={"pt-5 pb-4 secondary-color"}>USE OF YOUR INFORMATION</h4>
                            <p>Having accurate information about you permits us to provide you with a smooth, efficient,
                                and customized experience. Specifically, we may use information collected about you via
                                the Service to:</p>

                            <ol type={"1"}>
                                <li>Administer sweepstakes, promotions, games and contests.</li>
                                <li>Increase the efficiency and operation of the Service.</li>
                                <li>Compile anonymous statistical data and analysis for use internally or with third
                                    parties.
                                </li>
                                <li>Create and manage your account.</li>
                                <li>Deliver targeted advertising, coupons, newsletters, and other information regarding
                                    promotions and the Service to you.
                                </li>
                                <li>Email you regarding your account or order.</li>
                                <li>Enable user-to-user communications.</li>
                                <li>Fulfill and manage purchases, orders, payments, and other transactions related to
                                    the Service.
                                </li>
                                <li>Generate a personal profile about you to make future visits to the Service more
                                    personalized.
                                </li>
                                <li>Respond to legal process.</li>
                                <li>Monitor and analyze usage and trends to improve your experience with the Service.
                                </li>
                                <li>Notify you of updates to the Service</li>
                                <li>Offer new products, services, mobile applications, and/or recommendations to you.
                                </li>
                                <li>Perform other business activities as needed.</li>
                                <li>Process payments and refunds.</li>
                                <li>Request feedback and contact you about your use of the Service.</li>
                                <li>Resolve disputes and troubleshoot problems.</li>
                                <li>Respond to product and customer service requests.</li>
                                <li>Send you marketing materials.</li>
                                <li>Solicit support for the Service.</li>
                            </ol>

                            <h4 className={"pt-5 pb-4 secondary-color"}>DISCLOSURE OF YOUR INFORMATION</h4>
                            <p>We may share information we have collected about you in certain situations. Your
                                information may be disclosed as follows: </p>

                            <h5 className={"secondary-color"}>By Law or to Protect Rights </h5>
                            <p>If we believe the release of information about you is necessary to respond to legal
                                process, to investigate or remedy potential violations of our policies, or to protect
                                the rights, property, and safety of others, we may share your information as permitted
                                or required by any applicable law, rule, or regulation. This includes exchanging
                                information with other entities for fraud protection and credit risk reduction.</p>

                            <h5 className={"secondary-color"}>Third-Party Service Providers </h5>
                            <p>We may share your information with third parties that perform services for us or on our
                                behalf, including payment processing, data analysis, email delivery, hosting services,
                                customer service, and marketing assistance. </p>

                            <h5 className={"secondary-color"}>Marketing Communications</h5>
                            <p>With your consent, or with an opportunity for you to withdraw consent, we may share your
                                information with third parties for marketing purposes, as permitted by law.</p>

                            <h5 className={"secondary-color"}>Third-Party Advertisers </h5>
                            <p>We may use third-party advertising companies to serve ads when you visit the Service.
                                These companies may use information about your visits to the Service and other websites
                                that are contained in web cookies in order to provide advertisements about goods and
                                services of interest to you. </p>

                            <h5 className={"secondary-color"}>Affiliates </h5>
                            <p>We may share your information with our affiliates, in which case we will require those
                                affiliates to honor this Privacy Policy. Affiliates include any subsidiaries, business
                                partners or other companies that we control or that are under common control with
                                us.</p>

                            <h5 className={"secondary-color"}>Business Partners </h5>
                            <p>We may share your information with our business partners to offer you certain products,
                                services or promotions.</p>

                            <h5 className={"secondary-color"}>Social Media Contacts </h5>
                            <p>If you connect to the Service through a social network, your contacts on the social
                                network may see your name, profile photo, and descriptions of your activity.</p>

                            <h5 className={"secondary-color"}>Other Third Parties</h5>
                            <p>We may share your information with advertisers and investors for the purpose of
                                conducting general business analysis. We may also share your information with such third
                                parties for marketing purposes, as permitted by law.</p>

                            <h5 className={"secondary-color"}>Sale or Bankruptcy </h5>
                            <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are
                                acquired by another entity, we may transfer your information to the acquirer or
                                successor entity. If we go out of business or enter bankruptcy, your information would
                                be an asset transferred or acquired by a third party. You acknowledge that such
                                transfers may occur and that the transferee may decline honor commitments we made in
                                this Privacy Policy. </p>
                            <p>We are not responsible for the actions of third parties with whom you share personal or
                                sensitive data, and we have no authority to manage or control third-party solicitations.
                                If you no longer wish to receive correspondence, emails or other communications from
                                third parties, you are responsible for contacting the third party directly. </p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>TRACKING TECHNOLOGIES</h4>
                            <h5 className={"secondary-color"}>Cookies and Web Beacons</h5>
                            <p>We use cookies, web beacons, tracking pixels, and other tracking technologies on the
                                Service to help customize the Service and improve your experience. When you access the
                                Service, your personal information is not collected through the use of tracking
                                technology. Most browsers are set to accept cookies by default. You can remove or reject
                                cookies, but be aware that such action could affect the availability and functionality
                                of the Service. You may not decline web beacons. However, they can be rendered
                                ineffective by declining all cookies or by modifying your web browser’s settings to
                                notify you each time a cookie is tendered, permitting you to accept or decline cookies
                                on an individual basis.</p>

                            <h5 className={"secondary-color"}>Internet-Based Advertising</h5>
                            <p>Additionally, we may use third-party software to serve ads on the Service, implement
                                email marketing campaigns, and manage other interactive marketing initiatives,
                                including, without limitation, Google Ads, Meta Business Audiences, Twitter Audiences
                                and Tik Tok Audiences. This third-party software may use cookies or similar tracking
                                technology to help manage and optimize your online experience with us. For more
                                information about opting-out of interest-based ads, visit the <a
                                    href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\ZZ - Closed Files\Elation\optout.networkadvertising.org">Network
                                    Advertising Initiative Opt-Out Tool</a> or <a
                                    href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\ZZ - Closed Files\Elation\optout.aboutads.info">Digital
                                    Advertising Alliance Opt-Out Tool.</a></p>

                            <h5 className={"secondary-color"}>Website Analytics </h5>
                            <p>We may also partner with selected third-party vendors to allow tracking technologies and
                                remarketing services on the Service through the use of first party cookies and
                                third-party cookies, to, among other things, analyze and track users’ use of the
                                Service, determine the popularity of certain content, and better understand online
                                activity. By accessing the Service, you consent to the collection and use of your
                                information by these third-party vendors. You are encouraged to review their privacy
                                policy and contact them directly for responses to your questions. We do not transfer
                                personal information to these third-party vendors. However, if you do not want any
                                information to be collected and used by tracking technologies, you can install and/or
                                update your settings for one of the following: </p>

                            <div><a
                                href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\ZZ - Closed Files\Elation\optout.aboutads.info">Digital
                                Advertising Alliance Opt-Out Tool</a></div>
                            <div><a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Plugin </a>
                            </div>
                            <div><a
                                href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\ZZ - Closed Files\Elation\optout.networkadvertising.org">Network
                                Advertising Initiative Opt-Out Tool </a></div>
                            <div><a href="https://youradchoices.com/control">Your Ad Choices</a></div>

                            <p className={"pt-4"}>You should be aware that getting a new computer, installing a new
                                browser, upgrading an existing browser, or erasing or otherwise altering your browser’s
                                cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>THIRD-PARTY WEBSITES</h4>
                            <p>The Service may contain links to third-party websites and applications of interest,
                                including advertisements and external services, that are not affiliated with us. Once
                                you have used these links to leave the Service, any information you provide to these
                                third parties is not covered by this Privacy Policy, and we cannot guarantee the safety
                                and privacy of your information. Before visiting and providing any information to any
                                third-party websites, you should inform yourself of the privacy policies and practices
                                (if any) of the third party responsible for that website, and should take those steps
                                necessary to, in your discretion, protect the privacy of your information. We are not
                                responsible for the content or privacy and security practices and policies of any third
                                parties, including other sites, services or applications that may be linked to or from
                                the Service.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>SECURITY OF YOUR INFORMATION</h4>
                            <p>We use administrative, technical, and physical security measures to help protect your
                                personal information. While we have taken reasonable steps to secure the personal
                                information you provide to us, please be aware that despite our efforts, no security
                                measures are perfect or impenetrable, and no method of data transmission can be
                                guaranteed against any interception or other type of misuse. Any information disclosed
                                online is vulnerable to interception and misuse by unauthorized parties. We cannot
                                guarantee complete security if you provide personal information.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>POLICY FOR CHILDREN</h4>
                            <p>We do not knowingly solicit information from or market to children under the age of 13.
                                In the event we discover we have collected information from a person under the age of 13
                                in a manner inconsistent with the Children’s Online Privacy Protection Act’s
                                requirements, we will either delete the information or immediately seek the parent’s
                                consent for that collection. If you become aware of any data we have collected from
                                children under age 13, please contact us immediately at privacy@fpslounge.com so that we
                                can properly address the situation.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                            <p>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”)
                                feature or setting you can activate to signal your privacy preference not to have data
                                about your online browsing activities monitored and collected. No uniform technology
                                standard for recognizing and implementing DNT signals has been finalized. As such, we do
                                not currently respond to DNT browser signals or any other mechanism that automatically
                                communicates your choice not to be tracked online. If a standard for online tracking is
                                adopted that we must follow in the future, we will inform you about that practice in a
                                revised version of this Privacy Policy. </p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>OPTIONS REGARDING YOUR INFORMATION</h4>
                            <h5 className={"secondary-color"}>Account Information</h5>
                            <p>You may at any time review or change the information in your account or terminate your
                                account by contacting us using the contact information provided below.</p>
                            <p>Upon your request to terminate your account, we will deactivate or delete your account
                                and information from our active databases. However, some information may be retained in
                                our files to prevent fraud, troubleshoot problems, assist with any investigations,
                                enforce our Terms & Conditions and/or comply with legal requirements.</p>

                            <h5 className={"secondary-color"}>Emails and Communications</h5>
                            <p>If you no longer wish to receive correspondence, emails, or other communications from us,
                                you may opt-out by:</p>

                            <ul>
                                <li>Noting your preferences at the time you register your account with the Service</li>
                                <li>Contacting us using the contact information provided below</li>
                            </ul>

                            <p>If you no longer wish to receive correspondence, emails, or other communications from
                                third parties, you are responsible for contacting the third party directly. </p>


                            <h4 className={"pt-5 pb-4 secondary-color"}>CALIFORNIA PRIVACY RIGHTS</h4>
                            <h5 className={"secondary-color"}>California Consumer Privacy Act (“CCPA”)</h5>
                            <p>California residents have certain rights with respect to the processing and use of their
                                personal information, including the following rights:</p>

                            <p><span className="terms-condition-bold">The right to notice. </span>You must be properly
                                notified which categories of Personal Information are being collected and the purposes
                                for which the Personal Information is being used.</p>
                            <p><span
                                className="terms-condition-bold">The right to access / the right to request. </span>You
                                must be properly
                                notified which categories of Personal Information are being collected and the purposes
                                for which the Personal Information is being used.</p>
                            <p><span className="terms-condition-bold">The right to say no to the sale of Personal Information. </span>You
                                also have the right to ask the Company not to sell your Personal Information to third
                                parties. You can submit such a request by visiting our "Do Not Sell My Personal
                                Information" section or web page.</p>
                            <p><span
                                className="terms-condition-bold">The right to know about your Personal Information. </span>You
                                have the right to request and obtain from the Company information regarding the
                                disclosure of the following:</p>
                            <ul>
                                <li>The categories of Personal Information collected;</li>
                                <li>The sources from which the Personal Information was collected;</li>
                                <li>The business or commercial purpose for collecting or selling the Personal
                                    Information;
                                </li>
                                <li>Categories of third parties with whom we share Personal Information; and</li>
                                <li>The specific pieces of Personal Information we collected about you</li>
                            </ul>

                            <p><span className="terms-condition-bold">The right to delete Personal Information. </span>You
                                also have the right to request the deletion of your Personal Information that have been
                                collected in the past 12 months.</p>
                            <p><span className="terms-condition-bold">The right not to be discriminated against. </span>You
                                have the right not to be discriminated against for exercising any of your Consumer's
                                rights, including by:</p>
                            <ul>
                                <li>Denying goods or services to you;</li>
                                <li>Charging different prices or rates for goods or services, including the use of
                                    discounts or other benefits or imposing penalties;
                                </li>
                                <li>Providing a different level or quality of goods or services to you;</li>
                                <li>Suggesting that you will receive a different price or rate for goods or services or
                                    a different level or quality of goods or services.
                                </li>
                            </ul>

                            <h4 className={"pt-5 pb-4 secondary-color"}>INFORMATION COLLECTED</h4>
                            <table className={"mb-4"}>
                                <tr>
                                    <th className={"text-center"}>CATEGORY</th>
                                    <th className={"text-center"}>EXAMPLES</th>
                                    <th className={"text-center"}>COLLECTED</th>
                                </tr>
                                <tr>
                                    <td>Identifiers</td>
                                    <td>A real name, alias, postal address, unique personal identifier, online
                                        identifier, Internet Protocol address, email address, account name, Social
                                        Security number, driver's license number, passport number, or other similar
                                        identifiers
                                    </td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Personal information categories listed in the California Customer Records
                                        statute (Cal. Civ. Code § 1798.80(e))
                                    </td>
                                    <td>A name, signature, Social Security number, physical characteristics or
                                        description, address, telephone number, passport number, driver's license or
                                        state identification card number, insurance policy number, education,
                                        employment, employment history, bank account number, credit card number, debit
                                        card number, or any other financial information, medical information, or health
                                        insurance information. Some personal information included in this category may
                                        overlap with other categories
                                    </td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Protected classification characteristics under California or federal law</td>
                                    <td>Age (40 years or older), race, color, ancestry, national origin, citizenship,
                                        religion or creed, marital status, medical condition, physical or mental
                                        disability, sex (including gender, gender identity, gender expression, pregnancy
                                        or childbirth and related medical conditions), sexual orientation, veteran or
                                        military status, genetic information (including familial genetic information),
                                        medical records and other data covered by HIPAA
                                    </td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Biometric Information</td>
                                    <td>Genetic, physiological, behavioral, and biological characteristics, or activity
                                        patterns used to extract a template or other identifier or identifying
                                        information, such as, fingerprints, faceprints, and voiceprints, iris or retina
                                        scans, keystroke, gait, or other physical patterns, and sleep, health, or
                                        exercise data
                                    </td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Internet or other similar network activity</td>
                                    <td>Browsing history, search history, information on a consumer's interaction with a
                                        website, application, or advertisement
                                    </td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Geolocation data</td>
                                    <td>Physical location or movements</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Sensory data</td>
                                    <td>Audio, electronic, visual, thermal, olfactory, or similar information</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Professional or employment-related information</td>
                                    <td>Current or past job history or performance evaluations.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Non-public education information (per the Family Educational Rights and Privacy
                                        Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))
                                    </td>
                                    <td>Education records directly related to a student maintained by an educational
                                        institution or party acting on its behalf, such as grades, transcripts, class
                                        lists, student schedules, student identification codes, student financial
                                        information, or student disciplinary records.
                                    </td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>Inferences drawn from other personal information</td>
                                    <td>Profile reflecting a person's preferences, characteristics, psychological
                                        trends, predispositions, behavior, attitudes, intelligence, abilities, and
                                        aptitudes
                                    </td>
                                    <td>Yes</td>
                                </tr>
                            </table>

                            <p>Personal information does not include:</p>
                            <ul>
                                <li>Publicly available information from government records</li>
                                <li>De-identified or aggregated consumer information</li>
                                <li>Information that is exempt from the CCPA such as:</li>
                                <ul>
                                    <li>o health or medical information covered by the Health Insurance Portability and
                                        Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                        Information Act (CMIA) or clinical trial data;
                                    </li>
                                    <li>o personal information covered by certain sector-specific privacy laws,
                                        including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act
                                        (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's
                                        Privacy Protection Act of 1994.
                                    </li>
                                </ul>
                            </ul>

                            <p>We obtain the categories of personal information listed above from the following
                                categories of sources:</p>
                            <ul>
                                <li>Directly from users of the Service or their agents, such as when a person
                                    voluntarily submits information to us
                                </li>
                                <li>Indirectly from our clients or their agents, such as when we collect information by
                                    providing goods or services to them.
                                </li>
                                <li>Directly and indirectly from activity on or through the Service.</li>
                                <li>From third parties that interact with us in connection with the Service.</li>
                            </ul>


                            <h4 className={"pt-5 pb-4 secondary-color"}>EXERCISING YOUR CCPA RIGHTS</h4>
                            <p>In order to exercise any of your rights under the CCPA, and if you are a California
                                resident, you can email us ccpa@fpslounge.com or call us at ccpa@fpslounge.com or submit
                                the form available on this page "Do Not Sell My Personal Information" </p>
                            <p>The Company will disclose and deliver the required information free of charge within 45
                                days of receiving your verified request. The time period to provide the required
                                information may be extended once by an additional 45 days when reasonably necessary and
                                with prior notice.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>DO NOT SELL MY PERSONAL INFORMATION</h4>
                            <p>We do not sell personal information. However, the Service Providers we partner with (for
                                example, our advertising partners) may use technology on the Service that "sells"
                                personal information as defined by the CCPA.</p>
                            <p>If you wish to opt out of the use of your personal information for interest-based
                                advertising purposes and these potential sales as defined under CCPA, you may do so by
                                following the instructions below.</p>
                            <p>Please note that any opt out is specific to the browser you use. You may need to opt out
                                on every browser that you use.</p>

                            <h5 className={"secondary-color"}>Website</h5>
                            <p>You can opt out of receiving ads that are personalized as served by our Service Providers
                                by clicking on the links below:</p>

                            <div><a
                                href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\Elation\optout.aboutads.info">Digital
                                Advertising Alliance Opt-Out Tool</a></div>
                            <div><a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Plugin</a>
                            </div>
                            <div><a
                                href="C:\Users\HankFasthoff\Tresors\Fasthoff Law Firm PLLC\Clients\Elation\optout.networkadvertising.org">Network
                                Advertising Initiative Opt-Out Tool </a></div>
                            <div><a href="https://youradchoices.com/control">Your Ad Choices</a></div>

                            <p>The opt out will place a cookie on your computer that is unique to the browser you use to
                                opt out. If you change browsers or delete the cookies saved by your browser, you will
                                need to opt out again.</p>

                            <h5 className={"secondary-color"}>Mobile Devices</h5>
                            <p>Your mobile device may give you the ability to opt out of the use of information about
                                the apps you use in order to serve you ads that are targeted to your interests:</p>

                            <ul>
                                <li>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android
                                    devices
                                </li>
                                <li>"Limit Ad Tracking" on iOS devices</li>
                            </ul>

                            <p>You can also stop the collection of location information from your mobile device by
                                changing the preferences on your mobile device.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>"DO NOT TRACK" POLICY AS REQUIRED BY CALIFORNIA
                                ONLINE PRIVACY PROTECTION ACT (“CALOPPA”)</h4>
                            <p>Our Service does not respond to Do Not Track signals. However, some third party websites
                                keep track of you browsing activities. If you are visiting such websites, you can set
                                your preferences in your web browser to inform websites that you do not want to be
                                tracked. You can enable or disable DNT by visiting the preferences or settings page of
                                your web browser.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>CALIFORNIA BUSINESS AND PROFESSIONS CODE §
                                22581</h4>
                            <p>California residents under the age of 18 who are registered users of online sites,
                                services, or applications have a right under California Business and Professions Code §
                                22581 to remove, or request and obtain removal of, content or information they have
                                publicly posted. To remove content or information you have publicly posted, please send
                                a detailed description of the specific content or information you wish to have removed;
                                see How to Contact Us below. Please be aware that your request does not guarantee
                                complete or comprehensive removal of content or information posted online and that the
                                law may not permit or require removal in certain circumstances.</p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>EUROPEAN UNION GDPR RIGHTS</h4>
                            <p>Residents of a member state of the European Union entitled to the following:</p>

                            <ol type={"1"}>
                                <li>The right to access. You have the right to request copies of your personal data. We
                                    may charge you a small fee for this service.
                                </li>
                                <li>The right to rectification. You have the right to request that we correct any
                                    information you believe is inaccurate or incomplete.
                                </li>
                                <li>The right to erasure. You have the right to request that we erase your personal
                                    data, under certain conditions.
                                </li>
                                <li>The right to restrict processing. You have the right to request that we restrict the
                                    processing of your personal data, under certain conditions.
                                </li>
                                <li>The right to object to processing. You have the right to object to our processing of
                                    your personal data, under certain conditions.
                                </li>
                                <li>The right to data portability. You have the right to request that we transfer the
                                    data that we have collected to another organization, or directly to you, under
                                    certain conditions.
                                </li>
                            </ol>

                            <p>If you make a request, we have one month to respond to you. If you would like to exercise
                                any of these rights, please contact us at privacy@fpslounge.com and include your first
                                name, last name and residence address in your email to us. </p>

                            <h4 className={"pt-5 pb-4 secondary-color"}>HOW TO CONTACT US</h4>

                            <p>If you have a privacy concern, complaint, or a question for us, please contact us at:</p>
                            <p>FPS Lounge, Inc. <br/>
                                2600 Esperanza Xing, Unit 7302 <br/>
                                Austin, Texas 78758 <br/>
                                privacy@fpslounge.com or (for CCPA requests) ccpa@fpslounge.com
                            </p>

                            <div>Unless otherwise stated, the data is processed in the United States of America and at
                                any other location where other parties processing information are located.
                            </div>
                        </div>
                    </div>

                </div>
            </Container>
        </div>
    )
}