import React from "react";
import {Button, Col, Container, Nav, OverlayTrigger, Popover, Row, Tab} from "react-bootstrap";
import './CoachDashboardPage.scss';
import {CoachOverviewTab} from "./CoachOverviewTab";
import {CoachBookingsTab} from "./CoachBookingsTab";
import {CoachCalendarTab} from "./CoachCalendarTab";
import {CoachPackagesTab} from "./CoachPackagesTab";
import useCookie from 'react-use-cookie';
import {CoachEarningsTab} from "./CoachEarningsTab";
import {CoachVodTab} from "./CoachVodTab";
import {Bank2, Book, Box, Calendar, CashStack, Download, PlayCircle} from "react-bootstrap-icons";
import {useCurrentUser} from "../../hooks/use-current-user";
import {CoachWithdrawalsTab} from "./CoachWithdrawalsTab";
import {useWithdrawFunds} from "../../hooks/use-withdraw-funds";
import {BusyOverlay} from "../../components/BusyOverlay";
import {UserSchemaExt} from "../../schemas/user-schema";

export const CoachDashboardPage = () => {
    const [coachDashboardTabIndex, setCoachDashboardTabIndex] = useCookie('coachDashboardTabIndex', "first");
    const {user} = useCurrentUser();
    const {isBusyWithdrawFunds, withdrawFunds} = useWithdrawFunds();

    return (<BusyOverlay isBusy={isBusyWithdrawFunds}>
        <Container className={'page student-dashboard-page mb-5'}>
            <Row className={"dashboard-header pb-4"}>
                <Col lg={7}>
                    <h2 className={"primary-color dashboard-title pt-2"}>Coach Dashboard</h2>
                </Col>
                <Col lg={5}>
                    <div className="dashboard-header-row">
                        <div className="wallet-balance pt-2">
                            <div><span className="primary-color">Balance : {user?.balance ?? "-"} USD</span></div>
                        </div>
                        <OverlayTrigger
                            trigger={UserSchemaExt.canWithdraw(user) ? [] : ['hover', 'focus']}
                            placement="bottom"
                            overlay={(<Popover>
                                You must have at least 50 USD in your wallet before you can withdraw.
                            </Popover>)}
                        >
                            <div>
                                <Button disabled={!UserSchemaExt.canWithdraw(user)}
                                        className={"dashboard-primary-outline-btn"}
                                        onClick={withdrawFunds}>Withdraw</Button>
                            </div>
                        </OverlayTrigger>
                    </div>
                </Col>

            </Row>
            <Tab.Container defaultActiveKey={coachDashboardTabIndex}
                           onSelect={(tab) => setCoachDashboardTabIndex(tab ?? "first")}>
                <Row>
                    <Col lg={3} className={"pt-4 mb-4 mb-lg-0"}>
                        <Nav variant="pills" className="flex-column dashboard-sidebar">
                            <Nav.Item>
                                <Nav.Link eventKey="first"><Bank2/>&nbsp;&nbsp; Overview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"><Calendar/>&nbsp;&nbsp; My Calendar</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third"><Book/>&nbsp;&nbsp; Booking Requests</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="vod"><PlayCircle/>&nbsp;&nbsp; VOD Requests</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="earnings"><CashStack/>&nbsp;&nbsp; My Earnings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="withdrawals"><Download/>&nbsp;&nbsp; My Withdrawals</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth"><Box/>&nbsp;&nbsp; My Packages</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={9} className={"pt-4"}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <CoachOverviewTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <CoachCalendarTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <CoachBookingsTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="vod">
                                <CoachVodTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="earnings">
                                <CoachEarningsTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="withdrawals">
                                <CoachWithdrawalsTab/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <CoachPackagesTab/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    </BusyOverlay>)
}
