import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, Stack} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {EditStudentProfilePayload} from "../../schemas/game-schema";
import {useNavigate} from "react-router-dom";
import {FieldError} from "../../components/form/FieldError";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import './EditStudentProfilePage.scss';

export function EditStudentProfilePage() {
    const usersStoreActions = useStoreActions(state => state.users);
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const [isFormInit, setIsFormInit] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue
    } = useForm<EditStudentProfilePayload>();

    useEffect(() => {
        if (!user || isFormInit) return;
        setValue('gamerTag', user.gamerTag ?? '');
        setValue('discord', user.discord ?? '');
        setIsFormInit(true);
    }, [user])


    const onSubmit: SubmitHandler<EditStudentProfilePayload> = async (payload) => {
        console.log(payload)
        if (!user?.uid) return;
        const success = await usersStoreActions.editStudentProfile({
            ...payload,
            updateId: user.uid
        });
        if (!success) return;
        navigate(-1);
    };

    function onDiscard() {
        navigate(-1);
    }

    return (<Container className={'page edit-student-profile-page'}>
            <div className={"dashboard-box-outline"}>
                <div className="p-5">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Col>
                            <h1 className={"primary-color text-center"}>Edit My Profile</h1>
                            <p className={"text-center pb-5"}>This section will let you edit your profile.</p>
                            <Col>
                                <div className="discord-tag">

                                    <Form.Group className="mb-4">
                                        <Form.Label style={{textAlign: "left", width: "100%"}}>
                                            Discord Username
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Discord Username"
                                            {...register('discord', {
                                                required: "Field is required",
                                            })}
                                        />
                                        <FieldError message={errors.discord?.message}/>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label style={{textAlign: "left", width: "100%"}}>
                                            Game Tag
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Gamer Tag / Display Name"
                                            {...register('gamerTag', {
                                                required: "Field is required",
                                            })}
                                        />
                                        <FieldError message={errors.gamerTag?.message}/>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
                                <Button variant="primary" type="submit" className={"dashboard-primary-btn w-100"}>
                                    Save
                                </Button>
                                <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                                        onClick={() => onDiscard()}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Col>
                    </Form>
                </div>
            </div>
        </Container>

    );
}
