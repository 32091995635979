import {Card, Col, Row} from "react-bootstrap";
import React, {FC} from "react";
import {BookingSchema} from "../schemas/booking-schema";
import {BookingMode, BookingModeExt} from "../definitions/booking-mode";
import {BookingStatus} from "../definitions/booking-status";
import "./StudentBookingListItem.scss"
import {BusyOverlay} from "./BusyOverlay";
import {TimeUtils} from "../utils/time-utils";

export const StudentBookingListItem: FC<{ bookingData: BookingSchema }> = function (props) {
    function getStyleClass(): string {
        switch (props.bookingData.bookingStatus) {
            case BookingStatus.Pending:
                return "student-booking-list-item-pending";
            case BookingStatus.Accepted:
                return "student-booking-list-item-accept";
            case BookingStatus.Complete:
                return "student-booking-list-item-accept";
            case BookingStatus.Cancelled:
                return "student-booking-list-item-reject";
            case BookingStatus.Rejected:
                return "student-booking-list-item-reject";
        }
    }

    return (<BusyOverlay isBusy={false}>
        <div className={getStyleClass()} key={props.bookingData.id}>
            <Card className={'mb-4'}>
                <Card.Body>
                    <div className="p-4">
                        <Row>
                            <Col lg={6}>
                                <div>
                                    <div className="student-info-row">
                                        <div className={"dashboard-coach-img"}><img
                                            src="/assets/profile/dummy-profile-1.jpg"
                                            width={100} alt=""/></div>
                                        <div className={"px-5"}>
                                            <div className="student-name">{props.bookingData.coachPointer.name}</div>
                                            <div
                                                className="booking-mode pt-2 pb-4">{BookingMode[props.bookingData.bookingMode]} - {BookingStatus[props.bookingData.bookingStatus]}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className={"my-bookings-col pt-4 pt-lg-0"}>
                                <div className="my-bookings-row py-1">
                                    <div className="my-bookings-bold">Mode:</div>
                                    {BookingModeExt.toDisplayLabel(props.bookingData.bookingMode)}
                                </div>
                                <div className="my-bookings-row py-1">
                                    <div className="my-bookings-bold">Placed:</div>
                                    {TimeUtils.timestampToLocaleString(props.bookingData.created)}
                                </div>
                                {props.bookingData.booking &&
                                    <div className={"my-bookings-time mb-4"}>
                                        <div className="my-bookings-row py-1">
                                            <div className="my-bookings-bold">Start:</div>
                                            {TimeUtils.dateToLocaleString(props.bookingData.booking.start)}
                                        </div>
                                        <div className="my-bookings-row py-1">
                                            <div className="my-bookings-bold">End:</div>
                                            {TimeUtils.dateToLocaleString(props.bookingData.booking.end)}
                                        </div>
                                        {props.bookingData.proceedComment &&
                                            <div className="my-bookings-row py-1">
                                                <div className="my-bookings-bold">Reason:</div>
                                                {props.bookingData.proceedComment}</div>}
                                    </div>}
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </BusyOverlay>);
};
