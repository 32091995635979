import {Button} from "react-bootstrap";
import React from "react";
import {BusyOverlay} from "../../components/BusyOverlay";
import useSound from "use-sound";

export function AdminLabTab() {
    const [play] = useSound(require('../../assets/sounds/notification.wav'),
        {volume: 0.5}
    );

    async function onRunLab() {
    }

    return <>
        <BusyOverlay isBusy={false}>
            <h3 className={"primary-color"}>Laboratory</h3>
            <p>This section is for devs to do experiments on new cool ideas!</p>
            <div className="py-3 add-new-game-btn">
                <Button className={"dashboard-secondary-btn"} variant={"primary"} onClick={() => onRunLab()}>Run Lab
                    Action 1</Button>
            </div>
            <Button className={"dashboard-secondary-btn"} variant={"primary"}
                    onClick={() => play()}>Play Notification Sound</Button>

        </BusyOverlay>
    </>;
}

