import './SlotAvailabilityView.scss';
import * as React from 'react';
import {DateRange, SlotAvailability, TimeUtils} from "../utils/time-utils";
import {Table} from "react-bootstrap";
import _ from "lodash";

type Props = {
    availabilityList: SlotAvailability[],
    selectedRange: DateRange | null,
    onClickSlot: (slot: SlotAvailability) => void,
};

export function SlotAvailabilityView(props: Props) {
    function buildAvailableRow(availability: SlotAvailability) {
        const isSelected = props.selectedRange && props.selectedRange[0] <= availability.slot[0] && props.selectedRange[1] >= availability.slot[1];
        return <tr key={JSON.stringify(availability.slot)}
                   onClick={() => {
                       if (!availability.isAvailable) return;
                       props.onClickSlot(availability);
                   }}
                   className={`${availability.isAvailable ? 'clickable' : ''} 
                               ${availability.isAvailable ? 'available' : 'booked'} 
                               ${isSelected ? 'selected' : ''}`}>
            <td>
                {TimeUtils.dateToLocaleWeekdayString(availability.slot[0])} {TimeUtils.dateToLocaleTimeString(availability.slot[0])}
                {' - '}
                {TimeUtils.dateToLocaleWeekdayString(availability.slot[1])} {TimeUtils.dateToLocaleTimeString(availability.slot[1])}
            </td>
            <td>
                {availability.isAvailable ? 'Available' : 'Booked'}
            </td>
        </tr>;
    }

    function buildBookedRow(availability: SlotAvailability) {
        return <tr key={JSON.stringify(availability.slot)} className={'booked'}></tr>;
    }

    return (
        <div className={'slot-availability-view'}>
            {_.isEmpty(props.availabilityList.filter(availability => availability.isAvailable)) &&
                <p className={'py-4'}>No available slots on this day.</p>}
            <Table bordered={true} border={2} className={'availability-table table-border-color-light'}>
                <tbody>
                {_(props.availabilityList).reject((object, i) => {
                    return i > 0 && props.availabilityList[i - 1].isAvailable === object.isAvailable && !object.isAvailable;
                }).map((availability) => {
                    if (!availability.isAvailable) return buildBookedRow(availability);
                    return buildAvailableRow(availability);
                }).value()}
                </tbody>
            </Table>
        </div>
    );
};
