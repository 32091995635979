import {Button, Table} from "react-bootstrap";
import React from "react";
import "./AdminAccessTab.scss"
import {useAdmins} from "../../hooks/use-admins";
import {UserSchema} from "../../schemas/user-schema";
import {useGames} from "../../hooks/use-games";
import {useStoreActions} from "../../models";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";
import {SpinnerPadded} from "../../components/SpinnerPadded";
import {useCurrentUser} from "../../hooks/use-current-user";

export function AdminAccessTab() {
    const {user} = useCurrentUser();
    const {allAdmins, adminsLoading} = useAdmins();
    const {allGames, gamesLoading} = useGames();
    const uiStoreActions = useStoreActions(actions => actions.ui);
    const usersStoreActions = useStoreActions(actions => actions.users);
    const {isBusy, setIsBusy} = useBusy();

    async function onRemoveAdmin(adminData: UserSchema): Promise<void> {
        const should = await uiStoreActions.addConfirmationAlert({message: `Are you sure you want to revoke admin access from ${adminData.displayName}?`})
        if (!should) return;
        setIsBusy(true);
        const response = await usersStoreActions.alterAdminRole({
            email: adminData.email,
            enable: false,
        });
        setIsBusy(false);
        await uiStoreActions.addMessageAlert({
            title: response.success ? 'Admin added successfully' : 'Failed to add admin',
            subtitle: response.success ? 'User admin access was changed successfully' : `Change failed with error: ${response.message}`,
        });
    }

    async function onAddAdmin(): Promise<void> {
        const emailToAdd = await uiStoreActions.addSingleInputAlert({
            title: "Enter New Admin Email",
            message: "Please enter the registered email of the account to make admin. " +
                "The Account MUST NOT be a coach account. Coach accounts are not eligible to be promoted to admins.",
            required: true,
            inputType: 'email',
        });
        if (!emailToAdd) return;
        setIsBusy(true);
        const response = await usersStoreActions.alterAdminRole({
            email: emailToAdd,
            enable: true,
        });
        setIsBusy(false);
        await uiStoreActions.addMessageAlert({
            title: response.success ? 'Admin removed' : 'Failed to remove admin',
            subtitle: response.success ? 'User admin access was changed successfully' : `Change failed with error: ${response.message}`,
        });
    }

    function buildAdminsTableRow(adminData: UserSchema) {
        return <tr className={"table-row-bg"} key={adminData.uid}>
            <td className={"name-td"}>{adminData.displayName}</td>
            <td className={"name-td"}>{adminData.email}</td>
            <td className={"name-td"}>
                {user?.uid !== adminData.uid &&
                    <Button onClick={() => onRemoveAdmin(adminData)} variant={"outline-danger"}>Remove Admin</Button>}
            </td>
        </tr>;
    }


    return <>
        <BusyOverlay isBusy={isBusy}>
            <div className="admin-coach-tab">
                <h3 className={"primary-color"}>All Admins</h3>
                <p>Manage all the admins on the platform in a single place.</p>
                <div className="my-3">
                    <Button className={"dashboard-secondary-btn"} onClick={() => onAddAdmin()}>Add New Admin</Button>
                </div>
                <div className={'mb-3'}>
                    <div className="dashboard-box-outline">
                        <div className="p-4">
                            {adminsLoading && <SpinnerPadded/>}
                            {adminsLoading || !user || <Table className={"add-new-admins-table"} striped={false}>
                                <thead>
                                <tr>
                                    <td>Admin Name</td>
                                    <td>Email</td>
                                    <td>Actions</td>
                                </tr>
                                </thead>
                                <tbody>
                                {adminsLoading || allAdmins?.map(buildAdminsTableRow)}
                                </tbody>
                            </Table>}
                        </div>
                    </div>
                </div>
            </div>
        </BusyOverlay>
    </>;
}
