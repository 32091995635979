import React, {FC, useEffect, useState} from "react";
import {Button, Container, Form, Stack} from "react-bootstrap";
import {userConverter, UserSchema} from "../../schemas/user-schema";
import "./CoachRegisterPage.scss"
import {useNavigate} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {doc} from "@firebase/firestore";
import {db} from "../../config/firebase-config";
import {useStoreActions} from "../../models";
import {FieldError} from "../../components/form/FieldError";
import {CoachRequestPayload} from "../../models/user-model";
import {useGames} from "../../hooks/use-games";
import {GameSchema} from "../../schemas/game-schema";
import _ from "lodash";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import Select from "react-select";
import {DataPointer} from "../../interfaces/data-pointer";
import {UserLanguage} from "../../interfaces/user-language";
import {PlusLg, Trash} from "react-bootstrap-icons";
import {v4 as uuid} from "uuid"
import {routes} from "../../App";
import {useCurrentUser} from "../../hooks/use-current-user";
import {DataConfig} from "../../config/data-config";

export const CoachRegisterPage: FC = () => {
    const navigate = useNavigate();
    const {user, authUser, userLoading} = useCurrentUser();
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        unregister,
        control,
        setValue
    } = useForm<CoachRequestPayload>();
    const [isFormInit, setIsFormInit] = useState(false);
    const {requestCoachAccount} = useStoreActions((actions) => actions.users);
    const [userData, loading, error] = useDocumentData<UserSchema>(doc(db, `users/${authUser?.uid}`).withConverter(userConverter));
    const [selectedPlatforms, setSelectedPlatforms] = useState<DataPointer[] | null>(null);
    const [selectedInputMethods, setSelectedInputMethods] = useState<DataPointer[] | null>(null);
    const [selectedLanguages, setSelectedLanguages] = useState<UserLanguage[] | null>(null);
    const {isBusy, setIsBusy} = useBusy();
    const uiStoreActions = useStoreActions(actions => actions.ui);
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control,
        name: "mainPoints",
        shouldUnregister: true
    });

    const {
        allGames,
        gamesLoading,
        gamesError,
    } = useGames()

    useEffect(() => {
        watch((formContent, change) => {
            console.log(formContent)
            if (change.name === 'coachingGame') {
                _.keys(formContent.filters).forEach(key => unregister(`filters.${key}`))
            }
        })
    }, [])

    const selectedGame: GameSchema | null = allGames?.find(game => watch('coachingGame') === game.id) ?? null;

    useEffect(() => {
        if (!user || isFormInit) return;
        setValue('gamerTag', user.name ?? '');
        setValue('discord', user.discord ?? '');
        setValue('permalink', user.permalink ?? '');
        setIsFormInit(true);
    }, [user])

    const onRequestSubmit = async (registerPayload: CoachRequestPayload) => {
        if (_.isEmpty(selectedLanguages)) {
            await uiStoreActions.addMessageAlert({
                title: 'Incomplete Information',
                subtitle: 'Please select at least one language',
            });
            return;
        }
        if (_.isEmpty(selectedPlatforms)) {
            await uiStoreActions.addMessageAlert({
                title: 'Incomplete Information',
                subtitle: 'Please select at least one platform',
            });
            return;
        }
        if (_.isEmpty(selectedInputMethods)) {
            await uiStoreActions.addMessageAlert({
                title: 'Incomplete Information',
                subtitle: 'Please select at least one input method',
            });
            return;
        }
        if (_.isEmpty(registerPayload.mainPoints)) {
            await uiStoreActions.addMessageAlert({
                title: 'Incomplete Information',
                subtitle: 'Please add at least one highlight points',
            });
            return;
        }
        setIsBusy(true);
        let result = await requestCoachAccount({
            ...registerPayload,
            languages: selectedLanguages?.map(language => language.id) ?? [],
            platforms: selectedPlatforms?.map(platform => platform.id) ?? [],
            inputMethods: selectedInputMethods?.map(inputMethod => inputMethod.id) ?? [],
        });
        console.log('Coach request result is:', result)
        setIsBusy(false);
    };
    const pendingRequest = !loading && userData && userData.coachRequested;
    const canRequest = !loading && userData && !userData.coachRequested;
    console.log(errors)
    return (
        <BusyOverlay isBusy={isBusy || userLoading}>
            <div className={'page coach-register-page'}>

                <div className="py-5 BecomeACoach">
                    <Container className="pb-5">

                        <h1 className="text-center primary-color pb-3">
                            Become a Coach
                        </h1>
                        <div className="become-coach-description text-center pb-5">
                            We're looking for world-class coaches, pros, and highly ranked players to help students
                            achieve
                            their video game dreams.
                        </div>

                        <div className="become-coach-outline mt-5">
                            {/*<div className="become-coach-img">
                                <img src="/assets/become-a-coach/cypher.png" alt=""/>
                            </div>*/}
                            <div className="become-coach-content">
                                <div className="become-coach-fields-main py-5">
                                    {canRequest && <Form onSubmit={handleSubmit(onRequestSubmit)}>
                                        <Form.Group className="mb-4">
                                            <Form.Select aria-label="Default select example"
                                                         {...register('coachingGame', {
                                                             required: "Field is required",
                                                             maxLength: 140,
                                                         })}>
                                                <option value={''}>Select The Game You Coach</option>
                                                {allGames?.map(game => <option key={game.id}
                                                                               value={game.id}>{game.name}</option>)}
                                            </Form.Select>
                                            <FieldError message={errors.coachingGame?.message}/>
                                        </Form.Group>
                                        {selectedGame?.filters.map(filter => <Form.Group key={filter.id}
                                                                                         className="mb-4">
                                            <Form.Select
                                                {...register(`filters.${filter.id}`, {
                                                    required: "Field is required",
                                                })}>
                                                <option value={''}>Select {filter.filterName}</option>
                                                {filter.filterOptions?.map(option => <option key={option.id}
                                                                                             value={option.id}>{option.optionName}</option>)}
                                            </Form.Select>
                                            <FieldError
                                                message={_.get(errors, `filters.${filter.id}.message`) as unknown as string}/>
                                        </Form.Group>)}
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Your Desired Profile URL/Handle
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Enter Profile URL/Handle"
                                                {...register('permalink', {
                                                    required: "Field is required",
                                                    maxLength: 40,
                                                    pattern: /^[a-zA-Z0-9]+$/
                                                })}
                                            />
                                            <FieldError message={errors.permalink?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Select Languages
                                            </Form.Label>
                                            <Select
                                                className={'react-select-dropdown'}
                                                classNamePrefix={"react-select-dropdown-options"}
                                                options={DataConfig.supportedLanguages}
                                                value={selectedLanguages}
                                                getOptionValue={option => option.id}
                                                onChange={(items) => setSelectedLanguages([...items])}
                                                isMulti={true}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Select Platforms
                                            </Form.Label>
                                            <Select
                                                className={'react-select-dropdown'}
                                                classNamePrefix={"react-select-dropdown-options"}
                                                options={DataConfig.supportedPlatforms}
                                                value={selectedPlatforms}
                                                getOptionValue={option => option.id}
                                                onChange={(items) => setSelectedPlatforms([...items])}
                                                isMulti={true}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Select Input Methods
                                            </Form.Label>
                                            <Select
                                                className={'react-select-dropdown'}
                                                classNamePrefix={"react-select-dropdown-options"}
                                                options={DataConfig.supportedInputMethods}
                                                value={selectedInputMethods}
                                                getOptionValue={option => option.id}
                                                onChange={(items) => setSelectedInputMethods([...items])}
                                                isMulti={true}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                K/D Ratio
                                            </Form.Label>
                                            <Form.Control
                                                type={'number'}
                                                placeholder="K/D Ratio"
                                                step={0.0000000000001}
                                                {...register('kdRatio', {})}
                                            />
                                            <FieldError message={errors.kdRatio?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Win Rate
                                            </Form.Label>
                                            <Form.Control
                                                type={'number'}
                                                placeholder="Win Rate"
                                                step={0.0000000000001}
                                                {...register('winRate', {})}
                                            />
                                            <FieldError message={errors.winRate?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Total Kills
                                            </Form.Label>
                                            <Form.Control
                                                type={'number'}
                                                placeholder="Total Kills"
                                                {...register('totalKills', {})}
                                            />
                                            <FieldError message={errors.totalKills?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Total Deaths
                                            </Form.Label>
                                            <Form.Control
                                                type={'number'}
                                                placeholder="Total Deaths"
                                                {...register('totalDeaths', {})}
                                            />
                                            <FieldError message={errors.totalDeaths?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Profile Bio
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                style={{height: '6em'}}
                                                placeholder="Your Profile Bio"
                                                {...register("bio", {
                                                    maxLength: 500,
                                                })}
                                            />
                                            <FieldError message={errors.bio?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                VOD Review Bio
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                style={{height: '6em'}}
                                                placeholder="Your VOD Review Bio"
                                                {...register("vodBio", {
                                                    maxLength: 500,
                                                })}
                                            />
                                            <FieldError message={errors.vodBio?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                Enter Up To 3 Highlight Points About Your Coaching
                                            </Form.Label>
                                            <Stack direction={"vertical"}>
                                                {fields.map((field, index) => (
                                                    <Stack key={`GameFilterOption-${index}`} direction={"horizontal"}>
                                                        <Form.Group key={field.id} className="w-100 me-2 my-1">
                                                            <Form.Control
                                                                placeholder={`Hightlight point #${index + 1}`}
                                                                {...register(`mainPoints.${index}.label`, {
                                                                    required: 'Field is required'
                                                                })}
                                                            />
                                                            <FieldError
                                                                message={errors.mainPoints?.[index]?.label?.message}
                                                            />
                                                        </Form.Group>
                                                        <Trash onClick={() => remove(index)}/>
                                                    </Stack>
                                                ))}
                                                <Button className={'mt-1 dashboard-secondary-btn'}
                                                        disabled={fields.length >= 3}
                                                        variant={"outline-primary"} onClick={
                                                    () => {
                                                        if (fields.length >= 3) return;
                                                        append({
                                                            id: uuid(),
                                                            label: '',
                                                        });
                                                    }
                                                }><PlusLg
                                                    size={20}/>Add Another Point</Button>
                                            </Stack>
                                            <FieldError message={errors.mainPoints?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>Gamer
                                                Tag</Form.Label>
                                            <Form.Control
                                                placeholder="Enter Gamer Tag / Display Name"
                                                {...register('gamerTag', {
                                                    required: "Field is required",
                                                })}
                                            />
                                            <FieldError message={errors.gamerTag?.message}/>
                                        </Form.Group>
                                        <div className="form-field-row">
                                        </div>
                                        <div className="form-field-row">
                                            <div className="ign me-1">
                                                <Form.Group className="mb-4">
                                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Youtube
                                                        Channel Link</Form.Label>
                                                    <Form.Control
                                                        placeholder="Youtube Channel Link"
                                                        {...register('youtube', {})}
                                                    />
                                                    <FieldError message={errors.youtube?.message}/>
                                                </Form.Group>
                                            </div>
                                            <div className="discord-tag">
                                                <Form.Group className="mb-4">
                                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Discord
                                                        Username</Form.Label>
                                                    <Form.Control
                                                        placeholder="Discord Username"
                                                        {...register('discord', {
                                                            required: "Field is required",
                                                        })}
                                                    />
                                                    <FieldError message={errors.discord?.message}/>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="form-field-row">
                                            <div className="twitter-username me-1">
                                                <Form.Group className="mb-4">
                                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Twitter
                                                        Link</Form.Label>
                                                    <Form.Control
                                                        placeholder="Twitter Link"
                                                        {...register('twitterUsername', {})}
                                                    />
                                                    <FieldError message={errors.twitterUsername?.message}/>
                                                </Form.Group>
                                            </div>
                                            <div className="twitch-username">
                                                <Form.Group className="mb-4">
                                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Twitch
                                                        Link</Form.Label>
                                                    <Form.Control
                                                        placeholder="Twitch Link"
                                                        {...register('twitchUsername', {})}
                                                    />
                                                    <FieldError message={errors.twitchUsername?.message}/>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="instagram-username">
                                            <Form.Group className="mb-4">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>Instagram
                                                    Link</Form.Label>
                                                <Form.Control
                                                    placeholder="Instagram Link"
                                                    {...register('instagram', {})}
                                                />
                                                <FieldError message={errors.instagram?.message}/>
                                            </Form.Group>
                                        </div>
                                        <div className="agree-for-condition">
                                            <div>
                                                <Form.Check
                                                    id={`agreeForConditions`}
                                                />
                                            </div>
                                            <div className={"mx-3 agree-for-condition-text mb-5"}>
                                                I agree to the FPS <a href={routes.termsCondition}>Terms
                                                of Service</a> and <a href={routes.privacyPolicy}>Privacy
                                                Policy</a>
                                            </div>
                                        </div>

                                        <div className="become-coach-btn-section">
                                            <div className={"become-coach-btn-main"}>
                                                <Button variant={"dark"} type={"submit"} className="become-coach-btn">Submit
                                                    Application</Button></div>
                                        </div>
                                    </Form>}
                                    {pendingRequest && <div className={"application-submit"}>
                                        <div>
                                            <h3 className={"primary-color pb-4"}>Your coach application is
                                                submitted.</h3>
                                            <p>We will get back to you via Email or Discord. Thank you for your
                                                patience.</p>
                                            <Button variant="primary" onClick={() => navigate('/student-dashboard')}
                                                    className={'w-100 mt-4 FPS-primary-button'}>
                                                Back to Dashboard
                                            </Button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </BusyOverlay>
    );
};
