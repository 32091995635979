import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, query} from "@firebase/firestore";
import {gameConverter, GameSchema} from "../schemas/game-schema";

export function useGames(): { allGames: GameSchema[] | undefined; gamesError: FirestoreError | undefined; gamesLoading: boolean } {
    const gameCollectionRef = collection(db, 'games').withConverter(gameConverter);
    const gamesQuery = query<GameSchema>(gameCollectionRef, orderBy('orderIndex', "desc"));
    const [allGames, gamesLoading, gamesError] = useCollectionData<GameSchema>(gamesQuery);
    return {
        allGames,
        gamesLoading,
        gamesError,
    };
}
