export enum GainType {
    Stripe = 0,
    PayPal = 1,
}

export abstract class GainTypeExt {
    public static toDisplayLabel(gainType?: GainType | null): string {
        switch (gainType) {
            case GainType.Stripe:
                return 'Stripe';
            case GainType.PayPal:
                return 'PayPal';
            default:
                return 'Unknown';
        }
    }
}
