import * as React from 'react';
import './OnlineIndicator.scss';

type Props = {
    isOnline: boolean
};

export function OnlineIndicator(props: Props) {
    return (<div className={`ring-container ${props.isOnline ? 'online' : 'offline'}`}>
        <div className="circle">
            <div className="ringring"></div>
        </div>
    </div>);
}
