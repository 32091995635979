import {thunk, Thunk} from "easy-peasy";
import {
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile
} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {auth, db, functions} from "../config/firebase-config";
import {CallableResponse} from "../definitions/callable-response";
import {
    AlterAdminRolePayload,
    LoginPayload,
    RegisterPayload,
    ResetPasswordPayload,
    UserSchema
} from "../schemas/user-schema";
import {Dict} from "../interfaces/dict";
import {doc, setDoc} from "@firebase/firestore";
import {collection, updateDoc} from "firebase/firestore";
import {
    EditCoachMiniProfilePayload,
    EditCoachProfilePayload,
    EditPackagesPayload,
    EditStudentProfilePayload
} from "../schemas/game-schema";
import {TwitchConfig} from "../config/twitch-config";
import _ from "lodash";
import {CommonUtils} from "../utils/common-utils";
import {DataPointer} from "../interfaces/data-pointer";

export interface CoachRequestPayload extends Dict {
    permalink: string;
    coachingGame: string;
    gamerTag: string;
    discord: string;
    youtube?: string;
    instagram?: string;
    twitterUsername?: string;
    twitchUsername?: string;
    vodBio: string;
    bio: string;
    languages: string[];
    platforms: string[];
    inputMethods: string[];
    mainPoints?: DataPointer[];
    kdRatio?: string;
    winRate?: string;
    totalKills?: string;
    totalDeaths?: string;
}

export interface UserModel {
    /* thunks */
    signUserWithGoogle: Thunk<UserModel>;
    signUserWithTwitch: Thunk<UserModel>;
    signUserEmailPassword: Thunk<UserModel, LoginPayload, any, {}, Promise<CallableResponse>>;
    resetPassword: Thunk<UserModel, ResetPasswordPayload, any, {}, Promise<boolean>>;
    createUser: Thunk<UserModel, RegisterPayload, any, {}, Promise<CallableResponse>>;
    alterAdminRole: Thunk<UserModel, AlterAdminRolePayload, any, {}, Promise<CallableResponse>>;
    requestCoachAccount: Thunk<UserModel, CoachRequestPayload>;
    signOut: Thunk<UserModel>;
    editPackages: Thunk<UserSchema, EditPackagesPayload, any, {}, Promise<boolean>>;
    editCoachProfile: Thunk<UserSchema, EditCoachProfilePayload, any, {}, Promise<boolean>>;
    editCoachMiniProfile: Thunk<UserSchema, EditCoachMiniProfilePayload, any, {}, Promise<boolean>>;
    editStudentProfile: Thunk<UserSchema, EditStudentProfilePayload, any, {}, Promise<boolean>>;
}

export const users: UserModel = {
    /* thunks */
    signUserWithGoogle: thunk(async (actions) => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({prompt: "select_account"});
        await signInWithPopup(auth, provider);
        return "SUCCESS";
    }),
    signUserWithTwitch: thunk(async (actions) => {
        const provider = new OAuthProvider(TwitchConfig.openIdProvider);
        const userCredential = await signInWithPopup(auth, provider);
        const additionalInformation = await getAdditionalUserInfo(userCredential);
        console.log('additionalInformation', additionalInformation);
        const displayName = `${additionalInformation?.profile?.['preferred_username']}` ?? 'Unknown';
        await updateProfile(userCredential.user, {displayName: displayName});
        await setDoc(doc(collection(db, 'users'), userCredential.user.uid), {
            'name': displayName,
        }, {merge: true});
        return "SUCCESS";
    }),
    editPackages: thunk(async (actions, payload) => {
        try {
            await updateDoc(doc(collection(db, 'users'), payload.updateId ?? ''), {
                chargingMode: payload.chargingMode,
                pricingPack: payload.pricingPack,
            });
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }),
    editCoachProfile: thunk(async (actions, payload) => {
        try {
            await updateDoc(doc(collection(db, 'users'), payload.updateId ?? ''), _.omit(CommonUtils.sanitizeData(payload), 'updateId'));
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }),
    editCoachMiniProfile: thunk(async (actions, payload) => {
        try {
            await updateDoc(doc(collection(db, 'users'), payload.updateId ?? ''), _.omit(CommonUtils.sanitizeData(payload), 'updateId'));
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }),
    editStudentProfile: thunk(async (actions, payload) => {
        try {
            await updateDoc(doc(collection(db, 'users'), payload.updateId ?? ''), _.omit(CommonUtils.sanitizeData(payload), 'updateId'));
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }),
    signUserEmailPassword: thunk(async (actions, loginPayload): Promise<CallableResponse> => {
        return signInWithEmailAndPassword(auth, loginPayload.email, loginPayload.password)
            .then(() => {
                return {
                    success: true,
                    message: 'Successfully signed in with email and password',
                };
            })
            .catch((error) => {
                console.error(error)
                return {
                    success: false,
                    message: `Authentication failed with error: ${error.message}`,
                };
            });
    }),
    resetPassword: thunk(async (actions, loginPayload) => {
        return sendPasswordResetEmail(auth, loginPayload.email)
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.error(error)
                return false;
            });
    }),
    createUser: thunk(async (actions, registerPayload): Promise<CallableResponse> => {
        if (registerPayload.password !== registerPayload.confirmPassword) return {
            success: false,
            message: `Passwords you entered do not match.`,
        };
        return createUserWithEmailAndPassword(
            auth,
            registerPayload.email,
            registerPayload.password
        )
            .then(async (userCredential) => {
                await updateProfile(userCredential.user, {displayName: registerPayload.name});
                await setDoc(doc(collection(db, 'users'), userCredential.user.uid), {
                    'name': registerPayload.name,
                    'fullName': registerPayload.fullName,
                    'discord': registerPayload.discord,
                }, {merge: true});
                return {
                    success: true,
                    message: `Successfully create your account`,
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    message: `Authentication failed with error: ${error.message}`,
                };
            });
    }),
    alterAdminRole: thunk(async (actions, alterAdminPayload): Promise<CallableResponse> => {
        try {
            const alterAdminRole = httpsCallable<AlterAdminRolePayload, CallableResponse>(functions, 'alterAdminRole');
            const response = await alterAdminRole(alterAdminPayload);
            console.log(response.data)
            return response.data;
        } catch (ex) {
            return {
                success: false,
                message: `Request failed with error: ${ex}`
            }
        }
    }),
    requestCoachAccount: thunk(async (actions, registerPayload) => {
        const requestCoachAccountRequest = httpsCallable<CoachRequestPayload, CallableResponse>(functions, 'requestCoachAccount');
        const response = await requestCoachAccountRequest(registerPayload);
        console.log(response.data)
        return response.data;
    }),
    signOut: thunk((actions) => {
        signOut(auth)
            .then(() => {
                console.log("Signed Out");
                return "SUCCESS";
            })
            .catch((error) => {
                return "FAILED";
            });
    }),
};
