import {jsPDF} from "jspdf";
import jsPDFInvoiceTemplate from "jspdf-invoice-template";
import {PdfInvoiceData} from "../interfaces/PdfInvoiceData";
import {GainSchema} from "../schemas/gain-schema";
import {GainTypeExt} from "../definitions/gain-type";
import {UserUtils} from "./user-utils";
import {toast} from "react-toastify";
import {EconomyConfig} from "../config/economy-config";
import {TimeUtils} from "./time-utils";
import _ from "lodash";

export abstract class PdfUtils {
    public static generateSamplePdf() {
        // Default export is a4 paper, portrait, using millimeters for units
        const doc = new jsPDF();

        doc.text("Hello world!", 10, 10);
        doc.table(1, 1, [{
            a: 'A value', b: 'B value', c: 'C Value'
        }], ['a', 'b', 'c'], {autoSize: true});
        doc.save("a4.pdf");
    }

    public static async generateInvoiceForGainData(gainData: GainSchema) {
        const userData = await UserUtils.getUserDataByUid(gainData.targetUserPointer.id);
        const logoAspect = 842 / 331;
        const logoDimensions = {
            height: 20,
            width: 20 * logoAspect,
        }
        const invoiceData: PdfInvoiceData = {
            outputType: "save",
            returnJsPDFDocObject: true,
            fileName: `invoice-${Date.now()}-${gainData.id}.pdf`,
            orientationLandscape: false,
            compress: true,
            logo: {
                src: require('../assets/images/logo.png'),
                ...logoDimensions,
                margin: {
                    top: 0, //negative or positive num, from the current position
                    left: 0 //negative or positive num, from the current position
                }
            },
            business: {
                name: "FPS Lounge, Inc.",
                address: "2600 Esperanza Xing,",
                phone: "Unit 7302, Austin, TX 78758.",
                email: "United States",
                email_1: "admin@fpslounge.com",
                website: "www.fpslounge.com",
            },
            contact: {
                label: "Invoice issued for:",
                name: userData?.fullName ?? userData?.displayName ?? '-',
                email: userData?.email ?? 'No Email',
                otherInfo: `ID: ${userData?.uid}`,
            },
            invoice: {
                label: `Invoice #: ${gainData.id}`,
                num: 19,
                invDate: `Payment Date: ${TimeUtils.timestampToLocaleString(gainData.created)}`,
                invGenDate: `Invoice Date: ${TimeUtils.dateToLocaleString(new Date())}`,
                headerBorder: true,
                tableBodyBorder: true,
                header: [
                    {
                        title: "#",
                        style: {
                            width: 10
                        }
                    },
                    {
                        title: "Title",
                        style: {
                            width: 30
                        }
                    },
                    {
                        title: "Description",
                        style: {
                            width: 80
                        }
                    },
                    {title: "Price"},
                    {title: "Quantity"},
                    {title: "Total"}
                ],
                table: [[
                    1,
                    "FPS Credit Purchase",
                    `FPS Credit Purchase via ${GainTypeExt.toDisplayLabel(gainData.gainType)}`,
                    `1 USD`,
                    gainData.creditAmount.toFixed(2),
                    `${gainData.creditAmount.toFixed(2)} USD`,
                ]],
                additionalRows: [
                    {
                        col1: 'Total:',
                        col2: `${gainData.totalAmount.toFixed(2)}`,
                        col3: 'USD',
                        style: {
                            fontSize: 14 //optional, default 12
                        }
                    },
                    {
                        col1: 'Transaction Fee:',
                        col2: `${_.round(EconomyConfig.houseTxFeePercentNormalized * 100, 2)}`,
                        col3: '%',
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: 'SubTotal:',
                        col2: `${gainData.creditAmount.toFixed(2)}`,
                        col3: 'USD',
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    }],
            },
            footer: {
                text: "The invoice is created on a computer and is valid without the signature and stamp.",
            },
            pageEnable: true,
            pageLabel: "Page ",
        };
        jsPDFInvoiceTemplate(invoiceData as any);
        toast('Invoice Downloaded')
    }
}
