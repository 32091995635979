import React, {FC} from 'react';
import {Button, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";

export const OnlineModePopup: FC<AlertRenderProps<string>> = (props) => {
    return (
        <div>
            <Stack style={{textAlign: "start"}} className={'px-3'}>
                <p>You are now online and available for instant bookings! You will automatically go offline if:</p>
                <ul style={{textAlign: "start"}}>
                    <li>You are idle for 2 hours</li>
                    <li>You log out</li>
                    <li>Enter a session</li>
                </ul>
                <Button variant={"outline-light"} onClick={() => props.cancel()}>Got it!</Button>
            </Stack>
        </div>
    );
};
