import React, {FC} from "react";
import {EarningSchema} from "../schemas/earning-schema";
import "./CoachEarningListItem.scss"
import {EarningTypeExt} from "../definitions/earning-type";
import {Col, Row} from "react-bootstrap";

export const CoachEarningListItem: FC<{ earningData: EarningSchema; hasActions: boolean; }> = function (props) {
    return (
        <div className={"coach-earning-list-item mb-4"} key={props.earningData.id}>
            <div className="dashboard-box-outline">
                <div className="p-4">
                    <Row>
                        <Col lg={6}>
                            <div className="coach-earning-row pb-4">
                                <div className="coach-earning-title">
                                    Earning Type
                                </div>
                                <div className="coach-earning-info"><span
                                    className="secondary-color">{EarningTypeExt.toDisplayLabel(props.earningData?.earningType)}</span>
                                </div>
                            </div>
                            <div className="coach-earning-row pb-4">
                                <div className="coach-earning-title">Created</div>
                                <div className="coach-earning-info">
                                    {props.earningData.created.toDate().toString()}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="coach-earning-row pb-4">
                                <div className="coach-earning-title">
                                    Platform Fee
                                </div>
                                <div className="coach-earning-info">
                                    {props.earningData.houseAmount} USD
                                </div>
                            </div>
                            <div className="coach-earning-row pb-4">
                                <div className="coach-earning-title">
                                    Net Earning Amount
                                </div>
                                <div className="coach-earning-info">
                                    {props.earningData.creditAmount} USD
                                </div>
                            </div>
                            <div className="coach-earning-row">
                                <div className="coach-earning-title">
                                    Total Earning Amount
                                </div>
                                <div className="coach-earning-info">
                                    {props.earningData.totalAmount} USD
                                </div>
                            </div>
                        </Col>
                    </Row>


                </div>
            </div>
        </div>);
};
