export enum VodType {
    Twitch = 0,
    YouTube = 1,
    GoogleDrive = 2,
    Other = 99,
}

export abstract class VodTypeExt {
    static get allVodTypes() {
        return [
            VodType.Twitch,
            VodType.YouTube,
            VodType.GoogleDrive,
            VodType.Other,
        ];
    }

    public static toDisplayLabel(mode: VodType): string {
        switch (mode) {
            case VodType.Twitch:
                return 'Twitch';
            case VodType.YouTube:
                return 'YouTube';
            case VodType.GoogleDrive:
                return 'Google Drive';
            case VodType.Other:
                return 'Other';
        }
    }
}
