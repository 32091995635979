import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {StudentBookingListItem} from "../../components/StudentBookingListItem";
import {useBookingsOfStudent} from "../../hooks/use-bookings";

export function StudentBookingsTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allBookings} = useBookingsOfStudent(user?.uid);

    return (<div>
        <h3 className={"primary-color"}>My Bookings</h3>
        <p>Below are all your current booked sessions.</p>
        {userLoading || allBookings?.map(booking => <StudentBookingListItem key={booking.id}
                                                                            bookingData={booking}/>)}
    </div>);
}
