import React, {FC} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import {VodTypeExt} from "../../definitions/vod-type";
import {VodSchema} from "../../schemas/vod-schema";
import {MdLink, MdNote, MdPerson, MdPlayArrow, MdTimer} from "react-icons/md";
import {TimeUtils} from "../../utils/time-utils";
import "./VodStudentDisplayPopup.scss"
import {VodReviewStatus} from "../../definitions/vod-review-status";

export const VodStudentDisplayPopup: FC<AlertRenderProps<void> & { vodData: VodSchema }> = (props) => {
    return (
        <div>
            <Row className={"pt-4 pb-2"}>
                <Col md={5} className={"text-start"}>
                    <a href={props.vodData.vodUrl} target={"_blank"}>
                        <img src="/assets/dashboard/vod-thumbnail.png" alt="" className={"pb-4 w-100"}/>
                    </a>
                </Col>
                <Col md={7}>
                    <div style={{textAlign: "start"}}>
                        <div className={"pb-3 primary-color vod-status"}>
                            {VodReviewStatus[props.vodData.vodReviewStatus]}
                        </div>
                        <div className="vod-created pb-3">Submitted
                            on {TimeUtils.timestampToLocaleString(props.vodData.created)}</div>

                        <div className="pb-3"><MdTimer
                            color={"#EE2337"}
                            size={25}/>&nbsp;&nbsp; {TimeUtils.hoursToHumanizedString(props.vodData.hours)}
                        </div>
                        {props.vodData.focus && <div className="vod-type pb-3">
                            <MdNote className={'me-3'} color={"#EE2337"} size={25}/>{props.vodData.focus}
                        </div>}
                        <div className="vod-type pb-3">
                            <MdPlayArrow className={'me-3'} color={"#EE2337"}
                                         size={25}/>{VodTypeExt.toDisplayLabel(props.vodData.vodType)}
                        </div>
                        <div className="vod-coach pb-3">
                            <MdPerson className={'me-3'} color={"#EE2337"} size={25}/>{props.vodData.coachPointer.name}
                        </div>
                        <div className="vod-link"><MdLink className={'me-3'} color={"#EE2337"} size={25}/>
                            <a target={"_blank"} href={props.vodData.vodUrl}>{props.vodData.vodUrl}</a>
                        </div>
                        <div className="vod-coach mt-3"><MdNote className={'me-3'} color={"#EE2337"} size={25}/>
                            {props.vodData.remarks}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="vod-description mb-3 text-start">
                <div className="p-2">
                    {props.vodData.description}
                </div>
            </div>
            <div className="cancel-btn mt-4">
                <Button variant={"outline-light"} onClick={() => props.cancel()}>Close</Button>
            </div>
        </div>);
};
