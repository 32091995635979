import {db} from "../config/firebase-config";
import {useDocumentData, useDocumentDataOnce} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError} from "@firebase/firestore";
import {vodConverter, VodSchema} from "../schemas/vod-schema";

export function useVod(vodId: string | undefined): { vod?: VodSchema; vodError?: FirestoreError | undefined; vodLoading: boolean } {
    const vodCollectionRef = collection(db, 'vods').withConverter(vodConverter);
    const [vod, vodLoading, vodError] = useDocumentData<VodSchema>(vodId ? doc(vodCollectionRef, vodId) : null);
    return {
        vod,
        vodError,
        vodLoading,
    };
}

export function useVodOnce(vodId: string | undefined): { vod?: VodSchema; vodError?: FirestoreError | undefined; vodLoading: boolean } {
    const vodCollectionRef = collection(db, 'vods').withConverter(vodConverter);
    const [vod, vodLoading, vodError] = useDocumentDataOnce<VodSchema>(vodId ? doc(vodCollectionRef, vodId) : null);
    return {
        vod,
        vodError,
        vodLoading,
    };
}
