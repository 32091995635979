// @flow
import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {FieldError} from "./FieldError";
import {PlusLg, Trash} from "react-bootstrap-icons";
import {Control, useFieldArray, UseFormRegister} from "react-hook-form";
import {GamePayload} from "../../schemas/game-schema";
import {FormState} from "react-hook-form/dist/types/form";
import {PricingPack} from "../../interfaces/pricing-pack";
import {GameFilterPack} from "../../interfaces/game-filter-pack";
import _ from "lodash";
import './RankedRateList.scss';
import {v4 as uuid} from "uuid";

type Props = {
    control: Control<GamePayload, any>;
    register: UseFormRegister<GamePayload>;
    formState: FormState<GamePayload>;
    pricingPack: PricingPack;
    filterPack?: GameFilterPack;
};

export function RankRateList(props: Props) {
    const {fields, append, remove} = useFieldArray({
        control: props.control,
        name: 'pricingPack.rankedRates'
    });

    const availableOptions = useMemo(() => props.filterPack?.filterOptions, [props.filterPack?.filterOptions])
    useEffect(() => {
        console.log(availableOptions?.map(option => option.optionName))
    }, [availableOptions])

    return (
        <div className="rank-rate-list">
            {fields.length === 0 && <span>No Items</span>}
            <Stack direction={"vertical"}>
                {fields.map((field, i) => (
                    <Stack key={`RankedRate-${i}`} className={'rank-rate-list-item'} direction={"horizontal"}>
                        <Form.Group key={field.id} className="my-1">
                            <Row>
                                <Col>
                                    <Form.Select
                                        placeholder="From Rank"
                                        {...props.register(`pricingPack.rankedRates.${i}.fromRankId`, {
                                            required: 'Field is required'
                                        })}
                                    >
                                        <option value={''}>Select From Rank</option>
                                        {_(availableOptions).compact()?.map(game => <option key={game.id}
                                                                                            value={game.id}>{game.optionName}</option>).value()}
                                    </Form.Select>
                                    <FieldError
                                        message={props.formState.errors?.pricingPack?.rankedRates?.[i]?.fromRankId?.message}
                                    />
                                </Col>
                                <Col>
                                    <Form.Select
                                        placeholder="To Rank"
                                        {...props.register(`pricingPack.rankedRates.${i}.toRankId`, {
                                            required: 'Field is required'
                                        })}
                                    >
                                        <option value={''}>Select To Rank</option>
                                        {_(availableOptions).compact()?.map(game => <option key={game.id}
                                                                                            value={game.id}>{game.optionName}</option>).value()}
                                    </Form.Select>
                                    <FieldError
                                        message={props.formState.errors?.pricingPack?.rankedRates?.[i]?.toRankId?.message}
                                    />
                                </Col>
                            </Row>
                            <Form.Control
                                className={'mt-1'}
                                placeholder="Rate"
                                {...props.register(`pricingPack.rankedRates.${i}.rate`, {
                                    required: 'Field is required',
                                    valueAsNumber: true,
                                    min: 0,
                                })}
                            />
                            <FieldError
                                message={props.formState.errors?.pricingPack?.rankedRates?.[i]?.rate?.message}
                            />
                        </Form.Group>
                        <Trash className={'remove-icon'} onClick={() => remove(i)}/>
                    </Stack>
                ))}
            </Stack>
            <Button className={'mt-1 dashboard-secondary-btn'} variant={"outline-primary"}
                    onClick={() => append({
                        fromRankId: '',
                        toRankId: '',
                        rate: 0,
                        id: uuid(),
                    })}><PlusLg
                size={20}/> Add New Option</Button>
        </div>);
};
