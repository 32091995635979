import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useGameOnce} from "../../hooks/use-game";
import {PricingPack} from "../../interfaces/pricing-pack";
import {GameSchemaExt} from "../../schemas/game-schema";
import {GameFilterPack} from "../../interfaces/game-filter-pack";
import {useNavigate} from "react-router-dom";
import {routes} from "../../App";
import "./CoackPackagesTab.scss"

export function CoachPackagesTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {game, gameLoading, gameError} = useGameOnce(user?.coachingGame);
    const navigate = useNavigate();

    const pricingPack: PricingPack = user?.pricingPack ?? game?.pricingPack ?? {};
    const chargingMode: string[] | false = user?.chargingMode ?? game?.chargingMode ?? false;
    const filterPack: GameFilterPack | undefined = game?.filters.find(filter => pricingPack.rankFilterId === filter.id);

    function getFilterName(rankId: string): string {
        return filterPack?.filterOptions.find(option => option.id === rankId)?.optionName ?? '-';
    }

    async function onEditPackages() {
        navigate(routes.editCoachPackages)
    }

    return <Col className={"coach-packages-tab"}>
        <h3 className={"primary-color"}>My Packages</h3>
        <p>Edit and create custom coaching packages below.</p>
        <div className="my-3">
            <Button className={"dashboard-secondary-btn"} onClick={() => onEditPackages()}>Edit My Packages</Button>
        </div>
        <div className="dashboard-content-outline">
            <div className="p-4">
                <Row>
                    <Col md={3}>
                        <img src="/assets/dashboard/valorant-logo.png" width={100} alt=""/>
                    </Col>
                    <Col md={9}>
                        <div className="game-name pb-3">{game?.name}</div>
                        <div className="my-packages-row pb-3">
                            <div className="my-packages-title ">Hourly Rate</div>
                            {GameSchemaExt.hasHourlyRate(chargingMode, pricingPack) &&
                                <div>{pricingPack?.hourlyRate} USD/hour</div>}
                        </div>
                        {GameSchemaExt.hasPerGame(chargingMode, pricingPack) && <div className="my-packages-row pb-2">
                            <div className="my-packages-title">Per Game Rate</div>
                            <div>{pricingPack?.perGameRate} USD/game</div>
                        </div>}
                        {GameSchemaExt.hasRanked(chargingMode, pricingPack) &&
                            <div className="my-packages-row">
                                <div className="my-packages-title">Ranked Rates</div>
                                <div>
                                    {pricingPack.rankedRates?.map(rank => <div className={"pt-2"}
                                                                               key={`${rank.fromRankId}-${rank.toRankId}`}>{rank.rate} USD&nbsp;&nbsp;&nbsp;&nbsp;
                                        {getFilterName(rank.fromRankId)} to {getFilterName(rank.toRankId)}</div>)}</div>
                            </div>}
                        <div className="my-packages-row pb-3">
                            <div className="my-packages-title ">VOD Hourly Rate</div>
                            {<div>{pricingPack?.vodRate} USD/hour</div>}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    </Col>;
}
