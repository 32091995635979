// @flow
import * as React from 'react';
import {Facet} from "../../definitions/facet";
import {useRefinementList} from "react-instantsearch-hooks-web";
import {Form, Stack} from "react-bootstrap";
import './FacetRefinementList.scss';

type Props = {
    facet: Facet
};

export function FacetRefinementList(props: Props) {
    const refinementList = useRefinementList({attribute: props.facet.id});
    if (!refinementList.items.length) return <></>;
    return (
        <div className={'facet-refinement-list mb-3'}>
            <div className={'facet-title'}>{props.facet.label}</div>
            <Stack className={'facet-option-list'}>
                {refinementList.items.map(
                    item => <Stack className={'facet-option'} key={item.value} direction={"horizontal"}>
                        <Form.Check checked={item.isRefined} onChange={() => refinementList.refine(item.value)}/>
                        <span className={'ms-2'}>{item.label}</span>
                    </Stack>)}
            </Stack>
        </div>
    );
};
