import React from "react";
import {Button, Container, Stack} from "react-bootstrap";
import '../common/HomePage.scss';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {functions} from "../../config/firebase-config";
import {CallableResponse} from "../../definitions/callable-response";
import {useStoreActions} from "../../models";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";

export const SystemPage = () => {
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const [executeCallable, executing, executionError] = useHttpsCallable<undefined, CallableResponse>(functions, 'initializeSystem');
    const {isBusy, setIsBusy} = useBusy();

    async function onClickInitSystem(): Promise<void> {
        setIsBusy(true);
        const response = await executeCallable();
        setIsBusy(false);
        console.log(response?.data);
        await uiStoreActions.addMessageAlert({
            title: 'Initialized',
            subtitle: response?.data?.message ?? 'No message'
        });
    }

    return (
        <Container className={'page system-configuration-page'}>
            <BusyOverlay isBusy={isBusy}>
                <div>
                    <h1>System Configuration</h1>
                    <Stack direction="horizontal" gap={2}>
                        <p>This Page will have the tooling for system configuration</p>
                    </Stack>
                    <Button onClick={() => onClickInitSystem()}>
                        Initialize System
                    </Button>
                </div>
            </BusyOverlay>
        </Container>
    );
}
