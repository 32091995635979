import {Event} from "react-big-calendar";
import {CalendarEventSchema} from "../schemas/booking-schema";
import {Dict} from "../interfaces/dict";

export interface CalendarEvent extends Event, Dict {
    id: string;
    desc?: string;
}

export abstract class CalendarEventExt {
    public static fromEventSchema(event: CalendarEventSchema): CalendarEvent {
        return {
            id: event.id,
            start: event.start?.toDate(),
            end: event.end?.toDate(),
        }
    }
}
