export enum PricingMode {
    Hourly = 0,
    Ranked = 1,
    PerGame = 2,
}

export abstract class PricingModeExt {
    public static toDisplayLabel(mode: PricingMode): string {
        switch (mode) {
            case PricingMode.Hourly:
                return 'Hourly';
            case PricingMode.Ranked:
                return 'Ranked';
            case PricingMode.PerGame:
                return 'Per Match';
        }
    }
}
