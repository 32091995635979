import React, {FC, PropsWithChildren, useEffect, useMemo, useState} from 'react'
import './Calendar.scss';
import {Calendar, dateFnsLocalizer, SlotInfo, Views} from "react-big-calendar";
import {CalendarEvent} from "../../definitions/calendar-event";
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import {enUS} from "date-fns/locale";
import {DateRange, DateRangeList, TimeUtils} from "../../utils/time-utils";
import {DateTime, Zone} from "luxon";

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

type BookingCalendarProps = {
    events: CalendarEvent[];
    backgroundEvents?: CalendarEvent[];
    availabilityRanges?: [number, number][];
    onAddEvent?: (slotInfo: SlotInfo) => void;
    onClickEvent?: (event: CalendarEvent) => void;
    timezone: Zone;
};
export const BookingCalendar: FC<BookingCalendarProps> = (props) => {
    const backgroundEvents = useMemo(() => props.backgroundEvents?.map(event => {
        return {
            ...event,
            isBackground: true,
        }
    }), [props.backgroundEvents]);

    const {defaultDate, scrollToTime} = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date(),
        }),
        []
    )
    const [availabilityEvents, setAvailabilityEvents] = useState<DateRangeList>([]);
    const [selectedDate, setSelectedDate] = useState<DateTime>(TimeUtils.dateOfTz(defaultDate.valueOf(), props.timezone));

    useEffect(() => {
        if (!props.availabilityRanges) return;
        updateAvailabilityEvents(selectedDate);
    }, [props.availabilityRanges, selectedDate])

    function updateAvailabilityEvents(targetDate: DateTime): void {
        const availabilityEvents: DateRangeList = props.availabilityRanges?.map(event => {
            return [
                TimeUtils.getStartOfWeek(targetDate).plus(TimeUtils.weeklyHalfHourIdToOffset(event[0])),
                TimeUtils.getStartOfWeek(targetDate).plus(TimeUtils.weeklyHalfHourIdToOffset(event[1], true))];
        }) ?? [];
        setAvailabilityEvents(availabilityEvents);
    }

    function isRangeAvailable(range: { start: Date, end: Date }) {
        const newEventToAdd: DateRange = TimeUtils.dateRangeOfTz(range.start.valueOf(), range.end.valueOf(), props.timezone);
        const tree = TimeUtils.getRangeTreeForDateRanges(availabilityEvents);
        const searchHalfHourRange = TimeUtils.inclusiveDateRangeToHalfHourRange(newEventToAdd);
        const overlaps = tree.search(searchHalfHourRange);
        return overlaps.length > 0;
    }

    function isRangeAvailableExhaustive(range: { start: Date, end: Date }): boolean {
        const tree = TimeUtils.getRangeTreeForDateRanges(availabilityEvents);
        let i = TimeUtils.dateOfTz(range.start.valueOf(), props.timezone);
        while (i.valueOf() <= range.end.valueOf()) {
            const searchHalfHourRange = TimeUtils.inclusiveDateRangeToHalfHourRange([i.minus({minute: 30}), i.plus({minute: 30})]);
            const overlaps = tree.search(searchHalfHourRange);
            if (overlaps.length === 0) {
                return false;
            }
            i = i.plus({minute: 30});
        }
        return true;
    }

    function isRangeUnoccupied(range: { start: Date, end: Date }) {
        const backgroundEventRanges: DateRangeList = (props.backgroundEvents ?? [])
            .map(myEvent => TimeUtils.dateRangeOfTz(myEvent.start!.valueOf(), myEvent.end!.valueOf(), props.timezone));
        const newEventToAdd: DateRange = TimeUtils.dateRangeOfTz(range.start.valueOf(), range.end.valueOf(), props.timezone);

        const tree = TimeUtils.getRangeTreeForDateRanges(backgroundEventRanges);
        const searchHalfHourRange = TimeUtils.inclusiveDateRangeToHalfHourRange(newEventToAdd);
        const overlaps = tree.search(searchHalfHourRange);
        let hasOverlaps = overlaps.length > 0;
        return !hasOverlaps;
    }

    function isRangeInFuture(range: { start: Date, end: Date }) {
        const nowTz = TimeUtils.nowTz(props.timezone);
        return TimeUtils.dateOfTz(range.start.valueOf(), props.timezone) > nowTz && TimeUtils.dateOfTz(range.end.valueOf(), props.timezone) > nowTz;
    }

    function isDateAvailable(date: DateTime): boolean {
        const rangeAvailable = isRangeAvailable({start: date.toJSDate(), end: date.plus({minute: 30}).toJSDate()});
        const nowTz = TimeUtils.nowTz(props.timezone);
        return date > nowTz && rangeAvailable;
    }

    function onNavigate(newDate: Date) {
        const dateOfTzName = TimeUtils.dateOfTz(newDate.valueOf(), props.timezone);
        if (!dateOfTzName) return;
        setSelectedDate(dateOfTzName);
    }

    return (
        <div className="booking-calendar">
            <Calendar
                onNavigate={(date) => onNavigate(date)}
                dayLayoutAlgorithm={"overlap"}
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                events={[...props.events, ...(backgroundEvents ?? [])]}
                localizer={localizer}
                onSelectEvent={props.onClickEvent}
                onSelectSlot={(event) => {
                    if (!props.onAddEvent) return;
                    if (!isRangeInFuture(event) || !isRangeUnoccupied(event) || !isRangeAvailableExhaustive(event)) return;
                    props.onAddEvent(event);
                }}
                onSelecting={range => {
                    return isRangeInFuture(range) && isRangeUnoccupied(range) && isRangeAvailableExhaustive(range);
                }}
                selectable={!!props.onAddEvent}
                scrollToTime={scrollToTime}
                showMultiDayTimes={true}
                eventPropGetter={(event) => {
                    return {className: event.isBackground ? 'background-booking-event' : ''}
                }}
                // backgroundEvents={props.availabilityEvents}
                views={['week']}
                components={{
                    timeSlotWrapper: (slotProps) => <TimeSlotWrapper {...slotProps} timezone={props.timezone}
                                                                     isAvailable={isDateAvailable}/>,
                }}
            />
        </div>
    )
};

type TimeSlotWrapperProps = {
    isAvailable: (date: DateTime) => boolean;
    timezone: Zone;
};

const TimeSlotWrapper: FC<PropsWithChildren<TimeSlotWrapperProps>> = function (props: any & TimeSlotWrapperProps) {
    const isGutter = props.resource === undefined;
    if (isGutter) return <>{props.children}</>;
    const child = React.Children.only(props.children);
    const classToAppend = props.isAvailable(TimeUtils.dateOfTz(props.value.valueOf(), props.timezone)) ? ' rbc-on-range-bg' : ' rbc-off-range-bg';
    return React.cloneElement(child, {className: child.props.className + classToAppend});
}
