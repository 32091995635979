import {getStripePayments} from "@stripe/firestore-stripe-payments";
import {firebaseApp} from "./firebase-config";

export class StripeConfig {
    public static collections = {
        productsCollection: 'products',
        customersCollection: 'users',
    };
}

export const stripePayments = getStripePayments(firebaseApp, {
    productsCollection: StripeConfig.collections.productsCollection,
    customersCollection: StripeConfig.collections.customersCollection,
});
