import {thunk, Thunk} from "easy-peasy";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {addDoc, collection, updateDoc} from "firebase/firestore";
import {db, storage} from "../config/firebase-config";
import _ from "lodash";
import {GamePayload} from "../schemas/game-schema";
import {doc} from "@firebase/firestore";
import {CommonUtils} from "../utils/common-utils";

export interface GamesModel {
    /* thunks */
    addGame: Thunk<GamesModel, GamePayload, any, {}, Promise<boolean>>;
    uploadLogo: Thunk<GamesModel, File>;
}

export const games: GamesModel = {
    /* thunks */
    addGame: thunk(async (actions, game) => {
        try {
            // await actions.addRanks(game);
            // const dUrl = await actions.uploadLogo(game.logo[0]);
            // game.logo = dUrl;
            if (game.updateId) {
                await updateDoc(doc(collection(db, 'games'), game.updateId), {
                    ..._.omit(CommonUtils.sanitizeData(game), "logo"),
                    orderIndex: 0,
                });
            } else {
                await addDoc(collection(db, 'games'), _.omit(CommonUtils.sanitizeData(game), "logo"));
            }
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
    uploadLogo: thunk(async (actions, logo) => {
        const storageRef = ref(storage, "images/" + logo.name);
        let result = await uploadBytes(storageRef, logo);
        return await getDownloadURL(result.ref);
    }),
};
