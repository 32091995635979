export enum EarningType {
    CoachingSession = 0,
    VodReview = 1,
    Tip = 2,
}

export abstract class EarningTypeExt {
    public static toDisplayLabel(value: EarningType): string {
        switch (value ?? EarningType.CoachingSession) {
            case EarningType.CoachingSession:
                return 'Coaching Session';
            case EarningType.VodReview:
                return 'VOD Review';
            case EarningType.Tip:
                return 'Tip';
        }
    }
}
