import React, {FC} from 'react';
import {Button, Col, Row, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import {CheckCircleFill, TrashFill} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import {BookingSchema} from "../../schemas/booking-schema";
import {BookingMode} from "../../definitions/booking-mode";
import {BookingStatus} from "../../definitions/booking-status";
import {SessionParticipant} from "../../definitions/session-participant";
import {useStoreActions} from "../../models";
import {TimeUtils} from "../../utils/time-utils";

export const BookingCoachDisplayPopup: FC<AlertRenderProps<void> & { bookingData: BookingSchema }> = (props) => {
    const navigate = useNavigate();
    const bookingStoreAction = useStoreActions((actions) => actions.booking);
    const uiStoreActions = useStoreActions((actions) => actions.ui);

    function buildCompletionActionButtons() {
        if (props.bookingData.bookingStatus !== BookingStatus.Accepted) return <></>;
        return <>
            <div className={"booking-request-info-row"}>
                <div className="booking-request-title">
                    Completion Actions
                </div>
                <div>
                    {props.bookingData.coachCompletion ||
                        <Button className={'me-1'} variant={"outline-success"} onClick={async () => {
                            const should = await uiStoreActions.addConfirmationAlert(
                                {message: 'Are you sure you want to mark the session as complete?'}
                            )
                            if (!should) return;
                            return bookingStoreAction.completeSession({
                                bookingId: props.bookingData.id,
                                sessionParticipant: SessionParticipant.Coach,
                                isComplete: true,
                            });
                        }}>
                            <CheckCircleFill
                                size={20}/><span className={'ms-2'}>Mark As Complete</span></Button>}
                    <div className={"pt-3"}>
                        <div className={'me-2 pt-2'}>Complete: {props.bookingData.coachCompletion ? 'YES' : 'NO'}</div>
                    </div>

                </div>
            </div>
        </>;
    }

    function buildProceedActionButtons() {
        if (props.bookingData.bookingStatus !== BookingStatus.Pending) return <></>;
        return <>
            <div className="booking-request-info-row">
                <div className="booking-request-title">Proceed</div>
                <div>
                    <Button className={'me-4'} variant={"outline-success"} onClick={async () => {
                        const should = await uiStoreActions.addConfirmationAlert({message: 'Are you sure you want to accept the session?'})
                        if (!should) return;
                        return bookingStoreAction.proceedBooking({
                            bookingId: props.bookingData.id,
                            accept: true
                        });
                    }}>
                        <CheckCircleFill
                            size={20}/></Button>
                    <Button variant={"outline-danger"} onClick={async () => {
                        const should = await uiStoreActions.addConfirmationAlert({message: 'Are you sure you want to reject the session?'})
                        if (!should) return;
                        return bookingStoreAction.proceedBooking({
                            bookingId: props.bookingData.id,
                            accept: false
                        });
                    }}><TrashFill
                        size={20}/></Button>
                </div>
            </div>
        </>;
    }

    return (
        <div>
            <Stack direction={"vertical"}>
                <div className="p-4">
                    <Row>
                        <Col md={6}>
                            <div className="student-info-row">
                                <div className={"dashboard-student-img"}><img
                                    src="/assets/profile/dummy-profile-1.jpg"
                                    width={100} alt=""/></div>
                                <div className={"student-info"}>
                                    <div className="student-name">{props.bookingData.studentPointer.name}</div>
                                    <div
                                        className="booking-mode primary-color pt-2">{BookingMode[props.bookingData.bookingMode]} - {BookingStatus[props.bookingData.bookingStatus]}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className={"booking-request-col pt-4 pt-md-0"}>
                            {props.bookingData.booking &&
                                <div className={"booking-request-time mb-4"}>
                                    <div className="booking-request-row">
                                        <div className="booking-request-bold">Start</div>
                                        {TimeUtils.dateToLocaleString(props.bookingData.booking.start)}
                                    </div>
                                    <div className="booking-request-row pt-2">
                                        <div className="booking-request-bold">End</div>
                                        {TimeUtils.dateToLocaleString(props.bookingData.booking.end)}</div>
                                </div>}

                            <div className={"mb-4"}>
                                {buildCompletionActionButtons()}
                            </div>
                            <div>
                                {buildProceedActionButtons()}
                            </div>

                        </Col>
                    </Row>
                </div>
            </Stack>
        </div>);
};
