import {Col, Container, Row} from "react-bootstrap";
import "./Footer.scss";
import {routes} from "../App";
import {useCurrentUser} from "../hooks/use-current-user";
import {useGames} from "../hooks/use-games";
import React from "react";
import {useNavigate} from "react-router-dom";

export const Footer = () => {
    const {allGames} = useGames();
    const navigate = useNavigate();
    const {authUser, user, isAdmin, isStudent, isCoach} = useCurrentUser();

    return (
        <div className="pt-5 pb-md-5 pb-0 bg-main-color footer">
            <Container className={"py-5"}>
                <Row className={"pb-3"}>
                    <Col md={8}>
                        <div className="footer-logo"><img src="/assets/Logo/LogoDark%20BG.png" width={250} alt=""/>
                        </div>
                        <div className={"footer-description"}>FPS Lounge is a coaching, training, and resource
                            platform built for players who want to get better at the games they love. We’re a community
                            committed to helping players improve and ultimately enjoy competitive gaming more.
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footer-links-main pt-5">
                            <div>
                                <div className="footer-title pb-3">Quick Links</div>
                                <div className="footer-links py-1"><a
                                    href={routes.coaches}>Coaches</a></div>
                                <div className="footer-links py-1"><a
                                    href={routes.pricing}>Pricing</a></div>
                                {(isCoach || isStudent) &&
                                    <div className="footer-links py-1"><a href={routes.chat}>Chat</a></div>}
                                {(isCoach || isAdmin) ||
                                    <div className="footer-links py-1"><a href={routes.coachRegister}>Become a
                                        Coach</a></div>}
                                <div className="footer-links py-1"><a href={routes.aboutUs}>About
                                    Us</a></div>
                                <div className="footer-links py-1"><a target={'_blank'}
                                                                      href={"mailto:support@fpslounge.com"}>Contact
                                    Us</a></div>
                            </div>
                            <div>
                                <div className="footer-title pb-3">Games</div>
                                {allGames?.map(game => <div key={game.id} className="footer-links clickable py-1">
                                    <div
                                        onClick={() => navigate(routes.coachesWithFilter.replace(':gameFilter', game.name))}>{game.name}</div>
                                </div>)}
                            </div>
                        </div>


                    </Col>

                </Row>

                <div className="h-rule"></div>


                <div className="footer-links float-end pt-2"><a href={routes.privacyPolicy}>Privacy Policy</a></div>
                <div className="footer-links float-md-end px-md-5 pt-2"><a href={routes.termsCondition}>Terms &
                    Conditions</a></div>

            </Container>
        </div>


    );
}
