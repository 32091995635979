import {Control, useFieldArray, UseFormRegister} from "react-hook-form";
import {GamePayload} from "../../schemas/game-schema";
import {FormState} from "react-hook-form/dist/types/form";
import {Button, Form, Stack} from "react-bootstrap";
import {FieldError} from "./FieldError";
import {PlusLg, Trash} from "react-bootstrap-icons";
import React from "react";
import './GameFilterForm.scss';
import {v4 as uuid} from "uuid";

function GameFilterOption(params: { control: Control<GamePayload, any>; register: UseFormRegister<GamePayload>; formState: FormState<GamePayload>; index: number }) {
    const {fields, append, remove} = useFieldArray({
        control: params.control,
        name: `filters.${params.index}.filterOptions`
    });
    return (
        <div className="filter-inner">
            {fields.length === 0 && <span>No Items</span>}
            <Stack direction={"vertical"}>
                {fields.map((field, i) => (
                    <Stack key={`GameFilterOption-${i}`} direction={"horizontal"}>
                        <Form.Group key={field.id} className="w-100 me-2 my-1">
                            <Form.Control
                                placeholder="Enter Option Name"
                                {...params.register(`filters.${params.index}.filterOptions.${i}.optionName`, {
                                    required: 'Field is required'
                                })}
                            />
                            <FieldError
                                message={params.formState.errors?.filters?.[params.index]?.filterOptions?.[i]?.message}
                            />
                        </Form.Group>
                        <Trash onClick={() => remove(i)}/>
                    </Stack>
                ))}
            </Stack>
            <Button className={'mt-1 dashboard-secondary-btn'} variant={"outline-primary"} onClick={
                () => append({optionName: "", id: uuid()})
            }><PlusLg
                size={20}/> Add New Option</Button>
        </div>
    );
}

export function FieldArray(params: { control: Control<GamePayload, any>; register: UseFormRegister<GamePayload>; formState: FormState<GamePayload> }) {
    const {fields, append, remove} = useFieldArray({
        control: params.control,
        name: "filters"
    });

    return (
        <div>
            {fields.map((field, i) => (
                <div
                    key={field.id}
                    className={`filter-outer`}
                >
                    <Stack direction={"horizontal"}>
                        <Form.Group className="w-100 me-2">
                            <Form.Control
                                placeholder="Enter Filter Name"
                                {...params.register(`filters.${i}.filterName`, {
                                    required: 'Field is required'
                                })}
                            />
                            <FieldError
                                message={params.formState.errors?.filters?.[i]?.message}
                            />
                        </Form.Group>
                        <Trash onClick={() => remove(i)}/>
                    </Stack>
                    <GameFilterOption index={i} {...{
                        control: params.control,
                        register: params.register,
                        formState: params.formState
                    }} />
                </div>
            ))}
            <Button className={"dashboard-secondary-btn"} variant={"outline-primary"}
                    onClick={() => append({id: uuid(), filterName: "", filterOptions: []})}><PlusLg
                size={20}/> Add New Filter</Button>
        </div>
    );
}
