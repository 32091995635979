import {createCheckoutSession, Session} from "@stripe/firestore-stripe-payments";
import {stripePayments} from "../config/stripe-config";
import {useBusy} from "./use-busy";
import {useSystemConfig} from "./use-system-config";
import {useStoreActions} from "../models";
import React from "react";
import {PaymentPopup, PaymentPopupResult} from "../components/alerts/PaymentPopup";
import {PaymentMethod} from "../definitions/payment-method";
import {PaypalConfig} from "../config/paypal-config";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {TypedCallableResponse} from "../definitions/typed-callable-response";
import {toast} from "react-toastify";

export function useAddFunds(): {
    isBusyAddFunds: boolean;
    addFunds: () => Promise<void>;
} {
    const {isBusy, setIsBusy} = useBusy();
    const {systemConfig} = useSystemConfig();
    const uiStoreActions = useStoreActions(state => state.ui);

    async function processStripePayment(amountToBuy: number): Promise<TypedCallableResponse<Session>> {
        if (!systemConfig) return {
            success: false,
            message: 'System is not an initialized state',
        }
        try {
            const session = await createCheckoutSession(stripePayments, {
                mode: "payment",
                price: systemConfig.products.creditPrice,
                quantity: amountToBuy,
            });
            console.log(session);
            setIsBusy(false);
            return {
                success: true,
                data: session,
                message: 'Payment received, your funds will arrive in 5-10 minutes.',
            }

        } catch (ex: any) {
            console.error('Paypal Payment Failed:', ex);
            setIsBusy(false);
            return {
                success: false,
                message: `Paypal Payment Failed: ${ex}`,
            }
        }
    }

    async function addFunds(): Promise<void> {
        if (!systemConfig) return;
        const value: PaymentPopupResult = await uiStoreActions.addCustomAlert({
            title: 'Add Funds',
            closeOnClickOutside: false,
            customClass: 'add-funds-alert',
            builder: (alertProps) => {
                return <PayPalScriptProvider
                    options={{
                        "client-id": PaypalConfig.clientId,
                        components: "buttons",
                        currency: "USD",
                    }}>
                    <PaymentPopup {...alertProps}/>
                </PayPalScriptProvider>;
            }
        });
        console.log(value)
        if (!value) return;
        const amountToBuy: number = Math.ceil(value.amount * 100);
        if (!amountToBuy) return;
        setIsBusy(true);
        switch (value.paymentMethod) {
            case PaymentMethod.Stripe:
                toast('You will be redirected to Stripe checkout in a moment...');
                const response = await processStripePayment(amountToBuy);
                if (response.success && response.data) {
                    window.location.href = response.data.url;
                } else {
                    await uiStoreActions.addMessageAlert({
                        title: 'Stripe Payment Failed!',
                        subtitle: response.message,
                    });
                }
                break;
            case PaymentMethod.Paypal:
                // paypal processing is done in place due to the architecture of their plugin
                break;
        }
    }

    return {
        isBusyAddFunds: isBusy,
        addFunds,
    };
}
