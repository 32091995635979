import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, query, where} from "@firebase/firestore";
import {bookingConverter, BookingSchema} from "../schemas/booking-schema";
import {BookingStatus} from "../definitions/booking-status";

export function useBookingsOfCoach(coachId: string | null | undefined, statusFilters?: BookingStatus[]): { allBookings: BookingSchema[] | undefined; bookingsError: FirestoreError | undefined; bookingsLoading: boolean } {
    const bookingsCollectionRef = collection(db, 'bookings').withConverter(bookingConverter);
    const statusConstraints = statusFilters ? [where("bookingStatus", "in", statusFilters)] : [];
    const bookingsQuery = coachId ? query<BookingSchema>(bookingsCollectionRef,
        where("coachPointer.id", "==", coachId),
        orderBy('created', 'desc'),
        ...statusConstraints) : undefined;
    const [allBookings, bookingsLoading, bookingsError] = useCollectionData<BookingSchema>(bookingsQuery);

    return {
        allBookings,
        bookingsLoading,
        bookingsError,
    };
}

export function useBookingsOfStudent(studentId: string | null | undefined, statusFilters?: BookingStatus[]): { allBookings: BookingSchema[] | undefined; bookingsError: FirestoreError | undefined; bookingsLoading: boolean } {
    const bookingsCollectionRef = collection(db, 'bookings').withConverter(bookingConverter);
    const statusConstraints = statusFilters ? [where("bookingStatus", "in", statusFilters)] : [];
    const bookingsQuery = studentId ? query<BookingSchema>(bookingsCollectionRef,
        where("studentPointer.id", "==", studentId),
        orderBy('created', 'desc'),
        ...statusConstraints) : undefined;
    const [allBookings, bookingsLoading, bookingsError] = useCollectionData<BookingSchema>(bookingsQuery);

    return {
        allBookings,
        bookingsLoading,
        bookingsError,
    };
}
