import {userConverter, UserSchema} from "../../schemas/user-schema";
import React from "react";
import {useStoreActions} from "../../models";
import {db} from "../../config/firebase-config";
import {collection, query, where} from "@firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import "./AdminCoachApprovalTab.scss"
import {BiCaretRight} from "react-icons/bi";
import {routes} from "../../App";
import {useNavigate} from "react-router-dom";

export function AdminCoachApprovalTab() {
    const uiStoreActions = useStoreActions(state => state.ui);
    const usersCollectionRef = collection(db, 'users').withConverter(userConverter);
    const pendingUsersQuery = query<UserSchema>(usersCollectionRef, where("coachRequested", "==", true));
    const [pendingUsers, loading, error, snapshot] = useCollectionData<UserSchema>(pendingUsersQuery);
    const navigate = useNavigate();

    function buildUserTableRow(userData: UserSchema) {
        return <div key={userData.email}
                    onClick={() => navigate(routes.coachApproval.replace(':username', userData.uid))}
                    className={'coach-request-item table-row-bg my-2 clickable'}>
            <div>
                <h5 className={"primary-color"}>{userData.displayName}</h5>
                <div className={"email-td"}>{userData.email}</div>
            </div>
            <BiCaretRight/>
        </div>;
    }

    return <>
        <div className="admin-coach-approval-tab">
            <h3 className={"primary-color"}>Coach Approval Requests</h3>
            <p>This section will contain a list of coach request submissions. You can either
                approve them or reject.</p>

            <div>
                {!pendingUsers?.length || loading || <div>
                    {loading || pendingUsers?.map(buildUserTableRow)}
                </div>}
                {!!pendingUsers?.length || <div>No pending coach approvals!</div>}
            </div>
        </div>
    </>;
}
