import React from "react";
import './NotificationsPage.scss'
import {Container} from "react-bootstrap";
import {useNotifications} from "../../hooks/use-notifications";
import {NotificationSchema} from "../../schemas/notification-schema";
import {TimeUtils} from "../../utils/time-utils";

export function NotificationsPage() {
    const {allNotifications} = useNotifications();

    function buildNotification(notification: NotificationSchema) {
        return <div className={'notification-list-item'}>
            <div>
                <div className={'notification-title primary-color'}>{notification.title}</div>
                <div className={'notification-body'}>{notification.body}</div>
                <div className={'notification-date'}>{TimeUtils.timestampToLocaleString(notification.created)}</div>
            </div>
            {/*<MdClose onClick={()=>onRemoveNotification()} size={25}/>*/}
        </div>;
    }

    return <Container className={'page notification-page'}>
        <h2 className={"primary-color dashboard-title pb-4"}>Notifications</h2>
        <hr/>
        <div style={{height: 600}}>
            {allNotifications?.map(notification => buildNotification(notification))}
            {!!allNotifications?.length || <div className={'mt-3'}>No notifications at the moment</div>}
        </div>
        <hr/>
    </Container>;
}
