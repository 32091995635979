import "./Footer.scss";
import {FC, PropsWithChildren, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../App";
import {useCurrentUser} from "../hooks/use-current-user";
import {SpinnerPadded} from "./SpinnerPadded";
import _ from "lodash";

type Props = {
    roleFilter: string [];
};

export const Protected: FC<PropsWithChildren & Props> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {user, userLoading} = useCurrentUser();
    useEffect(() => {
        console.log(location);
        if (user && !_.isEmpty(props.roleFilter) && !props.roleFilter.includes(user.role)) {
            navigate({
                pathname: routes.root,
            })
        }
        if (!userLoading && !user) {
            navigate({
                pathname: routes.login,
                search: `?redirect=${location.pathname}`,
            })
        }
    }, [userLoading, userLoading])

    if (userLoading || !user) return <div style={{padding: '200px 0'}}><SpinnerPadded/></div>;
    return <>{props.children}</>;
}
