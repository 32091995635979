import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, query, where} from "@firebase/firestore";
import {notificationConverter, NotificationSchema} from "../schemas/notification-schema";
import {useCurrentUser} from "./use-current-user";

export function useNotifications(): { allNotifications: NotificationSchema[] | undefined; notificationsError: FirestoreError | undefined; notificationsLoading: boolean } {
    const {user} = useCurrentUser();
    const notificationsCollectionRef = collection(db, 'activities').withConverter(notificationConverter);
    const notificationsQuery = !user ? null : query<NotificationSchema>(
        notificationsCollectionRef,
        orderBy('created', 'desc'),
        where("notify", "array-contains", user?.uid),
    );
    const [allNotifications, notificationsLoading, notificationsError] = useCollectionData<NotificationSchema>(notificationsQuery);

    return {
        allNotifications,
        notificationsLoading,
        notificationsError,
    };
}
