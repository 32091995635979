import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useWithdrawalsOfCoach} from "../../hooks/use-withdrawals";
import {CoachWithdrawalListItem} from "../../components/CoachWithdrawalListItem";
import {Button, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useWithdrawFunds} from "../../hooks/use-withdraw-funds";
import {useSetPaypalId} from "../../hooks/use-set-paypal-id";
import {UserSchemaExt} from "../../schemas/user-schema";

export function CoachWithdrawalsTab() {
    const {user, userLoading} = useCurrentUser();
    const {allWithdrawals} = useWithdrawalsOfCoach(user?.uid);
    const {isBusyWithdrawFunds, withdrawFunds} = useWithdrawFunds();
    const {isBusySetPaypalId, changePaypalId} = useSetPaypalId();

    return (<BusyOverlay isBusy={isBusySetPaypalId || isBusyWithdrawFunds}>
        <div>
            <h3 className={"primary-color"}>My Withdrawals</h3>
            <p>Review your withdrawals history below.</p>
            <Row className={'py-4'}>
                <Col>{user?.paypalId ?? 'No Paypal Email Set'}</Col>
                <Col><Button variant="danger" onClick={() => changePaypalId()} className={"w-100"}>
                    Change PayPal Email
                </Button></Col>
            </Row>
            <OverlayTrigger
                trigger={UserSchemaExt.canWithdraw(user) ? [] : ['hover', 'focus']}
                placement="bottom"
                overlay={(<Popover>
                    You must have at least 50 USD in your wallet before you can withdraw.
                </Popover>)}
            >
                <div>
                    <Button variant="primary" disabled={!UserSchemaExt.canWithdraw(user)}
                            onClick={() => withdrawFunds()} className={"dashboard-primary-btn w-100"}>
                        Withdraw
                    </Button>
                </div>
            </OverlayTrigger>
            <div className="mt-4">
                {userLoading || allWithdrawals?.map(withdrawal =>
                    <CoachWithdrawalListItem key={withdrawal.id} withdrawalData={withdrawal}/>)}
            </div>
        </div>
    </BusyOverlay>);
}
