export enum BookingMode {
    Scheduled = 0,
    Instant = 1
}

export abstract class BookingModeExt {
    public static toDisplayLabel(bookingMode: BookingMode | null): string {
        switch (bookingMode) {
            case BookingMode.Scheduled:
                return 'Scheduled';
            case BookingMode.Instant:
                return 'Instant';
            default:
                return 'Unknown';
        }
    }
}
