import * as React from 'react';
import {useEffect} from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import "./MainNavbar.scss";
import {auth} from "../config/firebase-config";
import {useStoreActions} from "../models";
import {routes} from "../App";
import {useCurrentUser} from "../hooks/use-current-user";
import {useLocation, useNavigate} from "react-router-dom";
import {useGames} from "../hooks/use-games";
import _ from "lodash";
import {useAddFunds} from "../hooks/use-add-funds";
import {AppConfig} from "../config/app-config";

export function MainNavbar() {
    const uiStoreActions = useStoreActions(state => state.ui);
    const {authUser, user, isAdmin, isStudent, isCoach} = useCurrentUser();
    const navigate = useNavigate();
    const {allGames} = useGames();
    const {addFunds} = useAddFunds();
    const location = useLocation();
    useEffect(() => {
        console.log(location)
    }, [location])

    async function onRunLab() {
        await uiStoreActions.addMessageAlert({
            title: "Hello There!",
            subtitle: "Subtitle here"
        });
    }

    async function onClickSignOut() {
        await auth.signOut();
        navigate(routes.login);
    }

    return (

        <div className="app-navbar">
            <div className="navbar-desktop">

                <Container>
                    <div className="navbar-desktop-main">
                        <div>
                            <Navbar.Brand href={routes.root}><img
                                src={AppConfig.isProductionEnv ? "/assets/Logo/LogoDark BG.png" : "/assets/Logo/LogoDark BG Staging.png"}
                                alt=""
                                width={175}/></Navbar.Brand>
                        </div>
                        <div className={"navbar-desktop-items"}>
                            {isStudent && <NavDropdown className={"navbar-primary-btn"} title={`Start Your Session`}
                                                       id="basic-nav-dropdown">
                                {allGames?.map(game => <NavDropdown.Item key={game.id}
                                                                         onClick={() => {
                                                                             navigate(routes.coachesWithFilter.replace(':gameFilter', game.name));
                                                                             navigate(0);
                                                                         }}>{game.name.toUpperCase()}</NavDropdown.Item>)}
                            </NavDropdown>}

                            {isCoach && <div className="navbar-primary-btn coach-dashboard-btn"><Nav.Link
                                href={routes.coachDashboard}>Dashboard</Nav.Link></div>}

                            {/*<Nav.Link href={routes.root}>Home</Nav.Link>*/}
                            <Nav.Link href={routes.coaches}>Coaches</Nav.Link>
                            <Nav.Link href={routes.pricing}>Pricing</Nav.Link>
                            {(isCoach || isAdmin) || <Nav.Link href={routes.coachRegister}>Become a Coach</Nav.Link>}
                            <Nav.Link href={routes.aboutUs}>About Us</Nav.Link>


                            <div className="navbar-add-fund-main">{isStudent &&
                                <div className={"navbar-add-fund"}><NavDropdown
                                    title={`Balance : $${user?.balance ?? 'Unknown'}`}
                                    id="basic-nav-dropdown">

                                    <NavDropdown.Item onClick={() => addFunds()}>Add Funds</NavDropdown.Item>
                                </NavDropdown></div>}
                            </div>


                        </div>
                        <div className={"navbar-primary-btn navbar-desktop-login"}>
                            {authUser &&
                                <NavDropdown title={`${_.truncate(authUser?.displayName ?? 'Unknown', {length: 14})}`}
                                             id="basic-nav-dropdown">
                                    {isStudent &&
                                        <NavDropdown.Item href={routes.studentDashboard}>Dashboard</NavDropdown.Item>}
                                    {isCoach &&
                                        <NavDropdown.Item href={routes.coachDashboard}>Dashboard</NavDropdown.Item>}
                                    {(isAdmin) &&
                                        <NavDropdown.Item href={routes.admin}>Admin Zone</NavDropdown.Item>}
                                    {user &&
                                        <NavDropdown.Item href={routes.notifications}>Notifications</NavDropdown.Item>}
                                    <NavDropdown.Item onClick={() => navigate(routes.chat)}>Messages</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => onClickSignOut()}>Sign Out</NavDropdown.Item>
                                </NavDropdown>}
                            {!authUser && location.pathname !== routes.login &&
                                <Nav.Link href={routes.login}>Sign In</Nav.Link>}
                        </div>
                    </div>
                </Container>


            </div>


            <div className="navbar-mobile">

                <Navbar variant={"dark"} expand="xxl" fixed={"top"} className={"navbar-color"}>
                    <Container>
                        <Navbar.Brand href={routes.root}><img
                            src="/assets/Logo/LogoDark%20BG.png" alt=""
                            width={100}/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto navbar-mobile-items">
                                {/*<Nav.Link href={routes.root}>Home</Nav.Link>*/}
                                {isStudent && <NavDropdown className={"navbar-primary-btn"} title={`Start Your Session`}
                                                           id="basic-nav-dropdown">
                                    {allGames?.map(game => <NavDropdown.Item key={game.id}
                                                                             onClick={() => {
                                                                                 navigate(routes.coachesWithFilter.replace(':gameFilter', game.name));
                                                                                 navigate(0);
                                                                             }}>{game.name.toUpperCase()}</NavDropdown.Item>)}
                                </NavDropdown>}

                                {isCoach && <div className="navbar-primary-btn coach-dashboard-btn"><Nav.Link
                                    href={routes.coachDashboard}>Dashboard</Nav.Link></div>}


                                <Nav.Link className={"mt-3"} href={routes.coaches}>Coaches</Nav.Link>
                                <Nav.Link className={"mt-3"} href={routes.pricing}>Pricing</Nav.Link>
                                {isStudent &&
                                    <Nav.Link className={"mt-3"} href={routes.coachRegister}>Become a Coach</Nav.Link>}
                                <Nav.Link className={"mt-3"} href={routes.aboutUs}>About Us</Nav.Link>
                            </Nav>

                            {authUser &&
                                <div className={"navbar-add-fund mt-3"}>
                                    <NavDropdown title={`Balance : $${user?.balance ?? 'Unknown'}`}
                                                 id="basic-nav-dropdown">
                                        {isStudent &&
                                            <NavDropdown.Item onClick={() => addFunds()}>Add Funds</NavDropdown.Item>}
                                    </NavDropdown></div>}

                            {authUser &&
                                <NavDropdown className={"sign-in-btn mt-4"}
                                             title={`Hello ${authUser?.displayName ?? 'Unknown'}`}
                                             id="basic-nav-dropdown">
                                    {isCoach &&
                                        <NavDropdown.Item href={routes.coachDashboard}>Coach
                                            Dashboard</NavDropdown.Item>}
                                    {isStudent &&
                                        <NavDropdown.Item href={routes.studentDashboard}>Student
                                            Dashboard</NavDropdown.Item>}
                                    {(isAdmin) &&
                                        <NavDropdown.Item href={routes.admin}>Admin Zone</NavDropdown.Item>}
                                    {user &&
                                        <NavDropdown.Item href={routes.notifications}>Notifications</NavDropdown.Item>}
                                    <NavDropdown.Item onClick={() => navigate(routes.chat)}>Messages</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => auth.signOut()}>Sign Out</NavDropdown.Item>
                                </NavDropdown>}
                            {!authUser && location.pathname !== routes.login &&
                                <Nav.Link className={"sign-in-btn mt-3"} href={routes.login}>Sign In</Nav.Link>}

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>


    );
}
