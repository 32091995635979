import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {CreateBatchPayoutResponse} from "@paypal/payouts-sdk";

export interface WithdrawalSchema {
    id: string;
    created: Timestamp;
    modified: Timestamp;
    uid: string;
    amount: number;
    payoutResponse: CreateBatchPayoutResponse;
}

export const withdrawalConverter: FirestoreDataConverter<WithdrawalSchema> = {
    toFirestore(withdrawal: WithFieldValue<WithdrawalSchema>): DocumentData {
        return withdrawal;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): WithdrawalSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as WithdrawalSchema;
    },
};
