// @flow
import * as React from 'react';
import {routes} from "../App";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useStoreActions} from "../models";

type Props = {
    setIsBusy: (isBusy: boolean) => void;
};

export function SocialLoginBar(props: Props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {signUserWithGoogle, signUserWithTwitch} = useStoreActions((actions) => actions.users);
    const uiStoreActions = useStoreActions(state => state.ui);

    const onSignInWithGoogle = async () => {
        props.setIsBusy(true);
        try {
            let result = await signUserWithGoogle();
            console.log('Login with google result is:', result)
            if (result === "SUCCESS") navigate(searchParams.get('redirect') ?? routes.root);
        } catch (ex: any) {
            console.error(ex)
            await uiStoreActions.addMessageAlert({
                title: 'Login Failed',
                subtitle: ex.message,
            })
        }
        props.setIsBusy(false);
    };

    const onSignInWithTwitch = async () => {
        props.setIsBusy(true);
        try {
            let result = await signUserWithTwitch();
            console.log('Login with twitch result is:', result)
            if (result === "SUCCESS") navigate(searchParams.get('redirect') ?? routes.root);
        } catch (ex: any) {
            console.error(ex)
            await uiStoreActions.addMessageAlert({
                title: 'Login Failed',
                subtitle: ex.message,
            })
        }
        props.setIsBusy(false);
    };

    return (<div className="login-social-icons-main py-5">
            <div className="social-icon" onClick={onSignInWithGoogle}>
                <div className="social-img"><img src="/assets/Login/google.png" width={23}
                                                 alt=""/>
                </div>
            </div>
            <div className="social-icon" onClick={onSignInWithTwitch}>
                <div className="social-img"><img src="/assets/Login/twitch.png" width={23}
                                                 alt=""/>
                </div>
            </div>
            <div className="social-icon disabled">
                <div className="social-img"><img src="/assets/Login/discord.png" width={30}
                                                 alt=""/>
                </div>
            </div>
            <div className="social-icon disabled">
                <div className="social-img"><img src="/assets/Login/apple.png" width={21}
                                                 alt=""/>
                </div>
            </div>
        </div>
    );
};
