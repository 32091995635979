import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, Query, query, where} from "@firebase/firestore";
import {gainConverter, GainSchema} from "../schemas/gain-schema";

export function useGainsOfStudent(studentId?: string | null): {
    allGains: GainSchema[] | undefined;
    gainsError: FirestoreError | undefined;
    gainsLoading: boolean;
    gainsQuery?: Query<GainSchema>;
} {
    const gainsCollectionRef = collection(db, 'gains').withConverter(gainConverter);
    const gainsQuery = studentId ? query<GainSchema>(gainsCollectionRef, where("targetUserPointer.id", "==", studentId), orderBy('created', "desc")) : undefined;
    const [allGains, gainsLoading, gainsError] = useCollectionData<GainSchema>(gainsQuery);

    return {
        allGains,
        gainsLoading,
        gainsError,
        gainsQuery,
    };
}

export function useAllGains(): {
    allGains: GainSchema[] | undefined;
    gainsError: FirestoreError | undefined;
    gainsLoading: boolean;
    gainsQuery?: Query<GainSchema>;
} {
    const gainsCollectionRef = collection(db, 'gains').withConverter(gainConverter);
    const gainsQuery = query<GainSchema>(gainsCollectionRef, orderBy('created', "desc"));
    const [allGains, gainsLoading, gainsError] = useCollectionData<GainSchema>(gainsQuery);

    return {
        allGains,
        gainsLoading,
        gainsError,
        gainsQuery,
    };
}
