import React from "react";
import {Button, Container, Stack, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useStoreActions} from "../../models";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {CoachRequestProceedParams} from "../../interfaces/coach-request-proceed-params";
import {CallableResponse} from "../../definitions/callable-response";
import {functions} from "../../config/firebase-config";
import {useBusy} from "../../hooks/use-busy";
import {useUser} from "../../hooks/use-user";
import './CoachApprovalPage.scss';
import {useGameOnce} from "../../hooks/use-game";
import {UserSchemaExt} from "../../schemas/user-schema";
import _ from "lodash";

export function CoachApprovalPage() {
    let {username} = useParams();
    const navigate = useNavigate();
    const {userData: coachData} = useUser(username);
    const {game} = useGameOnce(coachData?.coachingGame);
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const [executeCallable, executing, executionError] = useHttpsCallable<CoachRequestProceedParams, CallableResponse>(functions, 'proceedCoachRequest');
    const {isBusy, setIsBusy} = useBusy();

    async function onAcceptRequest() {
        if (!coachData) return;
        const should = await uiStoreActions.addConfirmationAlert({
            message: `Are you sure you want to accept the coach request from ${coachData.displayName}?`,
        });
        if (!should) return;
        setIsBusy(true);
        const response = await executeCallable({
            uid: coachData.uid,
            proceed: true,
        });
        await uiStoreActions.addMessageAlert({
            title: response?.data.success ? 'Coach request approved' : 'Failed to approve',
            subtitle: response?.data.success ? 'Coach request was approved successfully. Now coach has access to his dashboard' : `Approval failed with error: ${response?.data.message}`,
        });
        setIsBusy(false);
        navigate(-1);
    }

    async function onRejectRequest() {
        if (!coachData) return;
        const should = await uiStoreActions.addConfirmationAlert({
            title: 'Confirmation',
            message: `Are you sure you want to reject the coach request from ${coachData.displayName}?`,
        });
        if (!should) return;
        setIsBusy(true);
        const response = await executeCallable({
            uid: coachData.uid,
            proceed: false,
        });
        await uiStoreActions.addMessageAlert({
            title: response?.data.success ? 'Coach request rejected' : 'Failed to reject',
            subtitle: response?.data.success ? 'Coach request was rejected successfully.' : `Rejection failed with error: ${response?.data.message}`,
        });
        setIsBusy(false);
        navigate(-1);
    }

    function onBack() {
        navigate(-1);
    }

    return (<Container className={'page coach-approval-page'}>
        <BusyOverlay isBusy={isBusy}>
            <h2 className={"primary-color"}>Approve Coach Request</h2>
            <p>Review, approve and reject coach requests from users.</p>
            {coachData && <Stack direction={"vertical"}>
                <div className="mt-3" style={{textAlign: "start"}}>
                    <Table>
                        <tbody>
                        <tr>
                            <td>Coach Name</td>
                            <td>{coachData.displayName}</td>
                        </tr>
                        <tr>
                            <td>Coaching Game</td>
                            <td>{game?.name}</td>
                        </tr>
                        <tr>
                            <td>Gamer Tag</td>
                            <td>{coachData.gamerTag}</td>
                        </tr>
                        <tr>
                            <td>Languages</td>
                            <td>
                                <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                    {UserSchemaExt.languageInfo(coachData)?.map(language => <div key={language.id}
                                                                                                 className="language-img px-1">
                                        <img
                                            src={language.flagUrl}
                                            width={30} alt=""/></div>)}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Coach Profile Bio</td>
                            <td>{coachData.bio}</td>
                        </tr>
                        <tr>
                            <td>Coach VOD Bio</td>
                            <td>{coachData.vodBio}</td>
                        </tr>
                        <tr>
                            <td>Highlight Points</td>
                            <td>
                                <ul className={'m-0'}>
                                    {_.compact(coachData.mainPoints)?.map(point => <li
                                        key={point.id}>{point.label}</li>)}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Discord Username</td>
                            <td>{coachData.discord}</td>
                        </tr>
                        <tr>
                            <td>Youtube Channel</td>
                            <td>{coachData.youtube}</td>
                        </tr>
                        <tr>
                            <td>Twitter Username</td>
                            <td>{coachData.twitterUsername}</td>
                        </tr>
                        <tr>
                            <td>Twitch Username</td>
                            <td>{coachData.twitchUsername}</td>
                        </tr>
                        <tr>
                            <td>K/D Ratio</td>
                            <td>{coachData.kdRatio}</td>
                        </tr>
                        <tr>
                            <td>Win Rate</td>
                            <td>{coachData.winRate} %</td>
                        </tr>
                        <tr>
                            <td>Total Kills</td>
                            <td>{coachData.totalKills}</td>
                        </tr>
                        <tr>
                            <td>Total Deaths</td>
                            <td>{coachData.totalDeaths}</td>
                        </tr>
                        <tr>
                            <td>Platforms</td>
                            <td>{UserSchemaExt.platformInfo(coachData)?.map(platform => platform.label).join(', ')}</td>
                        </tr>
                        <tr>
                            <td>Input Methods</td>
                            <td>{UserSchemaExt.inputMethodInfo(coachData)?.map(inputMethods => inputMethods.label).join(', ')}</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </Stack>}
            <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
                <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                        onClick={() => onBack()}>
                    Back
                </Button>
                <Button variant="primary" className={"dashboard-primary-btn w-100"}
                        onClick={() => onRejectRequest()}>
                    Reject
                </Button>
                <Button variant="primary" onClick={() => onAcceptRequest()} className={"dashboard-success-btn w-100"}>
                    Approve
                </Button>
            </Stack>
        </BusyOverlay>
    </Container>);
}
