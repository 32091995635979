import React, {FC} from "react";
import {WithdrawalSchema} from "../schemas/withdrawal-schema";
import "./CoachWithdrawalListItem.scss"
import {TimeUtils} from "../utils/time-utils";

export const CoachWithdrawalListItem: FC<{ withdrawalData: WithdrawalSchema; }> = function (props) {
    function buildDataItem(title: string, value: string) {
        return <div className={'d-flex'} style={{justifyContent: "space-between"}}>
            <div className="coach-withdrawal-title">
                {title}
            </div>
            <div className="coach-withdrawal-info">
                {value}
            </div>
        </div>;
    }

    return (
        <div className={"coach-withdrawal-list-item mb-4"} key={props.withdrawalData.id}>
            <div className="dashboard-box-outline">
                <div className="coach-withdrawal-row py-4 px-5">
                    <h5 className={'py-3'}>
                        FPS Lounge Fund Withdrawal
                    </h5>
                    {buildDataItem(
                        'Requested On:',
                        TimeUtils.timestampToLocaleString(props.withdrawalData.created),
                    )}
                    {buildDataItem(
                        'Total Withdrawal Amount:',
                        `${props.withdrawalData.amount} USD`,
                    )}
                    {buildDataItem(
                        'Paypal Batch ID:',
                        `${props.withdrawalData.payoutResponse?.batch_header?.payout_batch_id ?? '-'}`,
                    )}
                </div>
            </div>
        </div>);
};
