import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, query, where} from "@firebase/firestore";
import {withdrawalConverter, WithdrawalSchema} from "../schemas/withdrawal-schema";

export function useWithdrawalsOfCoach(coachId?: string | null): {
    allWithdrawals: WithdrawalSchema[] | undefined;
    withdrawalsError: FirestoreError | undefined;
    withdrawalsLoading: boolean
} {
    const withdrawalsCollectionRef = collection(db, 'withdrawals').withConverter(withdrawalConverter);
    const withdrawalsQuery = coachId ? query<WithdrawalSchema>(withdrawalsCollectionRef, where("uid", "==", coachId)) : undefined;
    const [allWithdrawals, withdrawalsLoading, withdrawalsError] = useCollectionData<WithdrawalSchema>(withdrawalsQuery);

    return {
        allWithdrawals,
        withdrawalsLoading,
        withdrawalsError,
    };
}
