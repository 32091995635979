import React, {FC} from 'react';
import {Button, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import {useBooking} from "../../hooks/use-booking";
import {SpinnerPadded} from "../SpinnerPadded";
import {BookingMode} from "../../definitions/booking-mode";
import {BookingStatus} from "../../definitions/booking-status";
import {CommonUtils} from "../../utils/common-utils";
import {TimeUtils} from "../../utils/time-utils";

export const BookingRequestedPopup: FC<AlertRenderProps<void> & { bookingId: string }> = (props) => {
    const {booking, bookingLoading} = useBooking(props.bookingId);
    return (
        <div>
            <Stack style={{textAlign: "start"}} className={'px-3'}>
                <div className="mt-4">
                    <p>Please wait until the coach accepts the session request or you can see this booking on your
                        student dashboard. After the coach accepts the request, you can jump to
                        discord for the session.</p>
                    <p></p>
                </div>
                {bookingLoading && <SpinnerPadded/>}
                {booking && <div className={'mb-4'}>
                    <div className="student-name">Coach - <span
                        className={'primary-color fw-semibold'}>{booking.coachPointer.name}</span></div>
                    <div className="booking-mode">Booking Type - <span
                        className={'primary-color fw-semibold'}>{BookingMode[booking.bookingMode]}</span></div>
                    <div className="booking-status">Booking Status - <span
                        className={'primary-color fw-semibold'}>{BookingStatus[booking.bookingStatus]}</span></div>
                    {booking.booking &&
                        <div className={"my-bookings-time"}>
                            <div>Start - <span
                                className={'primary-color fw-semibold'}>{TimeUtils.dateToLocaleString(booking.booking.start)}</span>
                            </div>
                            <div>End - <span
                                className={'primary-color fw-semibold'}>{TimeUtils.dateToLocaleString(booking.booking.end)}</span>
                            </div>
                        </div>}
                </div>}
                <Button variant={"outline-light"} className={'mb-2'} onClick={() => {
                    CommonUtils.openInNewTab('https://discord.com/invite/t9tBBBYpmY');
                }}>Open
                    Discord</Button>
                <Button variant={"outline-light"} onClick={() => props.cancel()}>Close</Button>
            </Stack>
        </div>
    );
};
