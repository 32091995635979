import {UserLanguage} from "../interfaces/user-language";
import {DataPointer} from "../interfaces/data-pointer";

export class DataConfig {
    public static readonly supportedLanguages: UserLanguage[] = [
        {
            id: 'en',
            label: 'English (US)',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/English_language.svg/45px-English_language.svg.png',
        },
        {
            id: 'fi',
            label: 'Finnish',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/45px-Flag_of_Finland.svg.png',
        },
        {
            id: 'es',
            label: 'Spanish',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Spanish_language_%28ES-MX%29.svg/45px-Flag_of_Spanish_language_%28ES-MX%29.svg.png',
        },
        {
            id: 'ja',
            label: 'Japanese',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flag_of_Japan.svg/45px-Flag_of_Japan.svg.png',
        },
        {
            id: 'zh',
            label: 'Chinese',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/45px-Flag_of_the_People%27s_Republic_of_China.svg.png',
        },
        {
            id: 'ru',
            label: 'Russian',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/45px-Flag_of_Russia.svg.png',
        },
        {
            id: 'de',
            label: 'German',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/45px-Flag_of_Germany.svg.png',
        },
        {
            id: 'ar',
            label: 'Arabic',
            flagUrl: 'https://en.wiktionary.org/wiki/File:Flag_of_the_Arab_League.svg',
        },
        {
            id: 'fr',
            label: 'French',
            flagUrl: 'https://en.wiktionary.org/wiki/File:Flag_of_France.svg',
        },
        {
            id: 'yue',
            label: 'Cantonese',
            flagUrl: 'https://en.wiktionary.org/wiki/File:Flag_of_Hong_Kong.svg',
        },
        {
            id: 'ko',
            label: 'Korean',
            flagUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/45px-Flag_of_South_Korea.svg.png',
        },
    ];
    public static readonly supportedPlatforms: DataPointer[] = [
        {
            id: 'pc',
            label: 'PC',
        },
        {
            id: 'console',
            label: 'Console',
        },
        {
            id: 'mobile',
            label: 'Mobile',
        }
    ];
    public static readonly supportedInputMethods: DataPointer[] = [
        {
            id: 'keyboard',
            label: 'Keyboard',
        },
        {
            id: 'controller',
            label: 'Controller',
        },
        {
            id: 'touch',
            label: 'Touch',
        }
    ];

    public static readonly comingSoonCoaches: { "name": string, "email": string }[] = [
        {
            "name": "Glocktane",
            "email": "dseymjr@gmail.com"
        }, {
            "name": "Mittn",
            "email": "metin.tand@gmail.com"
        }, {"name": "Kurtybirdie", "email": "kbonsted13@gmail.com"}, {
            "name": "chiknnuggey",
            "email": "tcatania2112@gmail.com"
        }, {"name": "creanak#love", "email": "johncreanak@gmail.com"}, {
            "name": "SnipernamedG",
            "email": "botnamedg@gmail.com"
        }, {"name": "Empr", "email": "emprdropmaps@gmail.com"}, {
            "name": "CalebFTG",
            "email": "ftgcaleb@gmail.com"
        }, {"name": "ijaN", "email": "brownnaji42@gmail.com"}, {
            "name": "TrapisKilled",
            "email": "seansan69@hotmail.com"
        }, {"name": "Laiys", "email": "crazykalski@gmail.com"}, {
            "name": "Kaido",
            "email": "kaido1x@protonmail.com"
        }, {"name": "KNG Sparebow", "email": "sparebow@yahoo.com"}, {
            "name": "iSypo (FLu iSypoYT)",
            "email": "isypomail5@gmail.com"
        }, {"name": "Fair#Fairr", "email": "adamessamelharoun@gmail.com"}, {
            "name": "SSLCK#001",
            "email": "innovationalgamingssick@gmail.com"
        }, {"name": "antifiber#001", "email": "titolamboy2003@gmail.com"}, {
            "name": "niftyAims",
            "email": "fpsnifty@gmail.com"
        }, {"name": "Sweaterr#1996", "email": "Drewjacob96@gmail.com"}, {
            "name": "TeeJay",
            "email": "aromanoiii2003@gmail.com"
        }, {"name": "narwhal", "email": "narwhalfn16@gmail.com"}, {
            "name": "Kanequake",
            "email": "ikanetv@gmail.com"
        }, {"name": "ItsVoltic_", "email": "justmaunz@yahoo.com"}, {
            "name": "BarretJ",
            "email": "twitch.barrettj@gmail.com"
        }, {"name": "Stonie#4423", "email": "ffstonie612@gmail.com"}, {
            "name": "Patzukka",
            "email": "Patzukka_@hotmail.com"
        }, {"name": "Stellarmoves", "email": "ethanstellarbiz@gmail.com"}, {
            "name": "ODK f0rsaken",
            "email": "f0rsakenwowlive@gmail.com"
        }, {"name": "Kruhmmy", "email": "edgarf372@gmail.com"}, {
            "name": "FarmerLucas",
            "email": "watsonwpo@gmail.com"
        }, {"name": "MPxD#2179", "email": "mpxd.business@gmail.com"}, {
            "name": "Shraded#11378",
            "email": "shradedtv@gmail.com"
        }, {"name": "geesh", "email": "geeshtv7@gmail.com"}, {
            "name": "Rondonstreams",
            "email": "therondon666@gmail.com"
        }, {"name": "tezsi ネ", "email": "Tezsigg@gmail.com"}, {
            "name": "ZerG#3646",
            "email": "zergog.tv@gmail.com"
        }, {"name": "withoutSPRIT", "email": "C.Gemmer995@gmail.com"}, {
            "name": "Aladdin#12773",
            "email": "AladdinLTDBusiness@gmail.com"
        }, {"name": "XII#cyx", "email": "yansiang0616@gmail.com"}, {
            "name": "Bobo Nozz#VCT",
            "email": "nozz1130@gmail.com"
        }, {"name": "1", "email": "stephen@icloud.com"}, {
            "name": "Kozok#7887",
            "email": "kozokyt@gmail.com"
        }, {"name": "Roligann", "email": "magnusskotte@gmail.com"}, {
            "name": "Vetreon",
            "email": "vetreon@yahoo.com"
        }, {"name": "KingValorant", "email": "Valorant@FPSLounge.com"}, {
            "name": "5",
            "email": "Apex@FPSlounge.com"
        }, {"name": "5", "email": "Fortnite@FPSlounge.com"}, {
            "name": "taingysauce",
            "email": "taingderek@gmail.com"
        }, {"name": "HotdamnFram", "email": "farb0204@yahoo.com"}];
}
