import React from "react";
import "./CoachRespondToVodPage.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useStoreActions} from "../../models";
import {useVodOnce} from "../../hooks/use-vod";
import {VodTypeExt} from "../../definitions/vod-type";
import {VodReviewStatus} from "../../definitions/vod-review-status";
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap";
import {FieldError} from "../../components/form/FieldError";
import {SubmitHandler, useForm} from "react-hook-form";
import {RespondToVodPayload} from "../../schemas/booking-schema";
import {useBusy} from "../../hooks/use-busy";
import {useCurrentUser} from "../../hooks/use-current-user";
import {BusyOverlay} from "../../components/BusyOverlay";
import {MdLink, MdNote, MdPerson, MdPlayArrow} from "react-icons/md";

export const CoachRespondToVodPage = () => {
    let {vodId} = useParams();
    const navigate = useNavigate();
    const bookingStoreAction = useStoreActions((actions) => actions.booking);
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const {vod, vodLoading, vodError} = useVodOnce(vodId);
    const {isBusy, setIsBusy} = useBusy();
    const {user} = useCurrentUser();
    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState,
        setValue
    } = useForm<RespondToVodPayload>();

    const onSubmit: SubmitHandler<RespondToVodPayload> = async (payload) => {
        if (!user || !vod) return;
        setIsBusy(true);
        console.log(payload)
        const success = await bookingStoreAction.respondToVodReview({
            ...payload,
            vodId: vod.id,
            coachPointer: {id: user?.uid, name: user?.displayName}
        });
        setIsBusy(false);
        if (!success) return;
        navigate(-1);
    };

    async function onDiscard(): Promise<void> {
        navigate(-1);
    }

    function buildVodInfoTable() {
        return <>
            {vod &&
                <div className="coach-vod-list-item mb-5">
                    <div className="p-4">
                        <Row>
                            <Col md={5} className={"vod-thumbnail"}>
                                <img src="/assets/dashboard/vod-thumbnail.png" width={350} alt=""/>
                            </Col>
                            <Col md={7}>
                                <div
                                    className="vod-title primary-color pb-3">{vod.name}&nbsp;&nbsp; - &nbsp;&nbsp;{VodReviewStatus[vod.vodReviewStatus]}</div>
                                <div className="vod-description pb-3">{vod.description}</div>
                                {vod.focus && <div className="vod-type pb-2">
                                    <MdNote className={'me-3'} size={20}/>{vod.focus}
                                </div>}
                                <div className="vod-type pb-2">
                                    <MdPlayArrow className={'me-3'} size={20}/>{VodTypeExt.toDisplayLabel(vod.vodType)}
                                </div>
                                <div className="vod-coach pb-2">
                                    <MdPerson className={'me-3'} size={20}/>{vod.coachPointer.name}
                                </div>
                                <div className="vod-link"><MdLink className={'me-3'} size={20}/>
                                    <a target={"_blank"} href={vod.vodUrl}>{vod.vodUrl}</a>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>}
        </>;
    }

    if (!vodLoading && vodError) return <p>{vodError.message}</p>
    return (<div className={"page respond-to-vod-page"}>
            <BusyOverlay isBusy={isBusy}>
                <Container>
                    <div className="dashboard-box-outline">
                        <div className="p-5">
                            <h1 className={"primary-color text-center"}>Respond to VOD</h1>
                            <p className={"text-center pb-5"}>This section will let you modify your charging packages
                                for coaching.</p>
                            <div>
                                {buildVodInfoTable()}
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{textAlign: "left", width: "100%"}}>
                                            VOD review remarks for the student:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            style={{height: 300}}
                                            placeholder="Enter your remarks"
                                            {...register("messageToStudent", {
                                                required: "Field is required",
                                            })}
                                        />
                                        <FieldError message={formState.errors.messageToStudent?.message}/>
                                    </Form.Group>
                                    <div className={"respond-to-vod-button-row mt-5"}>
                                        <Stack direction={"horizontal"} gap={2} className={'mt-4'}>
                                            <Button variant="primary" type="submit"
                                                    className={"dashboard-primary-btn w-100"}>
                                                Save
                                            </Button>
                                            <Button variant="outline-primary"
                                                    className={"dashboard-primary-outline-btn w-100"}
                                                    onClick={() => onDiscard()}>
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>

                </Container>
            </BusyOverlay>
        </div>
    );
}
