import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {ModelPointer} from "../interfaces/model-pointer";
import {CalendarEvent, CalendarEventExt} from "../definitions/calendar-event";
import {BookingMode} from "../definitions/booking-mode";
import {BookingStatus} from "../definitions/booking-status";
import {SessionParticipant} from "../definitions/session-participant";
import {Dict} from "../interfaces/dict";
import {PricingMode} from "../definitions/pricing-mode";

export interface CalendarEventSchema {
    end: Timestamp;
    start: Timestamp;
    title: string;
    id: string;
}

export interface BookingSchema {
    id: string;
    created: Timestamp;
    modified: Timestamp;
    gameId: string;
    studentPointer: ModelPointer;
    coachPointer: ModelPointer;
    booking?: CalendarEvent;
    bookingMode: BookingMode;
    bookingStatus: BookingStatus;
    coachCompletion: boolean;
    messageToCoach?: string;
    proceedComment?: string;
}

export interface CreateBookingRequestPayload extends Dict {
    booking: CalendarEvent | null;
    gameId: string;
    studentPointer: ModelPointer;
    coachPointer: ModelPointer;
    bookingMode: BookingMode;
    hours?: number;
    gameCount?: number;
    rank: string;
    messageToCoach?: string;
    pricingMode: PricingMode;
}

export interface RespondToVodPayload extends Dict {
    messageToStudent: string;
    vodId: string;
    coachPointer: ModelPointer;
}

export interface SetCoachAvailabilityPayload {
    availabilityRanges: [number, number][];
    timezone?: string;
    coachId: string;
}

export interface ProceedBookingPayload {
    bookingId: string;
    accept: boolean;
    proceedComment?: string;
}

export interface CompleteBookingPayload {
    bookingId: string;
    sessionParticipant: SessionParticipant;
    // in case we need a dispute system later
    isComplete: boolean;
}

export const bookingConverter: FirestoreDataConverter<BookingSchema> = {
    toFirestore(booking: WithFieldValue<BookingSchema>): DocumentData {
        return booking;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): BookingSchema {
        const booking: CalendarEventSchema | undefined | null = snapshot.get('booking');
        return {
            id: snapshot.id,
            ...snapshot.data(options),
            coachCompletion: snapshot.get('coachCompletion') ?? false,
            booking: booking ? CalendarEventExt.fromEventSchema(booking) : undefined,
        } as BookingSchema;
    },
};
