import "./CoachCard.scss"
import {StarFill} from "react-bootstrap-icons";
import React, {FC} from "react";
import {UserSchema, UserSchemaExt} from "../schemas/user-schema";
import {GameSchema} from "../schemas/game-schema";
import {routes} from "../App";
import {useNavigate} from "react-router-dom";
import _ from "lodash";

export const CoachCard: FC<{ userData: UserSchema, gameData: GameSchema | null }> = function (props) {
    const navigate = useNavigate();

    async function onOpenCoach(): Promise<void> {
        navigate(routes.coachProfile.replace(':username', props.userData.permalink!));
    }

    return (
        <div className={"all-coaches-page"}>
            <div className="coach-card">
                <div className="coach-card-outline">
                    <div className="coach-card-profile-click"
                         onClick={() => onOpenCoach()}></div>
                    <div className="coach-card-img"><img
                        src={props.userData.profileImage ?? props.gameData?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                        alt=""/></div>
                    <div className="game-name">{props.gameData?.name?.toUpperCase() ?? 'UNKNOWN GAME'}</div>

                    <div className={"coach-card-content"}>
                        <div className="coach-name-main">
                            <div className="coach-name">{props.userData.displayName}</div>
                            <div className="coach-language">
                                <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                    {UserSchemaExt.languageInfo(props.userData)?.map(language => <div
                                        key={language.id}
                                        className="language-img px-1"><img
                                        src={language.flagUrl}
                                        width={25} alt=""/></div>)}
                                </div>
                            </div>
                        </div>

                        <div className="coach-rating py-2"><StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/></div>

                        <div className="coach-details ">
                            <div className="coach-point">
                                {props.userData.mainPoints?.length && <div className="coach-detail-row">
                                    <ul>
                                        {props.userData.mainPoints?.map((point) => <li className={"pb-2"}
                                                                                       key={point.id}>{_.truncate(point.label, {length: 30})}</li>)}
                                    </ul>
                                </div>}
                                {!props.userData.mainPoints?.length && <div className="coach-detail-row">
                                    <div>{props.userData.bio || 'No bio provided'}</div>
                                </div>}
                            </div>

                        </div>

                        <div className="coach-card-btn-main pt-3">
                            <div className="coach-card-btn clickable"
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     return navigate(routes.bookSession.replace(':username', props.userData.uid ?? ''));
                                 }}>Book
                                Now
                            </div>
                            <div className={"coach-price"}>{UserSchemaExt.hourlyRateString(props.userData)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
