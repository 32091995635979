import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import './HomePage.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Community} from "../../components/Community";
import {routes} from "../../App";
import {useNavigate} from "react-router-dom";
import {useGames} from "../../hooks/use-games";
import {CoachCard} from "../../components/CoachCard";
import {useFeaturedCoaches} from "../../hooks/use-coaches";
import {GameUtils} from "../../utils/game-utils";

export const HomePage = () => {
    const navigate = useNavigate();
    const {allGames} = useGames();
    const {allFeaturedCoaches} = useFeaturedCoaches();
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 3
        },
        desktop: {
            breakpoint: {max: 3000, min: 1200},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1200, min: 992},
            items: 2
        },
        mobile: {
            breakpoint: {max: 992, min: 0},
            items: 1
        }
    }

    return (

        <div className={'page app-home-page'}>
            <div className="hero-section">
                <Container className={"pb-5"}>
                    <div className="header-text my-5">
                        <h1 className={"primary-color"}>FPS Lounge</h1>
                        <h3>unlock your potential</h3>
                    </div>

                    <div className={"ViewCoaches py-5"}>
                        <Container>
                            <h1 className={"text-center"}><span
                                className="primary-color">Train With the Very Best</span><br/>in &lt; 30 Seconds
                            </h1>
                            <div className="py-5 coach-card-carousel">
                                {allFeaturedCoaches && !!allFeaturedCoaches.length && <Carousel responsive={responsive}
                                                                                                swipeable={false}
                                                                                                draggable={false}
                                                                                                showDots={true}
                                                                                                infinite={true}>
                                    {allFeaturedCoaches?.map(coach => {
                                        const gameData = GameUtils.getGameDataById(allGames, coach.coachingGame);
                                        return <CoachCard key={coach.uid} userData={coach} gameData={gameData}/>;
                                    })}
                                </Carousel>}
                            </div>

                            <div className="view-coaches-btn-main pb-5"><a className={'clickable'}
                                                                           onClick={() => navigate(routes.coaches)}>
                                <div className="view-coaches-btn">View All Coaches</div>
                            </a></div>

                        </Container>
                    </div>


                    <img src="/assets/Logo/Glow_LogoOnly_Transparent_400x400%203.png"
                         className={"img-fluid floating-big-logo"} alt=""/>
                </Container>
            </div>

            <Container>
                <div className="all-games-section">
                    <div className="header-text-2 my-5">
                        <div><h3>Select your game to start winning today!</h3></div>
                        <div className={"logo-text"}><img src="/assets/Logo/logo-text.png" width={200} alt=""/></div>

                    </div>

                    <div className="game-btn-main my-5 py-5">
                        {allGames?.map(game => <div key={game.id} className={"pb-5 px-3"}>
                            <Button
                                onClick={() => navigate(routes.coachesWithFilter.replace(':gameFilter', game.name))}>{game.name.toUpperCase()}</Button>
                        </div>)}
                    </div>
                </div>
            </Container>


            <div className="pt-5 JoinFPS-main">
                <Container className={"pt-5"}>
                    <div className="joinFps-first-section">
                        <Row className={"pb-5 pb-md-0"}>
                            <Col md={7}>
                                <div className={"register-section-content"}>
                                    <h1>Learn from the very best.<br/><span
                                        className={"primary-color"}>Become the very best.</span></h1>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="joinFPS-digits pb-5">
                                    <div>
                                        <div className="primary-color digit-item">100+</div>
                                        <div className={"digit-item-label"}>Coaches</div>
                                    </div>
                                    <div>
                                        <div className="primary-color digit-item">$16</div>
                                        <div className={"digit-item-label"}>Starting at</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Container>


                <div className="register-section-main">
                    <div className={"registration-section-bg-pattern"}>
                        <div className={"bg-pattern-1"}><img src="/assets/Homepage/bg-pattern-1.png"
                                                             className={"img-fluid"} alt=""/></div>
                        <div className={"bg-pattern-2"}><img src="/assets/Homepage/bg-pattern-2.png"
                                                             className={"img-fluid"} alt=""/></div>
                    </div>

                    <Container>
                        <div className="register-section pb-5">
                            <Row className={"register-section-content-main"}>
                                <Col md={5}>
                                    <div className={"register-section-content"}>
                                        <h1>JOIN <span
                                            className={"primary-color"}>FPS LOUNGE</span></h1>
                                        <div className="FPSLounge-subheading">Game with the Top Players In the
                                            World
                                        </div>

                                        <div className="pt-5 secondary-color">Starting at $16/hr</div>
                                        <div className={"pt-3 register-now-btn-main"}><a
                                            href={routes.register}>
                                            <div className="register-now-btn">Register Now!</div>
                                        </a></div>
                                    </div>
                                </Col>
                                <Col md={7} className={"register-section-img"}>
                                    <img src="/assets/Homepage/Start-Winning-Today-Banner2.png"
                                         className={"img-fluid"} alt=""/>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>


            <div className="py-5 infoSection-one">
                <Container>
                    <div className={"info-section-one-content-desktop"}>
                        <h1 className="FPSLounge-heading">TRAINING THAT SCALES TO <span
                            className="primary-color"> GET YOU TO THE NEXT LEVEL</span>

                            <div className="info-section-one-description">Believe in yourself and believe in the
                                process. Learn what it takes to truly compete
                                through custom curated programs created by the elite.
                            </div>
                        </h1>

                        <Row>
                            <Col md={6}></Col>
                            <Col md={6}>
                                <div className="infoSectionOne-img"><img src="/assets/Homepage/info1.png"
                                                                         className={"img-fluid float-md-end"}
                                                                         alt=""/></div>
                            </Col>
                        </Row>

                    </div>

                    <div className="info-section-one-content-mobile">
                        <h1 className="FPSLounge-heading text-center">TRAINING THAT SCALES TO <span
                            className="primary-color"> GET YOU TO THE NEXT LEVEL</span>

                            <div className="info-section-one-description text-center pt-5">Believe in yourself and
                                believe in the process. Learn what it takes to truly compete
                                through custom curated programs created by the elite.
                            </div>
                        </h1>
                        <div className="infoSectionOne-img"><img src="/assets/Homepage/info1.png"
                                                                 className={"img-fluid"}
                                                                 alt=""/></div>

                    </div>

                </Container>
            </div>


            <div className="py-5 infoSection-two">
                <Container>

                    <div className={"info-section-two-content"}>
                        <h1 className="FPSLounge-heading"><span className="primary-color">SURPASS YOUR LIMITS</span>

                            <div className="info-section-two-description float-end"> Learn from your past mistakes and
                                turn them into future limit breaks. Coach-guided VOD
                                and replay review is the foundation of your personal evolution.
                            </div>
                        </h1>
                        <Row>
                            <Col md={6}>
                                <div className="infoSectionOne-img"><img src="/assets/Homepage/info2.png"
                                                                         className={"img-fluid"} alt=""/></div>
                            </Col>
                            <Col md={6}></Col>
                        </Row>
                    </div>

                    <div className="info-section-two-content-mobile">
                        <h1 className="FPSLounge-heading text-center"><span
                            className="primary-color">SURPASS YOUR LIMITS</span>

                            <div className="info-section-two-description text-center pt-5"> Learn from your past
                                mistakes and turn them into future limit breaks. Coach-guided VOD
                                and replay review is the foundation of your personal evolution.
                            </div>
                        </h1>
                        <div className="infoSectionTwo-img"><img src="/assets/Homepage/info2.png"
                                                                 className={"img-fluid"}
                                                                 alt=""/></div>

                    </div>

                </Container>
            </div>

            <Community/>


        </div>
    );
}
