import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";

export interface NotificationSchema {
    id: string;
    created: Timestamp;
    title: string;
    body: string;
    type: string;
}

export const notificationConverter: FirestoreDataConverter<NotificationSchema> = {
    toFirestore(notification: WithFieldValue<NotificationSchema>): DocumentData {
        return notification;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): NotificationSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as NotificationSchema;
    },
};
