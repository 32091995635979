import {db} from "../config/firebase-config";
import {useDocumentData, useDocumentDataOnce} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError} from "@firebase/firestore";
import {gameConverter, GameSchema} from "../schemas/game-schema";

export function useGame(gameId: string | undefined): { game?: GameSchema; gameError?: FirestoreError | undefined; gameLoading: boolean } {
    const gameCollectionRef = collection(db, 'games').withConverter(gameConverter);
    const [game, gameLoading, gameError] = useDocumentData<GameSchema>(gameId ? doc(gameCollectionRef, gameId) : null);
    return {
        game,
        gameError,
        gameLoading,
    };
}

export function useGameOnce(gameId: string | undefined): { game?: GameSchema; gameError?: FirestoreError | undefined; gameLoading: boolean } {
    const gameCollectionRef = collection(db, 'games').withConverter(gameConverter);
    const [game, gameLoading, gameError] = useDocumentDataOnce<GameSchema>(gameId ? doc(gameCollectionRef, gameId) : null);
    return {
        game,
        gameError,
        gameLoading,
    };
}
