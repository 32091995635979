import React, {FC} from "react";
import {Button, Container, Form} from "react-bootstrap";
import {RegisterPayload} from "../../schemas/user-schema";
import "./RegisterPage.scss"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import {FieldError} from "../../components/form/FieldError";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";
import {routes} from "../../App";
import {SocialLoginBar} from "../../components/SocialLoginBar";

export const RegisterPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const {register, handleSubmit, formState: {errors}} = useForm<RegisterPayload>();
    const {createUser, signUserWithGoogle, signUserWithTwitch} = useStoreActions((actions) => actions.users);
    const {isBusy, setIsBusy} = useBusy();
    const uiStoreActions = useStoreActions(state => state.ui);

    const onRegisterSubmit = async (registerPayload: RegisterPayload) => {
        setIsBusy(true);
        let response = await createUser(registerPayload);
        console.log('Register result is:', response)
        setIsBusy(false);
        if (!response.success) {
            await uiStoreActions.addMessageAlert({
                title: 'Signing Up Failed',
                subtitle: response.message,
            })
            return;
        }
        navigate(searchParams.get('redirect') ?? routes.root)
    };

    function onLogin() {
        navigate({
            pathname: routes.login,
            search: location.search,
        });
    }

    function getPageTitle() {
        return searchParams.get('redirect') ? 'Register as a coach' : 'Register'
    }

    return (
        <div className={'page register-page'}>
            <BusyOverlay isBusy={isBusy}>
                <div className="py-md-5 register">
                    <Container className="pt-5 pb-md-5">
                        <div className="register-outline">
                            <div className="register-img">
                                <img src="/assets/register/raze.png" alt=""/>
                            </div>
                            <div className="register-content">
                                <div className="register-heading primary-color pt-4">{getPageTitle()}</div>
                                <div className={"create-account"}>Already have an account?
                                    <a className={'clickable ms-2'} onClick={() => onLogin()}>Login</a>
                                </div>

                                <SocialLoginBar setIsBusy={setIsBusy}/>

                                <div className="section-breaker-main">
                                    <div className="section-breaker"></div>
                                    <div>or</div>
                                    <div className="section-breaker"></div>
                                </div>

                                <div className="register-fields-main pt-3">
                                    <Form onSubmit={handleSubmit(onRegisterSubmit)}>
                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                placeholder="Full Name (Private)"
                                                {...register('fullName', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.fullName?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                placeholder="Gamer Tag / Display Name"
                                                {...register('name', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.name?.message}/>
                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                placeholder="Discord Handle"
                                                {...register('discord', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.discord?.message}/>
                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                {...register('email', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.email?.message}/>
                                        </Form.Group>

                                        <Form.Group className="mb-4" controlId="formBasicPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                {...register('password', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.password?.message}/>
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formBasicPasswordConfirm">
                                            <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                {...register('confirmPassword', {
                                                    required: 'Field is required'
                                                })}
                                            />
                                            <FieldError message={errors.confirmPassword?.message}/>
                                        </Form.Group>
                                        <div className="agree-for-condition">
                                            <div>
                                                <Form.Check
                                                    id={`agreeForConditions`}
                                                />
                                            </div>
                                            <div className={"mx-3 agree-for-condition-text mb-4"}>
                                                I agree to the FPS <a href={routes.termsCondition}>Terms
                                                of Service</a> and <a href={routes.privacyPolicy}>Privacy
                                                Policy</a>
                                            </div>
                                        </div>
                                        <div className="register-btn-main-section pt-4 pb-5">
                                            <div className="forgot-password"><a
                                                className={'clickable'}
                                                onClick={() => navigate(routes.resetPassword)}
                                            >
                                                Forgot your password?</a></div>
                                            <div className={"register-btn-main"}>
                                                <Button variant={"dark"} type={"submit"} className="register-btn">Sign
                                                    Up</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </BusyOverlay>
        </div>);
};
