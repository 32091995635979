import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, ProgressBar, Stack} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {EditCoachProfilePayload} from "../../schemas/game-schema";
import {useNavigate} from "react-router-dom";
import {FieldError} from "../../components/form/FieldError";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import './EditCoachProfilePage.scss';
import Select from "react-select";
import {UserLanguage} from "../../interfaces/user-language";
import _ from "lodash";
import {DataPointer} from "../../interfaces/data-pointer";
import {useUploadProfilePic} from "../../hooks/use-upload-profile-pic";
import {toast} from "react-toastify";
import {DataConfig} from "../../config/data-config";

export function EditCoachProfilePage() {
    const usersStoreActions = useStoreActions(state => state.users);
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();
    const [isFormInit, setIsFormInit] = useState(false);
    const [selectedPlatforms, setSelectedPlatforms] = useState<DataPointer[] | null>(null);
    const [selectedInputMethods, setSelectedInputMethods] = useState<DataPointer[] | null>(null);
    const [selectedLanguages, setSelectedLanguages] = useState<UserLanguage[] | null>(null);

    const {
        upload,
        isUploading,
        uploadingError,
        uploadingSnapshot,
        publicUrl,
        progressPercent,
    } = useUploadProfilePic();

    useEffect(() => {
        setValue('profileImage', publicUrl);
    }, [publicUrl])

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue,
    } = useForm<EditCoachProfilePayload>();

    useEffect(() => {
        if (!user || isFormInit) return;
        setValue('gamerTag', user.gamerTag ?? '');
        setValue('discord', user.discord ?? '');
        setValue('permalink', user.permalink ?? '');
        setValue('vodBio', user.vodBio ?? '');
        setValue('bio', user.bio ?? '');
        setValue('kdRatio', user.kdRatio);
        setValue('winRate', user.winRate);
        setValue('totalKills', user.totalKills);
        setValue('totalDeaths', user.totalDeaths);
        setValue('profileImage', user.profileImage);
        setValue('youtube', user.youtube ?? '');
        setValue('twitchUsername', user.twitchUsername ?? '');
        setValue('twitterUsername', user.twitterUsername ?? '');
        setValue('discord', user.discord ?? '');
        console.log(DataConfig.supportedLanguages.filter(language => user.languages?.includes(language.id)))
        setSelectedLanguages(DataConfig.supportedLanguages.filter(language => user.languages?.includes(language.id)))
        setSelectedInputMethods(DataConfig.supportedInputMethods.filter(inputMethod => user.inputMethods?.includes(inputMethod.id)))
        setSelectedPlatforms(DataConfig.supportedPlatforms.filter(platform => user.platforms?.includes(platform.id)))
        setIsFormInit(true);
    }, [user])


    const onSubmit: SubmitHandler<EditCoachProfilePayload> = async (payload) => {
        console.log(payload)
        if (!user?.uid) return;
        if (_.isEmpty(selectedLanguages)) {
            toast('Please select at least one language');
            return;
        }
        if (_.isEmpty(selectedPlatforms)) {
            toast('Please select at least one platform');
            return;
        }
        if (_.isEmpty(selectedInputMethods)) {
            toast('Please select at least one input method');
            return;
        }
        if (isUploading) {
            toast('Please wait until the profile image is uploaded.');
            return;
        }

        const success = await usersStoreActions.editCoachProfile({
            ...payload,
            updateId: user.uid,
            languages: selectedLanguages?.map(language => language.id) ?? [],
            platforms: selectedPlatforms?.map(platform => platform.id) ?? [],
            inputMethods: selectedInputMethods?.map(inputMethod => inputMethod.id) ?? [],
        });
        if (!success) return;
        navigate(-1);
    };

    function onDiscard() {
        navigate(-1);
    }

    return (<Container className={'page edit-coach-profile-page'}>
            <div className={"dashboard-box-outline"}>
                <div className="p-5">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Col>
                            <h1 className={"primary-color text-center"}>Edit My Profile</h1>
                            <p className={"text-center pb-5"}>This section will let you edit your profile.</p>
                            <Col>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Profile Image</Form.Label>
                                    {uploadingError && <strong>Error: {uploadingError.message}</strong>}
                                    {isUploading && <ProgressBar className={'my-5'} now={progressPercent ?? 0}/>}
                                    <input
                                        type="file"
                                        onChange={async (e) => {
                                            const file = e.target.files ? e.target.files[0] : undefined;
                                            if (file) await upload(file);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Select Languages
                                    </Form.Label>
                                    <Select
                                        className={'react-select-dropdown'}
                                        classNamePrefix={"react-select-dropdown-options"}
                                        options={DataConfig.supportedLanguages}
                                        value={selectedLanguages}
                                        getOptionValue={option => option.id}
                                        onChange={(items) => setSelectedLanguages([...items])}
                                        isMulti={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Select Platforms
                                    </Form.Label>
                                    <Select
                                        className={'react-select-dropdown'}
                                        classNamePrefix={"react-select-dropdown-options"}
                                        options={DataConfig.supportedPlatforms}
                                        value={selectedPlatforms}
                                        getOptionValue={option => option.id}
                                        onChange={(items) => setSelectedPlatforms([...items])}
                                        isMulti={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Select Input Methods
                                    </Form.Label>
                                    <Select
                                        className={'react-select-dropdown'}
                                        classNamePrefix={"react-select-dropdown-options"}
                                        options={DataConfig.supportedInputMethods}
                                        value={selectedInputMethods}
                                        getOptionValue={option => option.id}
                                        onChange={(items) => setSelectedInputMethods([...items])}
                                        isMulti={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Discord Username
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="Discord Username"
                                        {...register('discord', {
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.discord?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Game Tag
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="Gamer Tag / Display Name"
                                        {...register('gamerTag', {
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.gamerTag?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Permalink
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="Permalink"
                                        {...register('permalink', {
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.permalink?.message}/>
                                </Form.Group>
                                <h4 className={'my-5'}>Gamer Statistics</h4>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        K/D Ratio
                                    </Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder="K/D Ratio"
                                        step={0.0000000000001}
                                        {...register('kdRatio', {})}
                                    />
                                    <FieldError message={errors.kdRatio?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Win Rate
                                    </Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder="Win Rate"
                                        step={0.0000000000001}
                                        {...register('winRate', {})}
                                    />
                                    <FieldError message={errors.winRate?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Total Kills
                                    </Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder="Total Kills"
                                        {...register('totalKills', {})}
                                    />
                                    <FieldError message={errors.totalKills?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Total Deaths
                                    </Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder="Total Deaths"
                                        {...register('totalDeaths', {})}
                                    />
                                    <FieldError message={errors.totalDeaths?.message}/>
                                </Form.Group>
                                <h4 className={'my-5'}>Bio</h4>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Profile Bio
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        style={{height: '6em'}}
                                        placeholder="Your Profile Bio"
                                        {...register("bio", {
                                            maxLength: 500,
                                        })}
                                    />
                                    <FieldError message={errors.bio?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        VOD Review Bio
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        style={{height: '6em'}}
                                        placeholder="Your VOD Review Bio"
                                        {...register("vodBio", {
                                            maxLength: 500,
                                        })}
                                    />
                                    <FieldError message={errors.vodBio?.message}/>
                                </Form.Group>
                                <h4 className={'my-5'}>Social Profiles</h4>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Youtube Channel</Form.Label>
                                    <Form.Control
                                        placeholder="Youtube Channel"
                                        {...register('youtube', {})}
                                    />
                                    <FieldError message={errors.youtube?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Twitter Profile
                                        Link</Form.Label>
                                    <Form.Control
                                        placeholder="Twitter Link"
                                        {...register('twitterUsername', {})}
                                    />
                                    <FieldError message={errors.twitterUsername?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>Twitch Profile
                                        Link</Form.Label>
                                    <Form.Control
                                        placeholder="Twitch Profile Link"
                                        {...register('twitchUsername', {})}
                                    />
                                    <FieldError message={errors.twitchUsername?.message}/>
                                </Form.Group>
                            </Col>
                            <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
                                <Button variant="primary" type="submit" className={"dashboard-primary-btn w-100"}>
                                    Save
                                </Button>
                                <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                                        onClick={() => onDiscard()}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Col>
                    </Form>
                </div>
            </div>
        </Container>

    );
}
