import {Col, Row} from "react-bootstrap";
import React, {FC} from "react";
import {VodSchema} from "../schemas/vod-schema";
import "./StudentVodListItem.scss"
import {VodTypeExt} from "../definitions/vod-type";
import {VodReviewStatus} from "../definitions/vod-review-status";
import {Clock, Link45deg, PersonWorkspace, Play} from "react-bootstrap-icons";
import {useStoreActions} from "../models";
import {VodStudentDisplayPopup} from "./alerts/VodStudentDisplayPopup";
import {TimeUtils} from "../utils/time-utils";

export const StudentVodListItem: FC<{ vodData: VodSchema }> = function (props) {
    const uiStoreActions = useStoreActions(store => store.ui);

    async function onVodOpen(): Promise<void> {
        await uiStoreActions.addCustomAlert({
            customClass: 'student-vod-alert',
            title: `${props.vodData.name}`,
            closeOnClickOutside: true,
            builder: (alertProps) => {
                return <VodStudentDisplayPopup {...alertProps} vodData={props.vodData}/>;
            }
        });
    }

    return (<div className={"student-vod-list-item mb-4 clickable"} key={props.vodData.id} onClick={() => onVodOpen()}>
            <div className="p-4">
                <Row>
                    <Col xl={5} className={"vod-thumbnail"}>
                        <img src="/assets/dashboard/vod-thumbnail.png" alt="" className={"pb-4 pb-xl-0"}/>
                    </Col>
                    <Col xl={7}>
                        <div
                            className="vod-title primary-color">{props.vodData.name}&nbsp;&nbsp; - &nbsp;&nbsp;{VodReviewStatus[props.vodData.vodReviewStatus]}</div>
                        <div className="vod-created pb-3">Submitted
                            on {TimeUtils.timestampToLocaleString(props.vodData.created)}</div>
                        <div className="vod-description pb-3">{props.vodData.description}</div>
                        <div className="vod-description pb-3"><Clock
                            size={20}/>&nbsp;&nbsp; {TimeUtils.hoursToHumanizedString(props.vodData.hours)}
                        </div>
                        <div className="vod-type pb-2"><Play
                            size={20}/>&nbsp;&nbsp; {VodTypeExt.toDisplayLabel(props.vodData.vodType)}</div>
                        <div className="vod-coach pb-2"><PersonWorkspace
                            size={20}/>&nbsp;&nbsp; {props.vodData.coachPointer.name}</div>
                        <div className="vod-status pb-2"></div>
                        <div className="vod-link"><Link45deg size={20}/>&nbsp;&nbsp; <a target={"_blank"}
                                                                                        href={props.vodData.vodUrl}>{props.vodData.vodUrl}</a>
                        </div>
                        <div className="vod-remark pb-3">{props.vodData.remarks}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
