import React, {FC, useEffect, useMemo} from 'react';
import {Button, Col, Form, Image, Row, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import {PaymentMethod} from "../../definitions/payment-method";
import _ from "lodash";
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {useStoreActions} from "../../models";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useSystemConfig} from "../../hooks/use-system-config";
import {CreateOrderActions, CreateOrderData} from "@paypal/paypal-js";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../BusyOverlay";
import './PaymentPopup.scss';
import {useForm} from "react-hook-form";
import {AppConfig} from "../../config/app-config";
import {toast} from "react-toastify";
import {EconomyConfig} from "../../config/economy-config";

export interface PaymentPopupResult {
    paymentMethod: PaymentMethod;
    amount: number;
}

export const PaymentPopup: FC<AlertRenderProps<PaymentPopupResult>> = (props) => {
    const [{options, isPending}, dispatch] = usePayPalScriptReducer();
    const {register, formState: {errors}, getValues, watch} = useForm<{ amount: number }>();
    const {isBusy, setIsBusy} = useBusy();

    const rawAmount = watch('amount')
    const amountWithFee = useMemo(() => rawAmount * (1 + EconomyConfig.houseTxFeePercentNormalized), [rawAmount]);
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: AppConfig.currency,
            },
        });
    }, [rawAmount, AppConfig.currency]);

    useEffect(() => {
        console.log('RAW AMOUNT CHANGED TO:', rawAmount)
    }, [rawAmount]);

    const {createPaypalFundingOrder} = useStoreActions(actions => actions.economy);
    const {systemConfig} = useSystemConfig();
    const {user} = useCurrentUser();

    return (
        <BusyOverlay isBusy={isBusy}>
            <Stack style={{textAlign: "start"}} className={'px-3'}>
                <Stack>
                    <Form>
                        <div className={'px-4'}>
                            <Form.Group className="mb-4">
                                <Form.Label style={{textAlign: "left", width: "100%"}}>Purchase Amount</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    placeholder="Purchase Amount"
                                    step={0.01}
                                    {...register('amount', {
                                        valueAsNumber: true,
                                        required: 'Amount is required',
                                    })}
                                />
                            </Form.Group>
                            <p>3.5% Processing fee will be added to the total amount.</p>
                        </div>
                    </Form>
                    <Row>
                        <Col md={6} className={'text-align-center'}>
                            <Image src={require('../../assets/payment-logos/stripe-img.png')} height={'60px'}
                                   className={'my-4'}/>
                            <Button disabled={_.isNaN(amountWithFee) || !amountWithFee || amountWithFee <= 0}
                                    onClick={() => {
                                        return props.confirm({
                                            amount: amountWithFee,
                                            paymentMethod: PaymentMethod.Stripe,
                                        });
                                    }} className={"payment-button stripe"}>
                                Pay by Credit Card
                            </Button>
                        </Col>
                        <Col md={6} className={'text-align-center'}>
                            <Image src={require('../../assets/payment-logos/paypal-logo.png')} height={'60px'}
                                   className={'my-4'}/>
                            <PayPalButtons
                                style={{"layout": "vertical"}}
                                disabled={!amountWithFee || amountWithFee <= 0}
                                fundingSource={"paypal"}
                                createOrder={async (data: CreateOrderData, actions: CreateOrderActions) => {
                                    if (!user || !systemConfig?.products?.creditPrice) return '';
                                    if (_.isNaN(amountWithFee) || !amountWithFee || amountWithFee <= 0) return '';
                                    setIsBusy(true);
                                    const response = await createPaypalFundingOrder({
                                        actions,
                                        data,
                                        uid: user.uid,
                                        price: systemConfig.products.creditPrice,
                                        quantity: _.round(amountWithFee, 2),
                                    });
                                    console.log('createOrder response', response)
                                    return response.data ?? '';
                                }}

                                onCancel={() => {
                                    setIsBusy(false);
                                }}
                                onApprove={async (data, actions) => {
                                    console.log('orderApproveData', data)
                                    actions.order?.capture().then(function (orderResponse) {
                                        console.log('orderResponse', orderResponse)
                                        setIsBusy(false);
                                        props.cancel();
                                        toast('Payment received, your funds will arrive in 5-10 minutes.');
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <div style={{textAlign: "end"}}>
                        <Button className={"payment-secondary-btn"} variant={"outline-primary mt-5 my-1"}
                                onClick={() => props.cancel()}>Cancel</Button>
                    </div>
                </Stack>
            </Stack>

        </BusyOverlay>
    );
};
