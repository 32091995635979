import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {ModelPointer} from "../interfaces/model-pointer";
import {VodType} from "../definitions/vod-type";
import {VodReviewStatus} from "../definitions/vod-review-status";

export interface VodSchema {
    id: string;
    created: Timestamp;
    modified: Timestamp;
    gameId: string;
    studentPointer: ModelPointer;
    coachPointer: ModelPointer;
    vodReviewStatus: VodReviewStatus;
    name: string;
    description: string | null;
    focus: string | null;
    vodUrl: string;
    vodType: VodType;
    remarks: string | null;
    hours: number;
}

export abstract class VodSchemaExt {
    public static isPending(vodData: VodSchema): boolean {
        return vodData.vodReviewStatus === VodReviewStatus.Pending;
    }
}

export const vodConverter: FirestoreDataConverter<VodSchema> = {
    toFirestore(vod: WithFieldValue<VodSchema>): DocumentData {
        return vod;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): VodSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
            vodType: parseInt(snapshot.get('vodType') ?? '0')
        } as VodSchema;
    },
};
