import React, {useEffect, useState} from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {BookingCalendar} from "../../components/calendar/BookingCalendar";
import {CalendarEvent} from "../../definitions/calendar-event";
import {useBookingsOfStudent} from "../../hooks/use-bookings";
import {UserSchemaExt} from "../../schemas/user-schema";

export function StudentCalendarTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const [myEvents, setEvents] = useState<CalendarEvent[]>([])
    const {allBookings} = useBookingsOfStudent(user?.uid);
    useEffect(() => {
        const allEvents = allBookings?.filter(bookingData => !!bookingData.booking).map(bookingData => bookingData.booking!) ?? [];
        setEvents(allEvents)
    }, [allBookings])

    return <div>
        <h3 className={"primary-color"}>My Calendar</h3>
        <p>This section will show all your past and upcoming sessions.</p>
        {user?.timezone &&
            <BookingCalendar timezone={UserSchemaExt.getTimezone(user)}
                             events={myEvents}/>}
    </div>;
}
