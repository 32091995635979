import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {CoachBookingListItem} from "../../components/CoachBookingListItem";
import {useBookingsOfCoach} from "../../hooks/use-bookings";

export function CoachBookingsTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allBookings} = useBookingsOfCoach(user?.uid);

    return (<div>
        <h3 className={"primary-color"}>My Bookings</h3>
        <p>Accept or decline your current booking requests.</p>
        {userLoading || allBookings?.map(booking =>
            <CoachBookingListItem key={booking.id} hasActions={true} bookingData={booking}/>)}
    </div>);
}
