import React from "react";
import {Button} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useStoreActions} from "../../models";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import {AppConfig} from "../../config/app-config";
import {useAddFunds} from "../../hooks/use-add-funds";

export function StudentWalletTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const economyStoreActions = useStoreActions(state => state.economy);
    const uiStoreActions = useStoreActions(state => state.ui);
    const {isBusy, setIsBusy} = useBusy();
    const {addFunds, isBusyAddFunds} = useAddFunds();

    async function onRequestFromFaucet(): Promise<void> {
        if (!user?.uid) return;
        const should = await uiStoreActions.addConfirmationAlert({
            message: 'Do you want to request 100 USD from the faucet for testing!'
        });
        if (!should) return;
        setIsBusy(true);
        const success = await economyStoreActions.requestCreditFromFaucet({
            amount: 100,
            uid: user.uid,
        });
        setIsBusy(false);
        if (success) {
            await uiStoreActions.addMessageAlert({
                title: 'Success',
                subtitle: 'You received 100 USD from the faucet for testing!'
            });
        }
    }

    return <>
        <div className="student-wallet-tab">
            <BusyOverlay isBusy={isBusy || isBusyAddFunds}>
                <h3 className={"primary-color"}>My Wallet</h3>
                <p>Below are all your wallet & financial details of the account.</p>
                <div className="dashboard-content-outline">
                    <div className="p-4">
                        <div className="my-wallet-row">
                            <div className="my-wallet-title">
                                Balance
                            </div>
                            <div className="my-wallet-info">
                                {user?.balance} USD
                            </div>
                        </div>
                        <div className="my-wallet-row pt-4">
                            <div className="my-wallet-title">Actions</div>
                            {AppConfig.showFaucetButton && <div className={"my-wallet-info"}>
                                <Button className={"dashboard-secondary-btn me-2"}
                                        onClick={() => onRequestFromFaucet()}>Request Free 100 USD
                                </Button>
                            </div>}
                            <div className="my-wallet-info">
                                <Button className={'dashboard-secondary-btn'} onClick={() => addFunds()}>Add Funds</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </BusyOverlay>
        </div>

    </>;
}
