import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {ModelPointer} from "../interfaces/model-pointer";
import {EarningType} from "../definitions/earning-type";

export interface EarningSchema {
    id: string;
    created: Timestamp;
    coachPointer: ModelPointer;
    earningType: EarningType;
    totalAmount: number;
    creditAmount: number;
    houseAmount: number;
}

export const earningConverter: FirestoreDataConverter<EarningSchema> = {
    toFirestore(earning: WithFieldValue<EarningSchema>): DocumentData {
        return earning;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EarningSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as EarningSchema;
    },
};
