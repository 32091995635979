import "./TermsConditionPage.scss"
import {Container} from "react-bootstrap";


export function TermsConditionPage() {
    return (
        <div className={"page terms-condition py-5"}>
            <Container>
                <h1 className="text-center primary-color">FPS Lounge™</h1>
                <h3 className={"primary-color text-center"}>Terms & Condition</h3>

                <div className="dashboard-box-outline mt-5">
                    <div className="p-4 terms-condition-text-main">
                        <h4 className={"secondary-color pb-4"}>Introduction</h4>

                        <p>Welcome to our website (“Site”). This Site is owned and operated by FPS Lounge, Inc. (“FPS
                            Lounge™”). This is a binding contractual agreement between you and FPS Lounge™.</p>
                        <p>The Terms & Conditions (as defined below), and all rules for all sweepstakes, games and other
                            contests are incorporated into one another by reference and are referred to individually and
                            collectively as the “Agreement.” </p>
                        <p>Your visitation, registration for an account, access and/or use of this Site constitutes your
                            acceptance of this Agreement (as defined below). FPS Lounge™ may modify the Agreement at any
                            time without prior notice to you. Such modification shall be effective immediately upon the
                            first of the following events to occur: (1) posting of the modified Agreement to the
                            website, or (2) upon otherwise notifying you of the modified Agreement. You agree to
                            periodically review the terms and conditions set forth in this Agreement to ensure that you
                            are aware of any modifications. </p>
                        <p>You agree to do business with FPS Lounge™ electronically. By agreeing to do business
                            electronically, you (i) consent to the electronic communication and delivery of all
                            documents related to the Site and all other goods and services provided via the Site; and
                            (ii) agree that your act of clicking your consent to this Agreement is a binding form of
                            your electronic signature, which you agree binds you to this Agreement and the Privacy
                            Policy.</p>

                        <p className={"terms-condition-bold pt-3"}>NOTIFICATION OF ARBITRATION CLAUSE AND CLASS
                            ACTION/JURY TRIAL WAIVER</p>
                        <p className={"terms-condition-bold"}>PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU
                            UNDERSTAND EACH PROVISION. THIS
                            AGREEMENT CONTAINS A MANDATORY INDIVIDUAL BINDING ARBITRATION AND CLASS ACTION/JURY TRIAL
                            WAIVER PROVISION, AS SET FORTH BELOW.</p>

                        <h4 className={"pt-5 pb-4 secondary-color"}>Definitions</h4>

                        <p>“Agreement” means these Terms & Conditions and the Privacy Policy.</p>
                        <p>"Personally Identifiable Information" means any information that identifies a natural person,
                            such as name, Social Security Number or other government-issued number, date of birth,
                            address, telephone number, biometric data, and the like.</p>
                        <p>“Person” means a natural person, entity organized under the laws of a governmental authority
                            or unincorporated association or organization, unless otherwise specified.</p>
                        <p>“Site” means any and all pages of the website (individually and collectively) you are viewing
                            at the moment, and also includes any and all websites on which the Agreement is posted.</p>
                        <p>“User” or “you” or "your" refers to any and all natural persons who visit, create an account
                            with, access or use this Site in any way imaginable, whether through existing technology or
                            through technology not heretofore manufactured, devised or discovered, or who request,
                            purchase, subscribe to, access, or otherwise use the goods and services offered on this
                            Site.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Prices and Payment Terms</h4>

                        <p>You agree that the order you place on our Site is an offer to buy, under these Terms, all
                            services listed in your order. All orders must be accepted by us or we will not be obligated
                            to sell the services to you. We may choose not to accept orders at our sole discretion. Your
                            order may be to add funds to your account and you may subsequently use those funds to select
                            the services you would like to purchase (such as gaming packages or coaching).</p>
                        <p>All prices, discounts, and promotions posted on this Site are subject to change without
                            notice. The price charged for a service will be the price in effect at the time the order is
                            placed and will be set out in your order confirmation. Price increases will only apply to
                            orders placed after such changes. Posted prices do not include taxes. All such taxes will be
                            added to your order total, and will be itemized in your shopping cart and in your order
                            confirmation. We make commercially reasonable efforts to display accurate price information,
                            however we may, on occasion, make inadvertent typographical errors, inaccuracies or
                            omissions related to pricing and availability. We reserve the right to correct any errors,
                            inaccuracies, or omissions at any time and to cancel any orders arising from such
                            occurrences.</p>
                        <p>Terms of payment are within our sole discretion and payment must be received by us before our
                            acceptance of an order. We accept most major credit cards and certain cryptocurrencies for
                            purchases. If your payment method is declined, we will attempt to process your charge until
                            the transaction is approved. We and our third-party payment processors may request receive,
                            updated payment method information from your payment method issuer (i.e., credit card
                            company), such as updated card numbers and expiration date information when your credit card
                            has expired. If such updated information is provided to us and our payment processors, we
                            will update your account information accordingly, and will use such updated information to
                            process payments for your future purchases. Your payment method issuer may give you the
                            right to opt out of providing vendors and payment processors with your updated information.
                            If you wish to opt out of your payment method issuer from providing updated information, you
                            should contact your payment method issuer. We are not responsible for any fees or charges
                            that your payment method issuer may apply. You represent and warrant that (i) the payment
                            method information you supply to us is true, correct and complete, (ii) you are duly
                            authorized to use such payment method for the purchase, (iii) charges incurred by you will
                            be honored by the payment method company (e.g., credit card company), and (iv) you will pay
                            charges incurred by you at the posted prices, including all applicable taxes, if any,
                            regardless of the amount quoted on the Site at the time of your order.</p>
                        <p>We use third-party payment processors for invoicing and payment processing of purchases
                            through our Site. Currently we use: (i) Stripe, Inc. and its affiliates, whose terms of
                            service at www.stripe.com/checkout/legal and privacy policy at www.stripe.com/privacy are
                            hereby incorporated by reference; and (ii) Paypal, Inc. and its affiliates. By purchasing
                            services through our Site, you agree to pay, through the payment processor all charges at
                            the prices then in effect for any use of such services in accordance with the applicable
                            payment terms, and you authorize us, through the payment processor, to charge your chosen
                            payment method. We are not responsible for any error by, or other acts of omissions of the
                            payment processor. If you choose to pay using cryptocurrency you are responsible for payment
                            of all fees associated with each such payment, e.g., gas fees on the Ethereum
                            blockchain.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Refunds</h4>

                        <p>By accessing and using the Service, you agree to and accept the standard terms and conditions
                            of any and all payment providers offered by, through or on the Service.</p>
                        <p>All sales and purchases are final and refunds shall not be offered or given. Notwithstanding
                            the finality of all purchases, FPS Lounge may, under extreme circumstances and in its sole
                            discretion, offer to refund all or a portion of a given purchase.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Refunds</h4>

                        <p>You irrevocably grant FPS Lounge™ permission to access, process and otherwise use your
                            Content (as defined below) in order to provide products and/or services to you, to track and
                            analyze your use of the Service, and make your Content available to other users of the
                            Service and other third parties, and for the purposes as otherwise set for in the FPS
                            Lounge™ privacy policy. To the extent that you have intellectual property rights in your
                            Content, you hereby irrevocably grant to FPS Lounge™ a nonexclusive, worldwide, perpetual,
                            royalty-free, fully-paid up, sublicensable (through multiple tiers), transferable license to
                            reproduce, publicly display, publicly perform, distribute, prepare derivative works from and
                            otherwise use your Content for the purposes outlined in this Agreement. You agree, represent
                            and warrant that your Content does not constitute or embody any person’s or entity’s
                            confidential information, including yours. “Content” means text, images, photos, audio,
                            video and/or audiovisual data or information, and all other forms of data, information or
                            communication. The phrase “your Content” means Content submitted or transmitted by you.</p>
                        <p>You represent and warrant that you own your Content and that submitting or transmitting your
                            Content to or through the Service will not violate the rights of any third party, including
                            intellectual property, privacy or publicity rights. FPS Lounge™ is under no obligation to
                            review or screen your or other Service users’ Content. If you believe that another user has
                            violated any of your intellectual property rights, you may initiate the procedures outlined
                            in FPS Lounge’s™ policy concerning claims that Content accessed through the Service
                            infringes intellectual property rights as set forth below.</p>
                        <p>You agree that FPS Lounge™ may process and use your personally identifiable information in
                            the manner set forth in its privacy policy. The privacy policy applies only to the Service
                            and does not apply to any third party website or service linked to the Service or
                            recommended or referred to through the Service by FPS Lounge™ or other Service users.</p>
                        <p className={"terms-condition-bold"}>YOU UNDERSTAND AND AGREE THAT SHARING CONTENT ONLINE
                            INVOLVES RISKS OF UNAUTHORIZED DISCLOSURE OR EXPOSURE OF YOUR PERSONALLY IDENTIFIABLE
                            INFORMATION AND THAT, IN SUBMITTING YOUR CONTENT TO OR TRANSMITTING IT THROUGH THE SERVICE,
                            YOU ASSUME THESE RISKS. </p>
                        <p>FPS Lounge™ disclaims all liability for the accuracy of any Content submitted to or
                            transmitted through the Service by you or another user, including without limitation your
                            Content.</p>
                        <p>You agree that you will not rely on the Service or FPS Lounge™ for backup or storage of your
                            Content. FPS Lounge™ may retain your Content even if you are no longer using the Service but
                            is not required to provide copies of your Content to you. FPS Lounge™ may permanently delete
                            or erase your Content or suspend your access to your Content through the Service at any time
                            and for any reason.</p>
                        <p>FPS Lounge™ may use, reproduce, sell, publicize and otherwise exploit Aggregate Data in any
                            way, in its sole discretion, and as otherwise provided in its privacy policy. “Aggregate
                            Data” refers to your Content with personally identifiable information removed.</p>
                        <p>You consent to receive email and/or text messages from FPS Lounge™ in connection with your
                            use of the Service. Standard text messaging charges required by your mobile carrier will
                            apply to text messages we send you. You may request removal of your personally identifiable
                            information through the procedures set forth in our privacy policy.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Security</h4>

                        <p>You agree to keep your password confidential and not to distribute it or otherwise allow
                            anyone other than you to access your account. Your password is the key to all of the
                            information in your account.</p>
                        <p>Any communication you make on this Site or over the Internet, whether sent via e-mail, via a
                            form on the Site, or in-game communication, etc., should be regarded as an insecure
                            communication, unless we explicitly state on a particular web page that you can expect the
                            communication to be secure or encrypted.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Refusal & Termination of Service</h4>

                        <p>FPS Lounge™ reserves the right to terminate or refuse service to anyone, at any time, for any
                            reason or no reason at all, without prior notice, including, without limitation, for
                            violating any provision of this Agreement.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Cross-Border Export of User Data</h4>

                        <p>You irrevocably consent and agree that FPS Lounge™ may export any and all of your personally
                            identifiable and non-personally identifiable data and information outside of your country of
                            residence and/or country of collection to the United States and other countries in which FPS
                            Lounge™ operates for uses consistent with this Agreement and the FPS Lounge™ privacy
                            policy</p>

                        <h4 className="pt-5 pb-4 secondary-color">Export Control</h4>
                        <p>You may not use or otherwise export or re-export the Site and/or its content, except as
                            authorized by United States law. The Site and its content may not be exported or re-exported
                            (i) into (or to a national or resident of) any U.S.-embargoed country, (ii) to anyone on the
                            U.S. Treasury Department's list of Specially Designated Nationals, or (iii) to anyone on the
                            U.S. Department of Commerce's Table of Denial Orders. You represent and warrant that you are
                            not a resident or national of any such country on any such list, and are not located in,
                            under the control of, acting on behalf of or in concert with, a resident or national of any
                            such country or on any such list. </p>

                        <h4 className="pt-5 pb-4 secondary-color">License to Use the Site</h4>
                        <p>FPS Lounge™ grants, subject to the terms of this Agreement, a limited, non-transferable,
                            non-sublicensable, non-exclusive license to use the software, content and documentation
                            which it owns, or of which it is a licensee, for the limited purposes accessing, exploring
                            and using the Site in real time in a manner consistent with the terms of the Agreement.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Intellectual Property Rights in the Site</h4>
                        <p>Unauthorized duplication of this Site, in whole or in part, or of any plans, designs,
                            specifications, data or content made available from the Site (except as expressly authorized
                            herein) is a violation of the Copyright Act of 1976.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Copyrights</h4>
                        <p>Unless otherwise stated, FPS Lounge™ owns the copyright in this Site and is the owner or a
                            licensee of the content hereon, including, without limitation, all “works” as defined in the
                            Copyright Act of 1976, text, graphics, images, video material, audio material, audiovisual
                            material, and other content, regardless of media format, whether now existing or hereafter
                            arising (“Copyright Material”). The Copyright Material is protected by United States and
                            international copyright laws. You may display and download portions of the Site solely for
                            your own personal, non-commercial use. You agree not to change or delete any proprietary
                            notices from materials downloaded from the Site. All other uses, including, without
                            limitation, the reproduction, copying, modification, distribution, sale, lease,
                            transmission, republication, performance, display, or any commercial use of the content on
                            this site are strictly prohibited by law.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Trademarks</h4>
                        <p>FPS Lounge™ and the FSP Lounge™ stylized design are trademarks owned by FPS Lounge™
                            (individually and collectively the “Trademarks”). The Trademarks may not be used in any
                            manner by any person or in connection with any product or service, without the express
                            written authorization of an officer or director of FPS Lounge™. All other trademarks,
                            service marks, and trade names are the property of their respective owners and are used on
                            this site by virtue of a right or license from their respective owners.</p>

                        <h4 className="pt-5 secondary-color">Notice and Procedure for Making Claims of Copyright
                            Infringement</h4>
                        <h4 className="pb-4 secondary-color">Notice of Claimed Infringement</h4>

                        <p>Pursuant to 17 U.S.C. § 512, claims of copyright infringement must be submitted to the
                            following designated agent:</p>
                        <p>Hank Fasthoff <br/>
                            Fasthoff Law Firm PLLC <br/>
                            21 Waterway Ave., Suite 300 <br/>
                            The Woodlands, TX 77380 <br/>
                            hank @ fasthofflawfirm.com
                        </p>

                        <p>To be effective, the notification must be a written communication that includes the
                            following:</p>
                        <ol type={"i"}>
                            <li><p>A physical or electronic signature of a person authorized to act on behalf of the
                                owner
                                of an exclusive right that is allegedly infringed;</p>
                            </li>
                            <li><p>A reasonably detailed description of the alleged infringing material, or, if multiple
                                copyrighted works are alleged to be infringing, a representative list of such works on
                                this website;</p>
                            </li>
                            <li><p>A reasonably detailed description of where the alleged infringing material is located
                                on
                                this website;</p>
                            </li>
                            <li><p>Information reasonably sufficient to permit us to contact the complaining party, such
                                as
                                an address, telephone number, and, if available, an electronic mail address at which the
                                complaining party may be contacted;</p>
                            </li>
                            <li><p>A statement that the complaining party has a good-faith belief that use of the
                                material
                                in the manner complained of is not authorized by the copyright owner, its agent, or the
                                law; and</p>
                            </li>
                            <li><p>A statement that the information in the notification is accurate, and under penalty
                                of
                                perjury, that the complaining party is authorized to act on behalf of the owner of an
                                exclusive right that is allegedly infringed.</p>
                            </li>
                        </ol>

                        <h4 className="pt-5 pb-4 secondary-color">Counter-Notice</h4>
                        <p>If you believe that your content that was removed pursuant to a Digital Millennium Copyright
                            Act notice we received is not infringing, or that you have the authorization from the
                            copyright owner, the copyright owner's agent, or pursuant to law, to post and use the
                            content, you may send a counter-notice containing the following information to the Copyright
                            Agent at the address listed above:</p>
                        <ol type={"i"}>
                            <li><p>Your physical or electronic signature;</p></li>
                            <li><p>Identification of the content that has been removed or to which access has been
                                disabled and the location at which the content appeared before it was removed or
                                disabled;</p></li>
                            <li><p>A statement that you have a good faith belief that the content was removed or
                                disabled as a result of mistake or a misidentification of the content; and</p></li>
                            <li><p>Your name, address, telephone number, and e-mail address, a statement that you
                                consent to the jurisdiction of the federal court in Travis County, Texas, and a
                                statement that you will accept service of process from the person who provided
                                notification of the alleged infringement.</p></li>
                        </ol>
                        <p>If a counter-notice is received by the Copyright Agent, we may send a copy of the
                            counter-notice to the original complaining party informing that person that it may replace
                            the removed content or cease disabling it in 10 business days. Unless the copyright owner
                            files an action seeking a court order against the content provider, member or user, the
                            removed content may be replaced, or access to it restored, in 10 to 14 business days or more
                            after receipt of the counter-notice, at our sole discretion.</p>

                        <h4 className="pt-5 pb-4 secondary-color">General Submissions</h4>
                        <p>FPS Lounge™ values and encourages feedback. Pursuant to FPS Lounge’s™ policy, however, we do
                            not accept or consider creative ideas, suggestions, or materials other than those
                            specifically requested from you by FPS Lounge™, if you choose to send any creative
                            submissions to us, whether at FPS Lounge’s™ request, or despite our request that you do not
                            send submissions to us, then you hereby irrevocably assign and transfer to FPS Lounge™ any
                            and all rights, throughout the universe, in and to each submission (including the individual
                            elements of each submission), without any compensation therefore, including, without
                            limitation, all domestic and foreign copyrights, trademark rights (and goodwill appurtenant
                            thereto), patent rights, trade secret rights, know-how, and all other intellectual property
                            rights and other rights, whether now existing or hereafter arising. To the extent moral
                            rights may not be assigned or transferred, you hereby irrevocably waive enforcement of any
                            and all moral rights. FPS Lounge™ shall be entitled to complete unrestricted use of the
                            submissions and all content, data and information contained therein for any purpose, whether
                            commercial or otherwise, without compensation to, or recognition or acknowledgment of, the
                            author(s) or provider(s) of the submissions.</p>

                        <h4 className="pt-5 pb-4 secondary-color">User Representations & Warranties</h4>
                        <p>You represent and warrant that you are at least 18 years of age and have reached the age of
                            majority in the location where you reside. If you desire to participate in one or more
                            sweepstakes, contests or promotions that may be offered by FPS Lounge™, you further
                            represent that you are a lawful resident of one of the states for which registration for
                            sweepstakes, contests or promotions is permitted. You further represent and warrant that you
                            possess the legal right and ability to enter into this Agreement and to use the Site in
                            accordance with this Agreement. You agree to be financially responsible and otherwise
                            responsible for your use of the Site, and to comply with your responsibilities and
                            obligations as stated in this Agreement. You further agree to (i) provide true, accurate,
                            current and complete information about you as requested by the forms available on this Site;
                            and (ii) to maintain and update your information to keep it secure, true, accurate, current,
                            and complete. If you provide any information that is untrue, inaccurate, not current or
                            incomplete, FPS Lounge™ has the right to terminate your account and refuse any and all
                            current or future use of the Site.</p>
                        <p>You further represent and warrant that you will not provide to us the name, address, email
                            address, phone number, or other personal identifying information of any person under the age
                            of 13. Violation of this provision is grounds for immediate cancellation of any orders,
                            goods, and services offered hereunder.</p>

                        <h4 className="pt-5 pb-4 secondary-color">Governing Law, Jurisdiction, & Venue; Arbitration</h4>
                        <p>All uses of the Site, and all content contained herein, including, without limitation, any
                            disputes arising under or related to this Agreement, shall be governed by and interpreted
                            pursuant to the laws of the State of Texas and the United States of America without regard
                            for conflicts of laws principles. All disputes arising under or relating to the Agreement
                            and/or your visitation, access or use of the Site, shall be submitted to and resolved by
                            binding arbitration in Travis County, Texas, and any award shall be confirmed exclusively in
                            the courts situated in Travis County, Texas. You further irrevocably agree and consent to
                            the exclusive jurisdiction and venue of the courts situated in Travis County, Texas for the
                            purposes of reviewing, confirming or otherwise relating to any arbitration award, and you
                            hereby expressly waive jurisdiction and venue in any other forum for such purposes. Any
                            arbitration shall be conducted on a confidential basis pursuant to the Commercial
                            Arbitration Rules of the American Arbitration Association. Any decision or award as a result
                            of any such arbitration proceeding shall be in writing and shall provide an explanation for
                            all findings of fact and conclusions of law, and shall be kept confidential and not
                            disclosed for any purpose except as required by law, e.g., disclosure to tax advisors for
                            the purpose of filing a tax return. Any such arbitration shall include a written record of
                            the arbitration hearing. </p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">WAIVER OF CLASS ACTION/JURY
                            TRIAL</h4>
                        <p>ARBITRATION MUST BE ON AN INDIVIDUAL BASIS. YOU MAY NOT JOIN OR CONSOLIDATE CLAIMS OR PARTIES
                            IN ARBITRATION, OR LITIGATE IN COURT OR ARBITRATE ANY CLAIMS AS A REPRESENTATIVE OR MEMBER
                            OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY, AND YOU HEREBY IRREVOCABLY WAIVE ANY
                            RIGHT YOU MAY HAVE TO FILE ANY SUCH CLAIM OR PARTICIPATE IN ANY PROCEEDING IN ANY SUCH
                            CAPACITY. YOU FURTHER HEREBY IRREVOCABLY WAIVE YOUR RIGHT TO A JURY TRIAL</p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">INDEMNIFICATION</h4>
                        <p>YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS FPS LOUNGE™ AND ITS PARENT (IF ANY),
                            SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND EACH OF THE FOREGOING’S PAST, PRESENT
                            AND FUTURE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, MANAGERS, PARTNERS, SHAREHOLDERS,
                            OWNERS, ASSOCIATES, JOINT VENTURERS, CONTRACTORS, ATTORNEYS, INSURERS, AGENTS AND
                            REPRESENTATIVES (INDIVIDUALLY AND COLLECTIVELY, THE “INDEMNIFIED PARTIES”), FROM AND AGAINST
                            ALL LIABILITIES, DAMAGES, CLAIMS, CAUSES OF ACTION, COSTS, ATTORNEY’S FEES, AND ALL OTHER
                            COSTS AND EXPENSES, IN CONNECTION WITH, ARISING OUT OF, OR RELATING TO (I) YOUR ACTUAL OR
                            ALLEGED BREACH OF THIS AGREEMENT (II) YOUR ACTUAL OR ALLEGED NEGLIGENT, WILFUL OR
                            INTENTIONAL ACTIONS OR OMISSIONS ARISING OUT OF OR RELATING TO YOUR REGISTRATION, VISITATION
                            OR USE OF THIS SITE; PURCHASE, POSSESSION, USE OR NONUSE OF ANY MERCHANDISE OR SERVICES MADE
                            AVAILABLE ON THIS SITE. THE INDEMNIFIED PARTIES MAY, AT THEIR SOLE DISCRETION, CHOOSE TO
                            PARTICIPATE IN THE DEFENSE OR ANY MATTER OR CLAIM OR NEGOTIATED SETTLEMENT. NO SETTLEMENT
                            THAT MAY HAVE THE POTENTIAL TO AFFECT AN INDEMNIFIED PARTY’S RIGHTS IN ANY WAY SHALL BE
                            ENTERED INTO WITHOUT SUCH INDEMNIFIED PARTY’S PRIOR WRITTEN APPROVAL.</p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">LIMITATION OF LIABILITY</h4>
                        <p>ACCESS TO AND USE OF THIS WEBSITE IS PROVIDED ON AN “AS IS” BASIS. YOU AGREE THAT FPS LOUNGE™
                            AND ITS PARENT (IF ANY), SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND EACH OF THE
                            FOREGOING’S PAST, PRESENT AND FUTURE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, MANAGERS,
                            PARTNERS, SHAREHOLDERS, OWNERS, ASSOCIATES, JOINT VENTURERS, CONTRACTORS, ATTORNEYS,
                            INSURERS, AGENTS AND REPRESENTATIVES (INDIVIDUALLY AND COLLECTIVELY, THE “LIMITED PARTIES”),
                            SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND, OR FOR ANY INDEMNITY, REGARDLESS OF THE
                            NATURE OR CAUSE OF THE DAMAGES OR INDEMNITY, THAT ARISE OUT OF OR RELATE TO YOUR VISITATION
                            OR USE OF THIS SITE; PURCHASE, POSSESSION, USE OR NONUSE OF MERCHANDISE OR A SWEEPSTAKES
                            ENTRY, OR THE OFFERING OF CONTENT, INFORMATION, GOODS, SERVICES, OR OTHER MATERIAL ON, OR
                            ACCESSIBLE THROUGH, THIS WEBSITE AND/OR IN ANY EMAILS, NEWSLETTERS, OR IN ANY
                            EXTERNALLY-LINKED THIRD PARTY SITE. WITHOUT LIMITING THE FOREGOING, THE LIMITED PARTIES
                            SHALL NOT BE LIABLE FOR ACTUAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL,
                            AND/OR CONSEQUENTIAL DAMAGES, OR FOR ANY OTHER CAUSE, LOSS, ACTION, CLAIM, OR DAMAGE,
                            INCLUDING LOSS OF TIME, LOSS OF OPPORTUNITY, LOSS OF BUSINESS, LOSS OF EARNINGS, OR INJURY
                            TO PERSON (INCLUDING DEATH) OR PROPERTY.</p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">DISCLAIMER OF ALL OTHER
                            WARRANTIES</h4>
                        <p>ALL PRODUCTS, SERVICES, MATERIALS, INFORMATION, AND OTHER CONTENT ON THIS SITE ARE PROVIDED
                            ON AN “AS IS” BASIS. FPS LOUNGE™ MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY TYPE, NEITHER
                            EXPRESS NOR IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION, MATERIALS, CONTENT,
                            PRODUCTS, OR SERVICES CONTAINED HEREIN. FPS LOUNGE™ EXPRESSLY DISCLAIMS ALL WARRANTIES OF
                            ALL TYPES, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
                            FOR A PARTICULAR PURPOSE. </p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">SITE USAGE POLICY</h4>
                        <p>The Site may be used only for lawful purposes. The transmission, storage, routing, or
                            presentation of any information, data or material in violation of any applicable local,
                            state, or federal law or regulation, or in violation of this Site Usage Policy is strictly
                            prohibited. Examples of such information, data, or material include unauthorized
                            reproduction, publication, distribution or other use of copyrighted works, patents,
                            inventions, trademarks, trade secrets; research, development, plans, materials or other data
                            that are prohibited by the United States Export Control Regulations; or the dissemination of
                            harmful, harassing, or fraudulent content. Any conduct by any user that constitutes
                            harassment, fraud, stalking, abuse, or a violation of applicable law is strictly prohibited.
                            Using the Site to perform, or solicit the performance of, any illegal activity is also
                            strictly prohibited.</p>

                        <p>In addition to the forgoing, the following uses are strictly prohibited and all users agree
                            not to engage in such conduct, directly or indirectly:</p>

                        <ol type={"a"}>
                            <li><p>Posting or transmitting any unsolicited advertising, promotional materials, junk
                                mail, "spam," chain emails, telephone calls or any other form of solicitation to
                                anyone. </p></li>
                            <li><p>Spamming any email address that appears on the Site. </p></li>
                            <li><p>Posting any multi-level-marketing, pyramid scheme, "club membership," distributorship
                                or sales representative agency arrangement or other business or commercial
                                opportunity.</p></li>
                            <li><p>Including any hidden text in your postings, including words in white or otherwise
                                invisible font.</p></li>
                            <li><p>Deleting or revising any material posted by any other person or entity.</p></li>
                            <li><p>If you have a password allowing access to a nonpublic area of the Site, you are
                                prohibited from sharing or disclosing it to any third party, and from allowing any third
                                party to access the Site under your account. </p></li>
                            <li><p>Using, or attempting to use, any engine, software, tool, agent or other device or
                                mechanism (including without limitation browsers, spiders, bots, avatars or intelligent
                                agents) to navigate or search the Site, other than (i) the search engine and search
                                agents available on the Site, and (ii) generally available third party web
                                browsers. </p></li>
                            <li><p>Accessing the Site by any means other than through the interface that is provided for
                                use in accessing the Site. </p></li>
                            <li><p>Attempting to decipher, decompile, disassemble or reverse engineer any of the
                                software comprising a part of the Site.</p></li>
                            <li><p>Aggregating, copying or duplicating in any manner any of the materials or information
                                available from the Site.</p></li>
                            <li><p>Framing of or linking to any of the materials or information available from the
                                Site.</p></li>
                            <li><p>Framing of or linking to any of the materials or information available from the
                                Site.</p></li>
                        </ol>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">CONFLICTS</h4>
                        <p>In the event of any conflict between this Agreement and any FPS Lounge™ policy posted online,
                            this Agreement shall control.</p>

                        <h4 className="pt-5 pb-4 secondary-color terms-condition-bold">ENTIRE AGREEMENT</h4>
                        <p>This Agreement sets forth the entire agreement of the parties and supersedes all prior or
                            contemporaneous statements, representations, agreement, writings, negotiations, and
                            discussions with respect to its subject matter hereof, all of which are merged herein.
                            Neither party is relying on any statement or representation that is not set forth in this
                            Agreement.</p>

                        <div className="term-condition-bold">For information on other FPS Lounge™ policies, please click
                            one the links below:
                        </div>
                        <a href="">Privacy Policy</a>

                        <div className={"pt-4"}>Copyright © 2022 FPS Lounge, Inc. All Rights Reserved.</div>
                    </div>
                </div>
            </Container>
        </div>
    )
}