export abstract class RoleFilters {
    private static ADMIN = 'admin';
    private static STUDENT = 'student';
    private static COACH = 'coach';

    public static adminOnly = [this.ADMIN];
    public static studentOnly = [this.STUDENT];
    public static coachOnly = [this.COACH];
    public static anyone = [];
}
