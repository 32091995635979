import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, Stack} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {EditCoachMiniProfilePayload} from "../../schemas/game-schema";
import {useNavigate} from "react-router-dom";
import {FieldError} from "../../components/form/FieldError";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import './EditCoachProfilePage.scss';
import _ from "lodash";
import {PlusLg, Trash} from "react-bootstrap-icons";
import {v4 as uuid} from "uuid";
import {toast} from "react-toastify";

export function EditCoachMiniProfilePage() {
    const usersStoreActions = useStoreActions(state => state.users);
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();
    const [isFormInit, setIsFormInit] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue,
    } = useForm<EditCoachMiniProfilePayload>();

    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control,
        name: "mainPoints",
        shouldUnregister: true
    });

    useEffect(() => {
        if (!user || isFormInit) return;
        setValue('mainPoints', user.mainPoints ?? []);
        setIsFormInit(true);
    }, [user])


    const onSubmit: SubmitHandler<EditCoachMiniProfilePayload> = async (payload) => {
        console.log(payload)
        if (!user?.uid) return;
        if (_.isEmpty(payload.mainPoints)) {
            toast('Please add at least one highlight points');
            return;
        }
        const success = await usersStoreActions.editCoachMiniProfile({
            ...payload,
            updateId: user.uid,
        });
        if (!success) return;
        navigate(-1);
    };

    function onDiscard() {
        navigate(-1);
    }

    return (<Container className={'page edit-coach-profile-page'}>
        <div className={"dashboard-box-outline"}>
            <div className="p-5">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Col>
                        <h1 className={"primary-color text-center"}>Edit Coach Card</h1>
                        <p className={"text-center pb-5"}>This section will let you edit your coach card.</p>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                    Enter Up To 3 Highlight Points About Your Coaching
                                </Form.Label>
                                <Stack direction={"vertical"}>
                                    {fields.map((field, index) => (
                                        <Stack key={`GameFilterOption-${index}`} direction={"horizontal"}>
                                            <Form.Group key={field.id} className="w-100 me-2 my-1">
                                                <Form.Control
                                                    placeholder={`Hightlight point #${index + 1}`}
                                                    {...register(`mainPoints.${index}.label`, {
                                                        required: 'Field is required'
                                                    })}
                                                />
                                                <FieldError
                                                    message={errors.mainPoints?.[index]?.label?.message}
                                                />
                                            </Form.Group>
                                            <Trash onClick={() => remove(index)}/>
                                        </Stack>
                                    ))}
                                    <Button className={'mt-1 dashboard-secondary-btn'}
                                            disabled={fields.length >= 3}
                                            variant={"outline-primary"} onClick={
                                        () => {
                                            if (fields.length >= 3) return;
                                            append({
                                                id: uuid(),
                                                label: '',
                                            });
                                        }
                                    }><PlusLg
                                        size={20}/>Add Another Point</Button>
                                </Stack>
                                <FieldError message={errors.mainPoints?.message}/>
                            </Form.Group>
                        </Col>
                        <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
                            <Button variant="primary" type="submit" className={"dashboard-primary-btn w-100"}>
                                Save
                            </Button>
                            <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                                    onClick={() => onDiscard()}>
                                Cancel
                            </Button>
                        </Stack>
                    </Col>
                </Form>
            </div>
        </div>
    </Container>);
}
