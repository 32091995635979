import _ from "lodash";
import {Dict} from "../interfaces/dict";

export abstract class CommonUtils {
    public static stringToSafeCamelCase(source: string) {
        return _.camelCase(source.replaceAll('.', ' '));
    }

    public static sanitizeData(data: Dict): Dict {
        return _(data).mapValues((value) => {
            return value === undefined ? null : value;
        }).value();
    }

    public static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static openInNewTab(url: string) {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
}
