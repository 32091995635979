import {secretConverter, SecretSchema} from "../schemas/secret-schema";
import {db} from "../config/firebase-config";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError} from "@firebase/firestore";

export function useSecret(uid: string | undefined): {
    secretError: FirestoreError | undefined;
    secretData: SecretSchema | undefined;
    secretLoading: boolean;
} {
    const coachDocRef = uid ? doc(collection(db, 'secrets'), uid).withConverter(secretConverter) : undefined;
    const [secretData, secretLoading, secretError] = useDocumentData<SecretSchema>(coachDocRef);
    return {
        secretData,
        secretLoading,
        secretError,
    };
}
