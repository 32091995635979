import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import "./StudentOverviewTab.scss"
import {
    Discord,
    Envelope,
    PatchCheck,
    PatchExclamation,
    PersonBadge,
    Twitch,
    Twitter,
    Youtube
} from "react-bootstrap-icons";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import {routes} from "../../App";
import {useNavigate} from "react-router-dom";
import {BusyOverlay} from "../../components/BusyOverlay";
import {UserSchemaExt} from "../../schemas/user-schema";
import {updateDoc} from "firebase/firestore";
import {TimeUtils} from "../../utils/time-utils";
import {toast} from "react-toastify";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, CategoryScale, LinearScale, PointElement, LineElement,);


const labels = ['January', 'February', 'March', 'April', 'May', 'June'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [12, 20, 10, 23, 11, 3],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: [2, 28, 18, 12, 5, 8],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};


export const data2 = {
    labels,
    datasets: [
        {
            label: 'Active days',
            data: [28, 20, 10, 8, 21, 50],
            backgroundColor: 'rgba(238, 35, 55, 0.5)',
        },
    ],
};

export const data3 = {
    labels,
    datasets: [
        {
            label: 'Active days',
            data: [5, 12, 15, 8, 16, 12],
            backgroundColor: 'rgba(238, 35, 55, 0.5)',
        },
    ],
};


export const data4 = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [5, 12, 12, 18, 13, 10],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: [8, 5, 18, 12, 7, 10],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};


export function StudentOverviewTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();

    async function onEditProfile() {
        navigate(routes.editStudentProfile);
    }

    return <BusyOverlay isBusy={userLoading}>
        <div className="student-overview-tab">
            <h3 className={"primary-color"}>Student Overview</h3>
            <p>This section will contain a overview of student account. It will also
                contain
                statistics and analytics.</p>

            <div className="dashboard-content-outline">
                <div className="dashboard-padding-box">

                    <Row>
                        <Col xl={6}>
                            <div className="student-info-row mb-4">
                                <div className={"dashboard-student-img"}><img src="/assets/profile/dummy-profile-1.jpg"
                                                                              alt=""/></div>
                                <div className={"px-5"}>
                                    <div
                                        className="student-name pt-1">{user?.displayName}&nbsp;&nbsp; {authUser?.emailVerified ?
                                        <PatchCheck color="#05B78C"/> : <PatchExclamation color="#EE2337"/>}</div>
                                    <div className={"student-role pt-2"}>{user?.role}</div>
                                </div>
                            </div>
                            <div className={"mb-4"}>
                                <Button variant={"primary"} className={'dashboard-secondary-btn'}
                                        onClick={() => onEditProfile()}>Edit
                                    Profile</Button>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className="student-contact-info-main">
                                <div className={"student-info-row pb-3"}>
                                    <div className="info-title">
                                        <Envelope size={20}/>
                                    </div>
                                    <div className="info-data">
                                        {user?.email}
                                    </div>
                                </div>
                                <div className={"student-info-row pb-4"}>
                                    <div className="info-title">
                                        <PersonBadge size={20}/>
                                    </div>
                                    <div className="info-data">
                                        {authUser?.uid}
                                    </div>
                                </div>
                                <div className={"student-info-row pb-3"}>
                                    <div className="info-title">
                                        <a className={`${user?.twitchUsername ? 'social-link-available' : ''}`}
                                           href={user?.twitchUsername}><Twitch size={20}/></a>
                                    </div>
                                    <div className="info-title">
                                        <a className={`${user?.youtube ? 'social-link-available' : ''}`}
                                           href={user?.youtube}><Youtube size={20}/></a>
                                    </div>
                                    <div className="info-title">
                                        <a className={`${user?.twitterUsername ? 'social-link-available' : ''}`}
                                           href={user?.twitterUsername}><Twitter size={20}/></a>
                                    </div>
                                    <div className="info-title">
                                        <a className={`${user?.discord ? 'social-link-available' : ''}`}
                                           href={user?.discord}><Discord size={20}/></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className={'py-4'}>
                        <Col xl={6}>
                            {user && <div className="booking-mode">Current
                                Timezone: <br/><span
                                    style={{color: '#05B78C'}}>{UserSchemaExt.getTimezone(user)?.name}
                                    {!!user?.timezone || `No timezone set! Please update your weekly availability again to update the timezone.`}</span>
                            </div>}
                        </Col>
                        <Col xl={6}>
                            <div className="dashboard-overview-btn-main">
                                <Button onClick={async () => {
                                    if (!user) return;
                                    await updateDoc(user.snapshot.ref, {
                                        timezone: TimeUtils.guessTimezone().name,
                                    });
                                    toast('Timezone reset complete!');
                                }} className={"dashboard-secondary-btn"}>Reset Timezone</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="dashboard-content-outline mt-4">
                <div className="dashboard-chart-section p-4">
                    <div className="dashboard-chart-overlay"><h2>Personalized goals and analytics coming soon</h2></div>
                    <div className="dashboard-blur">
                        <Row className={"pb-5"}>
                            <Col md={6}>
                                <div className="chart-main">
                                    <div className="chart-title pb-4">Completion</div>
                                    <div className="dashboard-chart">
                                        <Line data={data}/>
                                    </div>

                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="chart-main">
                                    <div className="chart-title pb-4">Active Hours</div>
                                    <div className={"dashboard-chart"}>
                                        <Bar data={data2}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="chart-main">
                                    <div className="chart-title pb-4">Active Hours</div>
                                    <div className={"dashboard-chart"}>
                                        <Bar data={data3}/>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="chart-main">
                                    <div className="chart-title pb-4">Completion</div>
                                    <div className="dashboard-chart">
                                        <Line data={data4}/>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </div>
    </BusyOverlay>;
}
