import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";
import {PricingMode} from "../definitions/pricing-mode";
import {GameFilterPack} from "../interfaces/game-filter-pack";
import {PricingPack} from "../interfaces/pricing-pack";
import {DataPointer} from "../interfaces/data-pointer";

export interface GameSchema {
    id: string,
    created: Timestamp,
    modified: Timestamp,
    name: string,
    type: string,
    imageUrl?: string,
    bannerUrl?: string,
    description: string,
    filters: GameFilterPack[];
    chargingMode: false | string[];
    pricingPack: PricingPack | null;
}

export abstract class GameSchemaExt {
    static hasPricingMode(chargingMode: string[] | false, pricingPack: PricingPack, mode: PricingMode): boolean {
        switch (mode) {
            case PricingMode.Hourly:
                return this.hasHourlyRate(chargingMode, pricingPack);
            case PricingMode.Ranked:
                return this.hasRanked(chargingMode, pricingPack);
            case PricingMode.PerGame:
                return this.hasPerGame(chargingMode, pricingPack);
        }
    }

    public static hasHourlyRate(chargingMode: string[] | false, pricingPack: PricingPack | null): boolean {
        return !!chargingMode && chargingMode.includes('0') && pricingPack?.hourlyRate !== undefined;
    }

    public static hasPerGame(chargingMode: string[] | false, pricingPack: PricingPack | null): boolean {
        return !!chargingMode && chargingMode.includes('2') && pricingPack?.perGameRate !== undefined;
    }

    public static hasRanked(chargingMode: string[] | false, pricingPack: PricingPack | null): boolean {
        return !!chargingMode && chargingMode.includes('1') && pricingPack?.rankedRates !== undefined;
    }
}

export interface GamePayload {
    updateId: string | null;
    name: string;
    type: string;
    logo: FileList;
    description: string;
    imageUrl: string;
    bannerUrl: string;
    created: Timestamp;
    filters: GameFilterPack[];
    chargingMode: false | string[];
    pricingPack: PricingPack | null;
}

export interface EditPackagesPayload {
    updateId: string;
    pricingPack: PricingPack | null;
    chargingMode: false | string[];
}

export interface EditCoachProfilePayload {
    updateId: string;
    youtube: string;
    discord: string;
    twitterUsername: string;
    twitchUsername: string;
    gamerTag: string;
    permalink: string;
    vodBio: string;
    bio: string;
    languages: string[];
    platforms: string[];
    inputMethods: string[];
    kdRatio?: string;
    winRate?: string;
    totalKills?: string;
    totalDeaths?: string;
    profileImage?: string | null;
}

export interface EditCoachMiniProfilePayload {
    mainPoints?: DataPointer[];
    updateId: string;
}

export interface EditStudentProfilePayload {
    updateId: string;
    discord: string;
    gamerTag: string;
}

export const gameConverter: FirestoreDataConverter<GameSchema> = {
    toFirestore(game: WithFieldValue<GameSchema>): DocumentData {
        return game;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): GameSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as GameSchema;
    },
};
