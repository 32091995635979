import {useBusy} from "./use-busy";
import {useStoreActions} from "../models";
import React from "react";
import {useCurrentUser} from "./use-current-user";
import _ from "lodash";

export function useWithdrawFunds(): {
    isBusyWithdrawFunds: boolean;
    withdrawFunds: () => Promise<void>;
} {
    const {user} = useCurrentUser();
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const economyStoreActions = useStoreActions((actions) => actions.economy);
    const {isBusy, setIsBusy} = useBusy();

    async function onWithdrawFunds(): Promise<void> {
        if (!user) return;
        if (user.balance < 50) {
            await uiStoreActions.addMessageAlert({
                title: 'Withdrawal Not Allowed',
                subtitle: 'You must have at least 50 USD in your wallet before you can withdraw.',
            });
            return;
        }
        const rawAmount = await uiStoreActions.addSingleInputAlert({
            title: "Amount to Withdraw",
            message: "Please enter the amount to withdraw",
            inputType: 'number',
            required: true,
        });
        const amount = _.toNumber(rawAmount);
        if (_.isNaN(amount) || amount <= 0) return;
        setIsBusy(true);
        const response = await economyStoreActions.requestWithdrawal({
            amount: amount,
            uid: user.uid,
        });
        setIsBusy(false);
        await uiStoreActions.addMessageAlert({
            title: response.success ? 'Withdrawal successful!' : 'Failed to submit!',
            subtitle: response.message,
        });
    }

    return {
        isBusyWithdrawFunds: isBusy,
        withdrawFunds: onWithdrawFunds,
    };
}
