import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";

export interface SystemConfigSchema {
    id: string;
    created: Timestamp;
    modified: Timestamp;
    isInitialized: boolean;
    balance: number,
    systemId: string;
    products: {
        credit: string;
        creditPrice: string;
        hourly: string;
        perGame: string;
        ranked: string;
    }
}

export const systemConfigConverter: FirestoreDataConverter<SystemConfigSchema> = {
    toFirestore(systemConfig: WithFieldValue<SystemConfigSchema>): DocumentData {
        return systemConfig;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): SystemConfigSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
            balance: snapshot.get('balance') ?? 0,
        } as SystemConfigSchema;
    },
};
