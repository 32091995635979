import React from "react";
import {Button, Col, Container, Row, Stack} from "react-bootstrap";
import '../common/CoachesPage.scss';
import "./CoachProfilePage.scss"
import {UserSchemaExt} from "../../schemas/user-schema";
import {useNavigate, useParams} from "react-router-dom";
import {CgCopy} from "react-icons/cg";
import {useStoreActions} from "../../models";
import {Discord, StarFill, Twitch, Twitter, Youtube} from "react-bootstrap-icons";
import {routes} from "../../App";
import {OnlineIndicator} from "../../components/OnlineIndicator";
import {useCoachByPermalink} from "../../hooks/use-user";
import _ from "lodash";
import {SpinnerPadded} from "../../components/SpinnerPadded";
import {useGameOnce} from "../../hooks/use-game";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useTipCoach} from "../../hooks/use-tip-coach";
import {useCurrentUser} from "../../hooks/use-current-user";
import {toast} from "react-toastify";
import {useCoachAvailability} from "../../hooks/use-coach-availability";
import {useCoachPricing} from "../../hooks/use-coach-pricing";
import {TimeUtils} from "../../utils/time-utils";

export const CoachProfilePage = () => {
    let {username} = useParams();
    const navigate = useNavigate();
    const {user} = useCurrentUser();
    const {coachData, coachLoading, coachError} = useCoachByPermalink(username);
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const {game} = useGameOnce(coachData?.coachingGame);
    const {isBusyTipCoach, tipCoach} = useTipCoach(coachData?.uid);
    const {availabilityEvents} = useCoachAvailability(coachData);
    const {pricingPack} = useCoachPricing(coachData, game);

    if (!coachLoading && coachError) return <p>{coachError.message}</p>

    async function onCopyPath(): Promise<void> {
        await navigator.clipboard.writeText(window.location.toString())
        toast(`Permalink of '${coachData?.displayName}' Copied to Clipboard`);
    }

    function onChatWithCoach(): void {
        if (!coachData) return;
        navigate(routes.chatWith.replace(':userId', coachData.uid))
    }

    function getBannerStyles() {
        if (!game?.bannerUrl) return {};
        return {
            backgroundImage: `
                    linear-gradient(
                    rgba(16, 24, 32, 1),
                    rgba(16, 24, 32, 0.98),
                    rgba(16, 24, 32, 0.9),
                    rgba(16, 24, 32, 0.75),
                    rgba(16, 24, 32, 0.75),
                    rgba(16, 24, 32, 0.99),
                    rgba(16, 24, 32, 1)), url('${game?.bannerUrl}')`
        };
    }

    function getRanks() {
        try {
            return game?.filters
                .map(filter => filter.filterOptions.find(option => coachData?.filters?.[filter.id!] === option.id)?.optionName)
                .join(', ');
        } catch (ex) {
            return '-'
        }
    }

    return (<BusyOverlay isBusy={isBusyTipCoach}>
            <div className={"page coach-profile-page"}>
                <div className="profile-bg-img" style={getBannerStyles()}></div>
                <div className="coach-profile-main">
                    <Container>
                        {coachLoading && <SpinnerPadded/>}
                        {coachData && UserSchemaExt.isCoach(coachData) && <>
                            <div className="coach-profile-desktop">
                                <div className="profile-row py-5">
                                    <div className="profile-main-col-1">
                                        <div
                                            className={`profile-pic ${UserSchemaExt.isOnline(coachData) ? 'coach-profile-pic-online' : ''}`}>
                                            <img
                                                src={coachData?.profileImage ?? game?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                                                style={{objectFit: "cover"}}
                                                width={350} height={350} alt=""/>
                                        </div>
                                        <div className="bio-box mt-5">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-3">Bio</div>
                                                <div
                                                    className="bio-description pb-3">{coachData.bio || 'No bio provided'}</div>
                                                {coachData.vodBio && <div className={'mt-2'}>
                                                    <div className="profile-sub-heading pb-3">Vod Bio</div>
                                                    <div className="bio-description pb-3">{coachData.vodBio}</div>
                                                </div>}
                                                <div className={"pt-2"}>
                                                    <div className="profile-sub-heading pb-3">Coach Info</div>
                                                    <div className={"usernames-main"}>
                                                        <div className={"usernames-title pb-2"}>Gamer Tag:</div>
                                                        <div>{coachData.gamerTag}</div>
                                                    </div>
                                                    <div className="coach-profile-social-media-main pt-4">
                                                        {coachData.twitchUsername && <div>
                                                            <div className={"usernames-title clickable pb-2"}><a
                                                                href={coachData.twitchUsername}><Twitch
                                                                size={"25"}
                                                                color={"#EE2337"}/></a>
                                                            </div>
                                                        </div>}
                                                        {coachData.youtube &&
                                                            <div className={"usernames-title clickable pb-2"}><a
                                                                href={coachData.youtube}><Youtube
                                                                size={"25"}
                                                                color={"#EE2337"}/></a>
                                                            </div>}
                                                        {coachData.twitterUsername &&
                                                            <div className={"usernames-title clickable pb-2"}><a
                                                                href={coachData.twitterUsername}><Twitter
                                                                size={"25"}
                                                                color={"#EE2337"}/></a>
                                                            </div>}
                                                        {coachData.discord &&
                                                            <div className={"usernames-title clickable pb-2"}><a
                                                                href={coachData.discord}><Discord
                                                                size={"25"}
                                                                color={"#EE2337"}/></a>
                                                            </div>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="general-availability-box mt-5">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-3">General Availability</div>
                                                <div className="general-availability-main">
                                                    {_.isEmpty(availabilityEvents) && 'Availability not specified yet.'}
                                                    {availabilityEvents.map((availability) => {
                                                        return <div key={JSON.stringify(availability)}
                                                                    className={'general-availability-row pb-3'}>
                                                            <div>{TimeUtils.dateToLocaleWeekdayString(availability[0])} {TimeUtils.dateToLocaleTimeString(availability[0])}</div>
                                                            to
                                                            <div>{TimeUtils.dateToLocaleWeekdayString(availability[1])} {TimeUtils.dateToLocaleTimeString(availability[1])}</div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="profile-main-col-2">
                                        <div className="profile-main-content">
                                            <div className="coach-name-row pb-5">
                                                <div className="coach-name-col">
                                                    <div className="coach-name">
                                                        <Stack direction={"horizontal"}>
                                                            <span className={'me-2'}>{coachData.displayName}</span>
                                                            <CgCopy style={{cursor: "pointer"}}
                                                                    onClick={() => onCopyPath()}
                                                                    size={23} color={'#D9D9D9'}/>
                                                        </Stack>
                                                    </div>
                                                    <Stack direction={"horizontal"} className="coach-game">
                                                        <span className={'me-2'}>{game?.name} Coach</span>
                                                        <OnlineIndicator isOnline={UserSchemaExt.isOnline(coachData)}/>
                                                    </Stack>
                                                </div>

                                                <div>
                                                    <div
                                                        onClick={() => navigate(routes.bookSession.replace(':username', coachData.uid ?? ''))}
                                                        className="book-lesson-btn-main clickable">
                                                        <div className="book-lesson-btn">Book Now</div>
                                                    </div>
                                                    {user && <div
                                                        onClick={() => tipCoach()}
                                                        className="book-lesson-btn-main clickable">
                                                        <div className="book-lesson-btn">Tip</div>
                                                    </div>}
                                                </div>
                                            </div>

                                            <div className="profile-agent-row-box-outline">
                                                <div className="profile-attribute-box p-4">
                                                    <div className="hourly-rate">
                                                        <div className="profile-sub-heading pb-3">Hourly Rate</div>
                                                        <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                            {coachData && <h5> $ {(pricingPack?.hourlyRate || 0)}</h5>}
                                                        </div>
                                                    </div>
                                                    <div className="rank">
                                                        <div className="profile-sub-heading pb-3">Rank</div>
                                                        <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                            {getRanks()}
                                                        </div>
                                                    </div>
                                                    <div className="languages">
                                                        <div className="profile-sub-heading pb-3">Languages</div>
                                                        {_.isEmpty(coachData.languages) && <div>Not Available</div>}
                                                        <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                            {UserSchemaExt.languageInfo(coachData)?.map(language => <div
                                                                key={language.id}
                                                                className="language-img px-1"><img
                                                                src={language.flagUrl}
                                                                width={30} alt=""/></div>)}
                                                        </div>
                                                    </div>
                                                    <div className="ratings">
                                                        <div className="profile-sub-heading pb-3">Ratings</div>
                                                        <div className="coach-rating py-2"><StarFill
                                                            color="#EE2337"/>&nbsp;&nbsp;
                                                            <StarFill
                                                                color="#EE2337"/>&nbsp;&nbsp;<StarFill
                                                                color="#EE2337"/>&nbsp;&nbsp;
                                                            <StarFill
                                                                color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="main-points-row mt-5">
                                            <div className="main-points-box">
                                                <div className="p-4">
                                                    <div className="profile-sub-heading pb-3">Main Points</div>
                                                    <ul>
                                                        {coachData.mainPoints?.map(mainPoint => <li
                                                            key={mainPoint.id}
                                                            className={"pb-3"}>{mainPoint.label}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="achievements-box">
                                                <div className="p-4">
                                                    <div className="profile-sub-heading pb-4">Statistics</div>
                                                    <div className="achievements-row pb-2">
                                                        <div>K/D Ratio</div>
                                                        <div>{coachData.kdRatio || '-'}</div>
                                                    </div>
                                                    <div className="achievements-row pb-2">
                                                        <div>Win Rate</div>
                                                        <div>{coachData.winRate ? `${coachData.winRate} %` : '-'}</div>
                                                    </div>
                                                    <div className="achievements-row pb-2">
                                                        <div>Total Kills</div>
                                                        <div>{coachData.totalKills || '-'}</div>
                                                    </div>
                                                    <div className="achievements-row pb-2">
                                                        <div>Total Deaths</div>
                                                        <div>{coachData.totalDeaths || '-'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="book-a-lesson-row mt-5">
                                            <div className="book-a-lesson-box">
                                                <div className="p-4">
                                                    <div className="pb-3">
                                                        <div className="profile-sub-heading pb-5">Book A Lesson</div>

                                                        <div className="packages-btn-row">
                                                            <div className="book-now-btn-main clickable">
                                                                <a onClick={() => navigate(routes.bookSession.replace(':username', coachData.uid ?? ''))}>
                                                                    <div className="book-now-btn">
                                                                        <div>Book Now</div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className={"submot-vod-btn-main"}>
                                                                <Button className={"submit-vod-btn"}
                                                                        onClick={() => navigate(routes.submitVod.replace(':username', coachData.uid ?? ''))}>Submit
                                                                    VOD</Button>
                                                            </div>
                                                            <div className="chat-with-coach-main">
                                                                <div onClick={() => onChatWithCoach()}
                                                                     className={"chat-with-coach-btn clickable"}>
                                                                    <div className={"chat-with-coach-btn-content-row"}>
                                                                        <div className={"chat-with-coach-img"}><img
                                                                            src="/assets/profile/message.png" alt=""/>
                                                                        </div>
                                                                        <div>&nbsp;&nbsp; Chat with Coach</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="my-4">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="coach-profile-mobile">
                                <Row>
                                    <Col lg={4}>
                                        <div className="profile-picture">
                                            <img
                                                src={coachData?.profileImage ?? game?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                                                style={{objectFit: "cover"}}
                                                width={300} height={300} alt=""/>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="coach-name-row pb-2">
                                            <div className="coach-name-col">
                                                <div className="coach-name">
                                                    <span>{coachData.displayName}</span>
                                                    <CgCopy style={{cursor: "pointer"}}
                                                            onClick={() => onCopyPath()}
                                                            size={23} color={'#D9D9D9'}/>
                                                </div>
                                                <Stack direction={"horizontal"} className="coach-game text-center">
                                                    <span className={'me-2'}>{game?.name} Coach</span>
                                                    <OnlineIndicator isOnline={UserSchemaExt.isOnline(coachData)}/>
                                                </Stack>
                                            </div>
                                            <div
                                                onClick={() => navigate(routes.bookSession.replace(':username', coachData.uid ?? ''))}
                                                className="book-lesson-btn-main clickable">
                                                <div
                                                    className="book-lesson-btn-main clickable">
                                                    <div className="book-lesson-btn">Tip</div>
                                                </div>
                                                <div className="book-lesson-btn mt-4">Book Now</div>
                                            </div>
                                        </div>
                                        <div className="profile-agent-row-box-outline">
                                            <div className="profile-attribute-box p-4">
                                                <div className="profile-attribute hourly-rate">
                                                    <div className="profile-sub-heading pb-3">Hourly Rate</div>
                                                    <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                        {coachData && <h5> $ {(pricingPack?.hourlyRate || 0)}</h5>}
                                                    </div>
                                                </div>
                                                <div className="profile-attribute rank">
                                                    <div className="profile-sub-heading pb-3">Rank</div>
                                                    <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                        {getRanks()}
                                                    </div>
                                                </div>
                                                <div className="profile-attribute languages">
                                                    <div className="profile-sub-heading pb-3">Languages</div>
                                                    {_.isEmpty(coachData.languages) && <div>Not Available</div>}
                                                    <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                                        {UserSchemaExt.languageInfo(coachData)?.map(language => <div
                                                            key={language.id}
                                                            className="language-img px-1"><img
                                                            src={language.flagUrl}
                                                            width={30} alt=""/></div>)}
                                                    </div>
                                                </div>
                                                <div className="ratings">
                                                    <div className="profile-sub-heading pb-3">Ratings</div>
                                                    <div className="coach-rating py-2"><StarFill
                                                        color="#EE2337"/>&nbsp;&nbsp;
                                                        <StarFill
                                                            color="#EE2337"/>&nbsp;&nbsp;<StarFill
                                                            color="#EE2337"/>&nbsp;&nbsp;
                                                        <StarFill
                                                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"mt-4"}>
                                    <Col lg={4}>
                                        <div className="bio-box">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-3">Bio</div>
                                                <div
                                                    className="bio-description pb-3">{coachData.bio || 'No bio provided'}</div>
                                                {coachData.vodBio && <div className={'mt-2'}>
                                                    <div className="profile-sub-heading pb-3">Vod Bio</div>
                                                    <div className="bio-description pb-3">{coachData.vodBio}</div>
                                                </div>}
                                                <div className={"pt-2"}>
                                                    <div className="profile-sub-heading pb-3">Coach Info</div>
                                                    <div className={"usernames-main"}>
                                                        <div className={"usernames-title pb-2"}>Gamer Tag:</div>
                                                        <div>{coachData.gamerTag}</div>
                                                    </div>
                                                    <div className="usernames-main pt-4">
                                                        {coachData.twitchUsername && <div>
                                                            <div className={"usernames-title clickable pb-2"}><Twitch
                                                                size={"25"}
                                                                color={"#EE2337"}/>
                                                            </div>
                                                        </div>}
                                                        {coachData.youtube &&
                                                            <div className={"usernames-title clickable pb-2"}><Youtube
                                                                size={"25"}
                                                                color={"#EE2337"}/>
                                                            </div>}
                                                        {coachData.twitterUsername &&
                                                            <div className={"usernames-title clickable pb-2"}><Twitter
                                                                size={"25"}
                                                                color={"#EE2337"}/>
                                                            </div>}
                                                        {coachData.discord &&
                                                            <div className={"usernames-title clickable pb-2"}><Discord
                                                                size={"25"}
                                                                color={"#EE2337"}/>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="main-points-box">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-3">Main Points</div>
                                                <ul>
                                                    {coachData.mainPoints?.map(mainPoint => <li
                                                        key={mainPoint.id} className={"pb-3"}>{mainPoint.label}</li>)}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="general-availability-box mt-4">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-3">General Availability</div>
                                                <div className="general-availability-main">
                                                    <div className="general-availability-main">
                                                        {_.isEmpty(availabilityEvents) && 'Availability not specified yet.'}
                                                        {availabilityEvents.map((availability) => {
                                                            return <div key={JSON.stringify(availability)}
                                                                        className={'general-availability-row pb-3'}>
                                                                <div>{TimeUtils.dateToLocaleWeekdayString(availability[0])} {TimeUtils.dateToLocaleTimeString(availability[0])}</div>
                                                                to
                                                                <div>{TimeUtils.dateToLocaleWeekdayString(availability[1])} {TimeUtils.dateToLocaleTimeString(availability[1])}</div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="achievements-box">
                                            <div className="p-4">
                                                <div className="profile-sub-heading pb-4">Statistics</div>
                                                <div className="achievements-row pb-2">
                                                    <div>K/D Ratio</div>
                                                    <div>{coachData.kdRatio || '-'}</div>
                                                </div>
                                                <div className="achievements-row pb-2">
                                                    <div>Win Rate</div>
                                                    <div>{coachData.winRate + " %" || '-'}</div>
                                                </div>
                                                <div className="achievements-row pb-2">
                                                    <div>Total Kills</div>
                                                    <div>{coachData.totalKills || '-'}</div>
                                                </div>
                                                <div className="achievements-row pb-2">
                                                    <div>Total Deaths</div>
                                                    <div>{coachData.totalDeaths || '-'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="book-a-lesson-row">
                                            <div className="book-a-lesson-box">
                                                <div className="p-4">
                                                    <div className="pb-3">
                                                        <div className="profile-sub-heading pb-5">Book A Lesson</div>

                                                        <div className="book-now-btn-main clickable mb-4">
                                                            <a onClick={() => navigate(routes.bookSession.replace(':username', coachData.uid ?? ''))}>
                                                                <div className="book-now-btn">
                                                                    <div>Book Now</div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className={"submot-vod-btn-main mb-4"}>
                                                            <Button className={"submit-vod-btn"}
                                                                    onClick={() => navigate(routes.submitVod.replace(':username', coachData.uid ?? ''))}>Submit
                                                                VOD</Button>
                                                        </div>
                                                        <div className="chat-with-coach-main">
                                                            <div onClick={() => onChatWithCoach()}
                                                                 className={"chat-with-coach-btn clickable"}>
                                                                <div className={"chat-with-coach-btn-content-row"}>
                                                                    <div className={"chat-with-coach-img"}><img
                                                                        src="/assets/profile/message.png" alt=""/></div>
                                                                    <div>&nbsp;&nbsp; Chat with Coach</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="my-4">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>}
                    </Container>
                </div>
            </div>
        </BusyOverlay>
    );
}
