import axios from "axios";
import {ChatEngineConfig} from "../config/chat-engine-config";

export interface ChatEngineChat {
    id: number;
    title: string;
}

interface ChatEngineCreateChatParams {
    usernames: string[],
    title: string,
    is_direct_chat: boolean,
    senderUsername: string;
    senderSecret: string;
}

export class ChatEngineUtils {
    public static async getOrCreateChat(params: ChatEngineCreateChatParams): Promise<ChatEngineChat> {
        const response = await axios.put(
            'https://api.chatengine.io/chats/',
            params,
            {
                headers: {
                    'Project-ID': ChatEngineConfig.projectId,
                    'User-Name': params.senderUsername,
                    'User-Secret': params.senderSecret
                }
            }
        );
        return response.data;
    }
}
