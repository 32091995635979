import React from "react";
import {Button, Col, Row, Stack} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {collection, updateDoc} from "firebase/firestore";
import {doc, serverTimestamp} from "@firebase/firestore";
import {db} from "../../config/firebase-config";
import "./CoachOverviewTab.scss"
import {
    Discord,
    Envelope,
    PatchCheck,
    PatchExclamation,
    Paypal,
    PersonBadge,
    Twitch,
    Twitter,
    Youtube
} from "react-bootstrap-icons";
import Switch from "react-switch";
import {UserSchemaExt} from "../../schemas/user-schema";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useNavigate} from "react-router-dom";
import {routes} from "../../App";
import {useStoreActions} from "../../models";
import {OnlineModePopup} from "../../components/alerts/OnlineModePopup";
import {useSetPaypalId} from "../../hooks/use-set-paypal-id";
import {useChangePassword} from "../../hooks/use-change-password";
import {TimeUtils} from "../../utils/time-utils";
import {toast} from "react-toastify";
import {AppConfig} from "../../config/app-config";
import {DateTime} from "luxon";

export function CoachOverviewTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {isBusy, setIsBusy} = useBusy();
    const navigate = useNavigate();
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const {isBusySetPaypalId, changePaypalId} = useSetPaypalId();
    const {isBusyChangePassword, changePassword} = useChangePassword();


    async function setOnlineMode(enable: boolean): Promise<void> {
        if (userLoading) return;
        setIsBusy(true);
        await updateDoc(doc(collection(db, 'users'), authUser?.uid), {
            isOnline: enable ? serverTimestamp() : null,
        });
        setIsBusy(false);
        if (enable) await uiStoreActions.addCustomAlert({
            title: 'You are now online!',
            closeOnClickOutside: true,
            builder: (alertProps) => {
                return <OnlineModePopup {...alertProps}/>;
            }
        })
    }

    function buildOnlineCheckbox() {
        return <Stack direction={"horizontal"}>
            <span className={'me-3'}>{UserSchemaExt.isOnline(user) ? 'You are online' : 'You are offline'}</span>
            <Switch onChange={enable => setOnlineMode(enable)}
                    checked={UserSchemaExt.isOnline(user)}/>
        </Stack>;
    }

    async function onEditProfile() {
        navigate(routes.editCoachProfile);
    }

    async function onEditMiniProfile() {
        navigate(routes.editCoachMiniProfile);
    }

    return <BusyOverlay isBusy={isBusyChangePassword || isBusy || userLoading || isBusySetPaypalId}>
        <div className={"coach-overview-tab"}>
            <h3 className={"primary-color"}>Coach Overview</h3>
            <p>This section will show an overview of your coach account.</p>
            <div className="dashboard-content-outline">
                <div className="dashboard-padding-box">
                    <Row>
                        <Col xl={6}>
                            <div className="coach-info-row">
                                <div className={"dashboard-coach-img"}><img
                                    src={user?.profileImage || "/assets/profile/dummy-profile-1.jpg"}
                                    alt=""/></div>
                                <div className={"coach-details"}>
                                    <div
                                        className="coach-name pb-3">{user?.displayName}&nbsp;&nbsp; {authUser?.emailVerified ?
                                        <PatchCheck color="#05B78C"/> : <PatchExclamation color="#EE2337"/>}</div>

                                    <div className="coach-contact-info-main pb-4 pb-xl-0">
                                        <div className={"coach-detail-row pb-3"}>
                                            <div className="info-title">
                                                <Envelope size={20}/>
                                            </div>
                                            <div className="info-data">
                                                {user?.email}
                                            </div>
                                        </div>
                                        <div className={"coach-detail-row"}>
                                            <div className="info-title">
                                                <PersonBadge size={20}/>
                                            </div>
                                            <div className="info-data">
                                                {authUser?.uid}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className="dashboard-overview-btn-main">
                                <div><Button variant={"primary"} className={'mt-3 dashboard-secondary-btn'}
                                             onClick={() => changePassword()}>Update Password</Button></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'availability-section my-3'}>
                            <h5 className={'my-2 pb-3 primary-color'}>Availability</h5>
                            <Row>
                                <Col xl={6}>
                                    <div className="booking-mode pb-3">{buildOnlineCheckbox()}</div>
                                </Col>
                                <Col xl={6}>
                                    <div className="dashboard-overview-btn-main">
                                        <Button onClick={() => navigate(routes.editCoachAvailability)}
                                                className={"dashboard-secondary-btn"}>Edit Weekly Availability</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'availability-section my-3'}>
                            <div className={'d-flex pb-3'}
                                 style={{alignItems: "center", justifyContent: "space-between"}}>
                                <h5 className={'my-2 primary-color'}>Coach Profile </h5>
                                <span>
                                    <Button variant={"primary"} className={'mx-2 dashboard-secondary-btn'}
                                            onClick={() => onEditProfile()}>Edit Profile</Button><Button
                                    variant={"primary"}
                                    className={'mx-2 dashboard-secondary-btn'}
                                    onClick={() => onEditMiniProfile()}>Edit Coach Card</Button>
                                </span>
                            </div>
                            <Row>
                                <Col xl={6}>
                                    <div className="booking-mode pb-3">Profile Bio</div>
                                </Col>
                                <Col xl={6}>
                                    {user?.bio?.trim() || 'No bio provided'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div className="booking-mode pb-3">VOD Bio</div>
                                </Col>
                                <Col xl={6}>
                                    {user?.vodBio?.trim() || 'No VOD bio provided'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div className="booking-mode pb-3">Coach Card Points</div>
                                </Col>
                                <Col xl={6}>
                                    <div>{user?.mainPoints?.length && <ul>
                                        {user?.mainPoints.map(point => <li key={point.id}>{point.label}</li>)}
                                    </ul>}</div>
                                    <div>{!user?.mainPoints?.length && 'No coach card points provided'}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'language-section my-3 pb-3'}>
                            <h5 className={'my-2 pb-3 primary-color'}>Languages & Region</h5>
                            <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                {UserSchemaExt.languageInfo(user)?.map(language => <div key={language.id}
                                                                                        className="language-img px-1">
                                    <img
                                        src={language.flagUrl}
                                        width={30} alt=""/></div>)}
                            </div>
                            <Row>
                                <Col xl={6}>
                                    {user && <div className="booking-mode pt-3">Current
                                        Timezone: <br/><span
                                            style={{color: '#05B78C'}}>{UserSchemaExt.getTimezone(user)?.name}
                                            {!!user?.timezone || `No timezone set! Please update your weekly availability again to update the timezone.`}</span>
                                    </div>}
                                </Col>
                                <Col xl={6}>
                                    <div className="dashboard-overview-btn-main">
                                        <Button onClick={async () => {
                                            if (!user) return;
                                            await updateDoc(user.snapshot.ref, {
                                                timezone: TimeUtils.guessTimezone().name,
                                            });
                                            toast('Timezone reset complete!');
                                        }} className={"dashboard-secondary-btn"}>Reset Timezone</Button>
                                    </div>
                                    {
                                        !AppConfig.isProductionEnv && <div className="dashboard-overview-btn-main mt-2">
                                            <Button onClick={async () => {
                                                if (!user) return;
                                                console.log('user.timezone: ', user?.timezone);
                                                const now = TimeUtils.nowTz(UserSchemaExt.getTimezone(user));
                                                console.log('Now time raw', now);
                                                console.log('Now time UTC string', now.toString());
                                                console.log('Now time local string', now.toLocaleString(DateTime.DATETIME_SHORT));
                                                console.log('DayJs Timezone offset', now.offset / 60);
                                                console.log('System Timezone offset', UserSchemaExt.getTimezone(user).offset);
                                            }} className={"dashboard-secondary-btn"}>Log Timezone</Button>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'social-links-section my-3 pb-3'}>
                            <h5 className={'pb-3 primary-color'}>Social Links</h5>
                            <Row>
                                <Col xl={6} className="social-link-item">
                                    <a className={`${user?.twitchUsername ? 'social-link-available' : ''}`}
                                       target={'_blank'}
                                       href={user?.twitchUsername}><Twitch
                                        size={20}/>
                                        <span className="ms-3">{user?.twitchUsername || 'Not Set'}</span></a>
                                </Col>
                                <Col xl={6} className="social-link-item">
                                    <a className={`${user?.youtube ? 'social-link-available' : ''}`} target={'_blank'}
                                       href={user?.youtube}><Youtube
                                        size={20}/>
                                        <span className="ms-3">{user?.youtube || 'Not Set'}</span></a>
                                </Col>
                                <Col xl={6} className="social-link-item">
                                    <a className={`${user?.twitterUsername ? 'social-link-available' : ''}`}
                                       target={'_blank'} href={user?.twitterUsername}><Twitter size={20}/>
                                        <span className="ms-3">{user?.twitterUsername || 'Not Set'}</span></a>
                                </Col>
                                <Col xl={6} className="social-link-item">
                                    <a className={`${user?.discord ? 'social-link-available' : ''}`} target={'_blank'}
                                       href={user?.discord}><Discord
                                        size={20}/>
                                        <span className="ms-3">{user?.discord || 'Not Set'}</span></a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'payments-section mt-3'}>
                            <h5 className={'my-2 pb-3 primary-color'}>Payout Options</h5>
                            <Row>
                                <Col xl={6}>
                                    <div><Paypal size={20}/>
                                        <span className="ms-3">{user?.paypalId || 'No Paypal Email'}</span></div>
                                </Col>
                                <Col xl={6}>
                                    <div className="dashboard-overview-btn-main pb-4">
                                        <Button onClick={() => changePaypalId()} className={"dashboard-secondary-btn"}>Edit
                                            Paypal Email</Button>
                                    </div>


                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </BusyOverlay>;
}
