import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    WithFieldValue
} from "firebase/firestore";

export interface SecretSchema {
    chatEngineId?: number,
    chatEngineSecret?: string,
}

export const secretConverter: FirestoreDataConverter<SecretSchema> = {
    toFirestore(post: WithFieldValue<SecretSchema>): DocumentData {
        return post;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): SecretSchema {
        return {
            ...snapshot.data(options),
            uid: snapshot.id,
        } as SecretSchema;
    },
};
