import "./AboutUsPage.scss"
import {Col, Container, Row} from "react-bootstrap";
import {Community} from "../../components/Community";

export function AboutUsPage() {

    return (
        <div className={"page about-us "}>

            <div className="py-5">
                <Container>
                    <Row className={"pb-5"}>
                        <Col lg={7}>
                            <div className="about-us-content">
                                <h1>WE ARE <span
                                    className="primary-color">FPS LOUNGE</span>
                                </h1>
                                <div className="pb-4 about-us-description">
                                    Built By Gamers, For Gamers
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <img className={"about-us-logo img-fluid"} src="/assets/Logo/LogoDark BG.png"
                                 alt=""/>
                        </Col>
                    </Row>

                    <div className="co-founder-msg-outline mt-5">
                        <div className="p-5 co-founder-img-section">
                            <div className="co-founder-img">
                                <img src="/assets/about-us/co-founder.png" className={'img-fluid'} alt=""/>
                            </div>
                            <div className="co-founder-content mx-lg-5">
                                <div className="co-founder-title">
                                    Founder & CEO
                                </div>
                                <div className="co-founder-name">
                                    DEREK TAING
                                </div>
                                <div className="co-founder-game">
                                    FAVORITE GAME OF ALL-TIME: <span className="secondary-color">APEX LEGENDS</span>
                                </div>
                            </div>
                        </div>

                        <div className="co-founder-message px-5 pb-5">
                            With an annual global revenue of over $135 billion dollars, the Gaming industry has now
                            surpassed the revenue of the music & entertainment industry, and all four major US sports
                            leagues combined.
                            <br/><br/>

                            As of 2022, the vast majority of gaming profits are going directly to game developers, game
                            distributors and platforms, while the revenue drivers like influencers and streamers are
                            left with tenths of a percentage of the value they bring to the industry. Highly skilled
                            gamers, streamers and professional Esports athletes deserve the ability to make a
                            sustainable living doing what they love.
                            <br/><br/>

                            Derek felt the effects of this broken revenue funnel first hand as a competitive gamer and
                            full time content creator for 5 years playing Rainbow Six Siege, PUBG and Apex Legends. It
                            was a near impossible challenge but with a drive for competing and reaching the highest
                            levels he was able to monetize a large audience to turn his passion for gaming into a
                            living. Unfortunately many of his teammates and friends struggled to survive despite working
                            16+ hour days.
                            <br/><br/>

                            The industry needed a solution. <br/><br/>

                            Thus, FPS Lounge was born. Gaming as a profession is not sustainable, equitable or
                            accessible. FPS Lounge was created with a mission to bring value back to highly skilled
                            players around the globe. It exists to create sustainable revenue streams and drive portions
                            of the gaming revenue back into the hands of professional Esports athletes. Built By Gamers,
                            For Gamers - FPS Lounge is here to stay.

                        </div>
                    </div>

                    <div className="about-us-welcome">
                        <img src="/assets/about-us/welcome-text.png" className={"img-fluid py-5"} alt=""/>
                    </div>
                </Container>
            </div>

            <Community/>
        </div>
    )
}