import React, {FC, useEffect, useMemo} from 'react'
import './Calendar.scss';
import {Calendar, dateFnsLocalizer, SlotInfo, Views} from "react-big-calendar";
import {CalendarEvent} from "../../definitions/calendar-event";
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import {DateRange, DateRangeList, TimeUtils} from "../../utils/time-utils";
import {Container} from "react-bootstrap";
import {Zone} from "luxon";

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

type AvailabilityCalendarProps = {
    events: CalendarEvent[];
    onAddEvent?: (slotInfo: SlotInfo) => void;
    onClickEvent?: (event: CalendarEvent) => void;
    timezone: Zone;
};
export const AvailabilityCalendar: FC<AvailabilityCalendarProps> = (props) => {
    useEffect(() => {
        console.log('### EVENTS', props.events);
    }, [props.events])

    const {defaultDate, scrollToTime} = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date(),
        }),
        []
    )

    function isRangeAvailable(range: { start: Date, end: Date }) {
        const backgroundEventRanges: DateRangeList = (props.events ?? [])
            .map(myEvent => [TimeUtils.dateOfTz(myEvent.start!.valueOf(), props.timezone), TimeUtils.dateOfTz(myEvent.end!.valueOf(), props.timezone)]);
        const newEventToAdd: DateRange = [TimeUtils.dateOfTz(range.start!.valueOf(), props.timezone), TimeUtils.dateOfTz(range.end!.valueOf(), props.timezone)];

        const tree = TimeUtils.getRangeTreeForDateRanges(backgroundEventRanges);
        const searchHalfHourRange = TimeUtils.inclusiveDateRangeToHalfHourRange(newEventToAdd);
        console.log('Searching tree for:', searchHalfHourRange)
        const overlaps = tree.search(searchHalfHourRange);
        let hasOverlaps = overlaps.length > 0;
        console.log(hasOverlaps ? 'HAS OVERLAPS' : 'NO OVERLAPS, SAFE');
        return !hasOverlaps;
    }

    return (
        <div className="booking-calendar">
            <Calendar
                className={'week-plan-calendar'}
                dayLayoutAlgorithm={"overlap"}
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                events={props.events}
                localizer={localizer}
                onSelectEvent={props.onClickEvent}
                onSelectSlot={(event) => {
                    if (!props.onAddEvent) return;
                    if (!isRangeAvailable(event)) return;
                    props.onAddEvent(event);
                }}
                onSelecting={range => {
                    return isRangeAvailable(range);
                }}
                selectable={!!props.onAddEvent}
                scrollToTime={scrollToTime}
                showMultiDayTimes={true}
                views={['week']}
                components={{
                    timeGutterHeader: () => <Container/>,
                    header: (headerProps) =>
                        <Container>{TimeUtils.dateOfTz(headerProps.date!.valueOf(), props.timezone).weekdayShort}</Container>,
                    toolbar: () => <Container/>
                }}
            />
        </div>
    )
};
