import React from "react";
import {Button, Col, Container, Nav, Row, Tab} from "react-bootstrap";
import './StudentDashboardPage.scss';
import {StudentOverviewTab} from "./StudentOverviewTab";
import {StudentBookingsTab} from "./StudentBookingsTab";
import {StudentWalletTab} from "./StudentWalletTab";
import {StudentCalendarTab} from "./StudentCalendarTab";
import useCookie from "react-use-cookie";
import {StudentVodTab} from "./StudentVodTab";
import {Bank2, Book, Calendar, Cash, PlayCircle, Wallet2} from "react-bootstrap-icons";
import {useCurrentUser} from "../../hooks/use-current-user";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useNavigate} from "react-router-dom";
import {routes} from "../../App";
import {useAddFunds} from "../../hooks/use-add-funds";
import {StudentGainsTab} from "../coach/StudentGainsTab";

export const StudentDashboardPage = () => {
    const [studentDashboardTabIndex, setStudentDashboardTabIndex] = useCookie('studentDashboardTabIndex', "first");
    const {user} = useCurrentUser();
    const navigate = useNavigate();
    const {addFunds, isBusyAddFunds} = useAddFunds();
    return (
        <BusyOverlay isBusy={isBusyAddFunds}>
            <Container className={'page student-dashboard-page pt-3 mb-5'}>

                <Row className={"dashboard-header pb-4"}>
                    <Col lg={5}>
                        <h2 className={"primary-color dashboard-title pt-2"}>Student Dashboard</h2>
                    </Col>
                    <Col lg={7}>
                        <div className="dashboard-header-row">
                            <div className="wallet-balance pt-2">
                                <div><span className="primary-color">Balance : </span>{user?.balance ?? "-"} USD</div>
                            </div>
                            <Button className={"dashboard-primary-outline-btn"} onClick={() => addFunds()}>Add
                                Funds</Button>
                            <Button className={"dashboard-primary-outline-btn"}
                                    onClick={() => navigate(routes.coaches)}>Book Now</Button>
                        </div>

                    </Col>

                </Row>

                <div>
                    <Tab.Container defaultActiveKey={studentDashboardTabIndex}
                                   onSelect={(tab) => setStudentDashboardTabIndex(tab ?? "first")}>
                        <Row>
                            <Col lg={3} className={"pt-4 mb-5 mb-lg-0"}>
                                <Nav variant="pills" className="flex-column dashboard-sidebar ">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first"><Bank2/>&nbsp;&nbsp; Overview</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second"><Calendar/>&nbsp;&nbsp; Calendar</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="vod"><PlayCircle/>&nbsp;&nbsp; VOD Reviews</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third"><Book/>&nbsp;&nbsp; My Bookings</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="gains"><Cash/>&nbsp;&nbsp; My Purchases</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth"><Wallet2/>&nbsp;&nbsp; My Wallet</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={9} className={"pt-4"}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <StudentOverviewTab/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <StudentCalendarTab/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="vod">
                                        <StudentVodTab/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <StudentBookingsTab/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="gains">
                                        <StudentGainsTab/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <StudentWalletTab/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Container>
        </BusyOverlay>

    );
}
