import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {initializeApp} from "firebase/app"
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
import {connectStorageEmulator, getStorage} from "firebase/storage";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {connectAuthEmulator, getAuth} from "firebase/auth"
import {AppConfig} from "./app-config";
import {getMessaging, getToken, MessagePayload, onMessage} from "firebase/messaging";
import {EnvUtils} from "../utils/env-utils";
import {Dict} from "../interfaces/dict";

const vapidKey = EnvUtils.readString('REACT_APP_FIREBASE_VAPID_KEY');
// Production Firebase configuration
const firebaseConfigs: Dict = {
    STAGING: {
        apiKey: "AIzaSyAxUHk6eU2QQDNemxImKph5o04lOT0HElE",
        authDomain: "fps-lounge-staging.firebaseapp.com",
        projectId: "fps-lounge-staging",
        storageBucket: "fps-lounge-staging.appspot.com",
        messagingSenderId: "551272160435",
        appId: "1:551272160435:web:068447d5ec8061577ba4c6"
    },
    PROD: {
        apiKey: "AIzaSyClSCbO8lk3Z5pDKH5GpSDKeGNadM3lOfs",
        authDomain: "fps-lounge.firebaseapp.com",
        projectId: "fps-lounge",
        storageBucket: "fps-lounge.appspot.com",
        messagingSenderId: "844979856475",
        appId: "1:844979856475:web:38bfbeb9708892a1dc4b7e"
    },
}

export const firebaseApp = initializeApp(firebaseConfigs[EnvUtils.readString('REACT_APP_FIREBASE_ENV')]);
if (!AppConfig.isEmulatorMode) {
    initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(EnvUtils.readString('REACT_APP_FIREBASE_APPCHECK_SITE_KEY')),
        isTokenAutoRefreshEnabled: true,
    });
}

export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const messaging = getMessaging(firebaseApp);

if (AppConfig.isEmulatorMode) {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectStorageEmulator(storage, "localhost", 9199);
}

export const getMessagingToken = async (): Promise<string | null> => {
    try {
        const currentToken = await getToken(messaging, {vapidKey: vapidKey});
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            return null;
            // shows on the UI that permission is required
        }
    } catch (ex) {
        console.log('An error occurred while retrieving token. ', ex);
        return null;
    }
}

export const onMessageListener = (): Promise<MessagePayload> =>
    new Promise((resolve) => {
        onMessage(messaging, (payload: MessagePayload) => {
            resolve(payload);
        });
    });
