import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {Button} from "react-bootstrap";
import {routes} from "../../App";
import {useVodsOfStudent} from "../../hooks/use-vods";
import {StudentVodListItem} from "../../components/StudentVodListItem";
import {useNavigate} from "react-router-dom";
import {SpinnerPadded} from "../../components/SpinnerPadded";

export function StudentVodTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allVods, vodsLoading} = useVodsOfStudent(user?.uid);
    const navigate = useNavigate();

    return (<div>
        <h3 className={"primary-color"}>VOD Reviews</h3>
        <p>This section shows all the VOD Review submissions.</p>
        <div className="my-3">
            <Button className={"dashboard-secondary-btn"} onClick={() => navigate(routes.coaches)}>Submit New
                VOD</Button>
        </div>
        {vodsLoading && <SpinnerPadded/>}
        {userLoading || allVods?.map(vod => <StudentVodListItem key={vod.id}
                                                                vodData={vod}/>)}

    </div>);
}
