import algoliasearch from "algoliasearch";
import {EnvUtils} from "../utils/env-utils";

export class AlgoliaConfig {
    public static readonly appId: string = EnvUtils.readString('REACT_APP_ALGOLIA_APP_ID');
    public static readonly appApiKey: string = EnvUtils.readString('REACT_APP_ALGOLIA_APP_API_KEY');
    public static readonly usersIndexName: string = EnvUtils.readString('REACT_APP_ALGOLIA_USERS_INDEX_NAME');
}

export const searchClient = algoliasearch(AlgoliaConfig.appId, AlgoliaConfig.appApiKey);
