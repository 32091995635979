import React, {FC} from 'react';
import {Button, Form, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import {useForm} from "react-hook-form";
import {UserSchema} from "../../schemas/user-schema";
import {FieldError} from "../form/FieldError";

export interface TipCoachPopupResult {
    tipMessage: string;
    amount: number;
}

export const TipCoachPopup: FC<AlertRenderProps<TipCoachPopupResult> & { coachData: UserSchema }> = (props) => {
    const {handleSubmit, register, formState: {errors}} = useForm<TipCoachPopupResult>();

    async function onSubmitTip(formData: TipCoachPopupResult): Promise<void> {
        if (!formData.amount || formData.amount <= 0) return;
        console.log(formData);
        props.confirm(formData);
    }

    async function onCancel(): Promise<void> {
        props.cancel();
    }

    return (
        <Stack style={{textAlign: "start"}} className={'px-3'}>
            <p>Tip your coach '{props.coachData?.displayName}' with a message!</p>
            <Form onSubmit={handleSubmit(onSubmitTip)}>
                <Form.Group className="mb-4">
                    <Form.Label style={{textAlign: "left", width: "100%"}}>Purchase Amount</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder="Tip Amount"
                        step={0.01}
                        {...register('amount', {valueAsNumber: true, required: 'Field is required'})}
                    />
                    <FieldError message={errors.amount?.message}/>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label style={{textAlign: "left", width: "100%"}}>Your Message to Coach</Form.Label>
                    <Form.Control
                        placeholder="Enter Message"
                        {...register('tipMessage', {required: 'Field is required'})}
                    />
                    <FieldError message={errors.tipMessage?.message}/>
                </Form.Group>
                <Stack direction={"horizontal"}>
                    <Button variant={"primary"} type={"submit"} className={'w-100 my-1 me-2'} onClick={() => {
                    }}>Tip Coach</Button>
                    <Button variant={"primary"} className={'w-100 my-1'} onClick={onCancel}>Cancel</Button>
                </Stack>
            </Form>
        </Stack>
    );
};
