import {ref, StorageError, StorageReference, UploadTaskSnapshot} from "firebase/storage";
import {useMemo, useState} from "react";
import {useUploadFile} from "react-firebase-hooks/storage";
import {usePublicUrl} from "./use-public-url";
import {storage} from "../config/firebase-config";
import path from "path-browserify";

export function useUploadProfilePic(): {
    upload: (selectedFile: File | undefined) => Promise<void>,
    isUploading: boolean,
    uploadingError: StorageError | undefined,
    uploadingSnapshot: UploadTaskSnapshot | undefined,
    publicUrl: string | null,
    progressPercent: number | null,
} {
    const [uploadFile, isUploading, uploadingSnapshot, uploadingError] = useUploadFile();
    const [thumbRef, setThumbRef] = useState<StorageReference>();
    const publicUrl = usePublicUrl(thumbRef);
    const progressPercent = useMemo(() => {
        if (!uploadingSnapshot?.totalBytes) return null;
        return 100 * (uploadingSnapshot.bytesTransferred / uploadingSnapshot.totalBytes);
    }, [uploadingSnapshot])
    const upload = async (selectedFile: File | undefined) => {
        if (selectedFile) {
            const originalFileName = `${Date.now()}-${selectedFile.name}`;
            const originalExtension = path.extname(originalFileName);
            const originalFileNameNoExt = path.basename(originalFileName, originalExtension);
            const uploadPath = `users/avatars/${originalFileName}`;
            const result = await uploadFile(ref(storage, uploadPath.toString()), selectedFile);
            if (!result?.ref.parent) return;
            const thumbFileName = path.join('thumbnails', `${originalFileNameNoExt}_500x500.jpeg`);
            const thumbRef = ref(result?.ref.parent, thumbFileName);
            setThumbRef(thumbRef);
            console.log(`Result:`, thumbRef.toString());
        }
    }

    return {
        upload,
        isUploading,
        uploadingError,
        uploadingSnapshot,
        publicUrl,
        progressPercent,
    }
}
