import React from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useVodsOfCoach} from "../../hooks/use-vods";
import {CoachVodListItem} from "../../components/CoachVodListItem";
import {SpinnerPadded} from "../../components/SpinnerPadded";

export function CoachVodTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allVods, vodsLoading} = useVodsOfCoach(user?.uid);

    return (<div>
        <h3 className={"primary-color"}>VOD Reviews</h3>
        <p>Review VOD submissions sent by your students.</p>
        {vodsLoading && <SpinnerPadded/>}
        {userLoading || allVods?.map(vod => <CoachVodListItem key={vod.id}
                                                              vodData={vod}/>)}
    </div>);
}
