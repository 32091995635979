import React from "react";
import {useSystemConfig} from "../../hooks/use-system-config";
import {useStoreActions} from "../../models";

export function AdminOverviewTab() {
    const {systemConfig, systemConfigLoading} = useSystemConfig();
    const uiStoreActions = useStoreActions(actions => actions.ui);

    return <>
        <h3 className={"primary-color"}>Admin Overview</h3>
        <p>This section will contain a overview of the platform for admins. It will also
            contain
            statistics and analytics.</p>
        <div className="dashboard-content-outline">
            <div className="p-4">
                <div className="my-wallet-row">
                    <div className="my-wallet-title">
                        House Balance
                    </div>
                    <div className="my-wallet-info">
                        {systemConfig?.balance} USD
                    </div>
                </div>
            </div>
        </div>
    </>;
}
