import {useBusy} from "./use-busy";
import {useStoreActions} from "../models";
import React from "react";
import {useCurrentUser} from "./use-current-user";
import {updateDoc} from "firebase/firestore";
import {toast} from "react-toastify";

export function useSetPaypalId(): {
    changePaypalId: () => Promise<void>,
    isBusySetPaypalId: boolean,
} {
    const {user, userLoading} = useCurrentUser();
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const {isBusy, setIsBusy} = useBusy();

    async function changePaypalId(): Promise<void> {
        if (!user) return;
        const rawAmount = await uiStoreActions.addSingleInputAlert({
            title: "Update your PayPal Account Email",
            message: "Please enter your PayPal Account Email to use for withdrawals.",
            defaultValue: user.paypalId,
            required: true,
        });

        if (!rawAmount) return;
        setIsBusy(true);
        await updateDoc(user.snapshot.ref, {
            paypalId: rawAmount,
        })
        setIsBusy(false);
        toast('PayPal Account Email updated');
    }

    return {
        changePaypalId,
        isBusySetPaypalId: isBusy,
    }
}
