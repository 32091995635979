import React, {FC} from "react";
import {GainSchema} from "../schemas/gain-schema";
import "./AdminGainListItem.scss"
import {GainTypeExt} from "../definitions/gain-type";
import {Button} from "react-bootstrap";
import {PdfUtils} from "../utils/pdf-utils";
import {TimeUtils} from "../utils/time-utils";

export const AdminGainListItem: FC<{ gainData: GainSchema; hasActions: boolean; }> = function (props) {
    return (
        <div className={"admin-gain-list-item mb-4"} key={props.gainData.id}>
            <div className="dashboard-box-outline">
                <div className="p-4">
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">
                            Gain Type
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">{GainTypeExt.toDisplayLabel(props.gainData?.gainType)}</span>
                        </div>
                    </div>
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">
                            Purchased User
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">{props.gainData.targetUserPointer.name}</span>
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">({props.gainData.targetUserPointer.id})</span>
                        </div>
                    </div>
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">
                            Total Spent Amount
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">{props.gainData.totalAmount} USD</span>
                        </div>
                    </div>
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">
                            Purchased Credit Amount
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">{props.gainData.creditAmount} USD</span>
                        </div>
                    </div>
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">
                            Transaction Fee
                        </div>
                        <div className="admin-gain-info"><span
                            className="secondary-color">{props.gainData.houseAmount} USD</span>
                        </div>
                    </div>
                    <div className="admin-gain-row pb-4">
                        <div className="admin-gain-title">Purchase Date</div>
                        <div className="admin-gain-info">
                            {TimeUtils.timestampToLocaleString(props.gainData.created)}
                        </div>
                    </div>
                    <Button onClick={() => PdfUtils.generateInvoiceForGainData(props.gainData)}
                            variant={"outline-light"} className={'mt-3'}>Download Invoice</Button>
                </div>
            </div>
        </div>);
};
